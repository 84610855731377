import config from 'config'

import {
  ISSUES_FETCH_REQUEST,
  ISSUES_FETCH_SUCCESS,
  ISSUES_FETCH_FAILURE,
  ISSUES_UPDATE_REQUEST,
  ISSUES_UPDATE_SUCCESS,
  ISSUES_UPDATE_FAILURE,
  ISSUES_CREATE_REQUEST,
  ISSUES_CREATE_SUCCESS,
  ISSUES_CREATE_FAILURE,
  ISSUES_UPDATE_ITEM,
  ISSUES_TOGGLE_ADD_MODAL,
  ISSUES_TOGGLE_EDIT_MODAL,
  ISSUES_FILTER_SET_KEYWORD,
  ISSUES_FILTER_SELECT_AUTHOR,
  ISSUES_FILTER_SELECT_TYPE,
  ISSUES_FILTER_SELECT_STATUS,
  ISSUES_CLEAR_FILTER,
  ISSUES_SORT,
  ISSUES_SET_ERRORS,
  ISSUES_SET_CURRENT_PAGE,
  ISSUES_SET_PAGINATION_PER_PAGE,
  ISSUES_SET_QUERY_SETTINGS,
  ISSUES_CLEAR,
} from '../actions/issues'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  filter: {
    keyword: '',
    author: null,
    issue_type: null,
    status: config.options.issues.defaultStatus,
  },
  sorting: {
    column: 'issues.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
}

const issuesReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ------------------------- ISSUES_FETCH_REQUEST ------------------------ */
    case ISSUES_FETCH_REQUEST:
      return { ...state, isLoading: true }

    /* ------------------------- ISSUES_FETCH_SUCCESS ------------------------ */
    case ISSUES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }

    /* ------------------------- ISSUES_FETCH_FAILURE ------------------------ */
    case ISSUES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }

    /* -------------------------- ISSUES_CREATE_REQUEST ------------------------- */
    /* -------------------------- ISSUES_UPDATE_REQUEST ------------------------- */
    case ISSUES_CREATE_REQUEST:
    case ISSUES_UPDATE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }

    /* -------------------------- ISSUES_CREATE_SUCCESS ------------------------- */
    /* -------------------------- ISSUES_CREATE_FAILURE ------------------------- */
    /* ------------------------ ISSUES_UPDATE_SUCCESS ------------------------ */
    /* ------------------------ ISSUES_UPDATE_FAILURE ------------------------ */
    case ISSUES_CREATE_SUCCESS:
    case ISSUES_CREATE_FAILURE:
    case ISSUES_UPDATE_SUCCESS:
    case ISSUES_UPDATE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }

    /* --------------------------- ISSUES_UPDATE_ITEM --------------------------- */
    case ISSUES_UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.data.id ? { ...action.data } : item
        ),
      }

    /* ------------------------- ISSUES_TOGGLE_ADD_MODAL ------------------------ */
    case ISSUES_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }

    /* ----------------------- ISSUES_TOGGLE_EDIT_MODAL ---------------------- */
    case ISSUES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }

    /* ------------------------ ISSUES_FILTER_SET_KEYWORD ----------------------- */
    case ISSUES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }

    /* ------------------------ ISSUES_FILTER_SELECT_AUTHOR ------------------------ */
    case ISSUES_FILTER_SELECT_AUTHOR:
      return {
        ...state,
        filter: {
          ...state.filter,
          author: action.author,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }

    /* ----------------------- ISSUES_FILTER_SET_ISSUE_TYPE --------------------- */
    case ISSUES_FILTER_SELECT_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          issue_type: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }

    /* ------------------------ ISSUES_FILTER_SELECT_STATUS ------------------------ */
    case ISSUES_FILTER_SELECT_STATUS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.status,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }

    /* ------------------------- ISSUES_CLEAR_FILTER ------------------------- */
    case ISSUES_CLEAR_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      }

    /* ----------------------------- ISSUES_SORT ----------------------------- */
    case ISSUES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }

    /* -------------------------- ISSUES_SET_ERRORS -------------------------- */
    case ISSUES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }

    /* ----------------------- ISSUES_SET_CURRENT_PAGE ----------------------- */
    case ISSUES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }

    /* -------------------- ISSUES_SET_PAGINATION_PER_PAGE ------------------- */
    case ISSUES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }

    /* ---------------------- ISSUES_SET_QUERY_SETTINGS ---------------------- */
    case ISSUES_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          author: parseInt(action.author, 10) || null,
          issue_type: action.issue_type || null,
          status: action.status || initialState.filter.status,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }

    /* ----------------------------- ISSUES_CLEAR ---------------------------- */
    case ISSUES_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default issuesReducer
