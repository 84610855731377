import React from 'react'

/* ---------------------------------- Icons --------------------------------- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'

/* -------------------------------------------------------------------------- */
/*                           Relative Quantity Toast                          */
/* -------------------------------------------------------------------------- */
const RelativeQuantityToast = ({
  relative_quantity,
  completed_quantity,
  machine_quantity,
}) => (
  <div className="mt-1 d-flex align-items-center">
    <span className={relative_quantity < 0 ? 'text-danger' : 'text-success'}>
      <FontAwesomeIcon
        icon={relative_quantity < 0 ? faMinus : faPlus}
        className="small"
      />
      {Math.abs(relative_quantity).toLocaleString()}
    </span>
    <span className="text-extramuted small ms-1">
      ({completed_quantity} / {machine_quantity})
    </span>
  </div>
)

export default RelativeQuantityToast
