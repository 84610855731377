import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import store from 'store'
import Loading from 'components/pages/Loading'
import SessionProvider from 'components/providers/SessionProvider'
import Wrapper from './Wrapper'

const App = () => (
  <Provider store={store}>
    <SessionProvider>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <CookiesProvider>
            <Wrapper />
          </CookiesProvider>
        </Suspense>
      </BrowserRouter>
    </SessionProvider>
  </Provider>
)

export default App
