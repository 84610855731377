import api from 'utils/api'
import config from 'config'
import { defaultRequest } from 'utils/requests'

export const GLOBAL_SET_UNAUTHORIZED = 'GLOBAL_SET_UNAUTHORIZED'
export const GLOBAL_CLEAR_UNAUTHORIZED = 'GLOBAL_CLEAR_UNAUTHORIZED'
export const GLOBAL_SET_NOT_FOUND = 'GLOBAL_SET_NOT_FOUND'
export const GLOBAL_CLEAR_NOT_FOUND = 'GLOBAL_CLEAR_NOT_FOUND'
export const GLOBAL_SET_NETWORK_ERROR = 'GLOBAL_SET_NETWORK_ERROR'
export const GLOBAL_CLEAR_NETWORK_ERROR = 'GLOBAL_CLEAR_NETWORK_ERROR'
export const GLOBAL_SET_SESSION_EXPIRED = 'GLOBAL_SET_SESSION_EXPIRED'
export const GLOBAL_CLEAR_SESSION_EXPIRED = 'GLOBAL_CLEAR_SESSION_EXPIRED'
export const GLOBAL_SET_EXPIRATION_WARNING = 'GLOBAL_SET_EXPIRATION_WARNING'
export const GLOBAL_CLEAR_EXPIRATION_WARNING = 'GLOBAL_CLEAR_EXPIRATION_WARNING'
export const GLOBAL_SET_MAINTENANCE_MODE = 'GLOBAL_SET_MAINTENANCE_MODE'

/**
 * FETCH SYSTEM INFO
 * * Get system info
 */
export const getSystemInfo = () => async (dispatch) => {
  defaultRequest({
    request: () => api.get(config.api.system.root),
    onSuccess: ({ data }) => {
      // Dispatch success action
      dispatch({
        type: GLOBAL_SET_MAINTENANCE_MODE,
        state: data.is_maintenance_mode === 'true',
      })
    },
  })
}
