import React from 'react'
import { toast } from 'react-toastify'

import { getSetting } from 'utils/helpers/settings'

import UserToast from 'components/elements/toasts/UserToast'
import pluckySfx from 'assets/sounds/plucky.mp3'
import solemnSfx from 'assets/sounds/solemn.mp3'
import moonlessSfx from 'assets/sounds/moonless.mp3'
import NotificationToast from 'components/elements/toasts/NotificationToast'
import AlertToast from 'components/elements/toasts/alert-toast'

const userAudio = new Audio(pluckySfx)
const infoAudio = new Audio(solemnSfx)
const warningAudio = new Audio(moonlessSfx)

const getSoundByType = (type) => {
  switch (type) {
    case 'warning':
      return warningAudio
    default:
      return infoAudio
  }
}

export const displayUserToast = (text, user, options) => {
  if (getSetting('notifications.toasts.enabled')) {
    toast(<UserToast text={text} user={user} {...options} />, {
      onOpen: () => {
        if (getSetting('notifications.toasts.sounds')) {
          userAudio.currentTime = 0
          userAudio.volume = 0.2
          userAudio.play()
        }
      },
      containerId: 'toasts',
    })
  }
}

export const displayNotificationToast = (
  text,
  options = {},
  toastOptions = {}
) => {
  toast(<NotificationToast text={text} {...options} />, {
    onOpen: () => {
      const type = options.type || 'info'
      const audio = getSoundByType(type)

      if (getSetting('notifications.toasts.sounds')) {
        audio.currentTime = 0
        audio.volume = 0.8
        audio.play()
      }
    },
    containerId: 'toasts',
    ...toastOptions,
  })
}

export const displayAlert = (text, type) =>
  toast(<AlertToast {...{ text, type }} />, { containerId: 'alerts' })
