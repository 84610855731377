import React from 'react'
import { Button } from 'reactstrap'
import Date from 'components/elements/basic/Date'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const OrderStatusToast = ({ prev, current }) => (
  <div className="mt-1 d-flex align-items-center">
    <Button color="light" disabled size="xs">
      <Date date={prev} />
    </Button>
    <FontAwesomeIcon icon={faArrowRight} className="mx-2 small" />
    <Button color="light" disabled size="xs">
      <Date date={current} />
    </Button>
  </div>
)

export default OrderStatusToast
