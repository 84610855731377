import config from 'config'
import {
  CUTTING_PATTERNS_FETCH_REQUEST,
  CUTTING_PATTERNS_FETCH_SUCCESS,
  CUTTING_PATTERNS_FETCH_FAILURE,
  CUTTING_PATTERNS_CREATE_REQUEST,
  CUTTING_PATTERNS_CREATE_SUCCESS,
  CUTTING_PATTERNS_CREATE_FAILURE,
  CUTTING_PATTERNS_UPDATE_REQUEST,
  CUTTING_PATTERNS_UPDATE_SUCCESS,
  CUTTING_PATTERNS_UPDATE_FAILURE,
  CUTTING_PATTERNS_DELETE_REQUEST,
  CUTTING_PATTERNS_DELETE_SUCCESS,
  CUTTING_PATTERNS_DELETE_FAILURE,
  CUTTING_PATTERNS_TOGGLE_EDIT_MODAL,
  CUTTING_PATTERNS_TOGGLE_DELETE_MODAL,
  CUTTING_PATTERNS_FILTER_SET_KEYWORD,
  CUTTING_PATTERNS_FILTER_SET_PERTINAX_MODE,
  CUTTING_PATTERNS_FILTER_SET_CLEANING_MODE,
  CUTTING_PATTERNS_FILTER_SET_PUTTING_MODE,
  CUTTING_PATTERNS_CLEAR_FILTER,
  CUTTING_PATTERNS_SORT,
  CUTTING_PATTERNS_SET_ERRORS,
  CUTTING_PATTERNS_SET_CURRENT_PAGE,
  CUTTING_PATTERNS_SET_PAGINATION_PER_PAGE,
  CUTTING_PATTERNS_SET_QUERY_SETTINGS,
  CUTTING_PATTERNS_CLEAR,
} from '../actions/cutting_patterns'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  filter: {
    keyword: '',
    pertinaxMode: 0,
    cleaningMode: 0,
    puttingMode: 0,
  },
  sorting: {
    column: 'cutting_patterns.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
}

const cuttingPatternsReducer = (state = initialState, action) => {
  switch (action.type) {
    // CUTTING_PATTERNS_FETCH_REQUEST
    case CUTTING_PATTERNS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // CUTTING_PATTERNS_FETCH_SUCCESS
    case CUTTING_PATTERNS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // CUTTING_PATTERNS_FETCH_FAILURE
    case CUTTING_PATTERNS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // CUTTING_PATTERNS_CREATE_REQUEST
    // CUTTING_PATTERNS_UPDATE_REQUEST
    // CUTTING_PATTERNS_DELETE_REQUEST
    case CUTTING_PATTERNS_CREATE_REQUEST:
    case CUTTING_PATTERNS_UPDATE_REQUEST:
    case CUTTING_PATTERNS_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // CUTTING_PATTERNS_CREATE_SUCCESS
    // CUTTING_PATTERNS_UPDATE_SUCCESS
    // CUTTING_PATTERNS_DELETE_SUCCESS
    // CUTTING_PATTERNS_CREATE_FAILURE
    // CUTTING_PATTERNS_UPDATE_FAILURE
    // CUTTING_PATTERNS_DELETE_FAILURE
    case CUTTING_PATTERNS_CREATE_SUCCESS:
    case CUTTING_PATTERNS_UPDATE_SUCCESS:
    case CUTTING_PATTERNS_DELETE_SUCCESS:
    case CUTTING_PATTERNS_CREATE_FAILURE:
    case CUTTING_PATTERNS_UPDATE_FAILURE:
    case CUTTING_PATTERNS_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // CUTTING_PATTERNS_TOGGLE_EDIT_MODAL
    case CUTTING_PATTERNS_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // CUTTING_PATTERNS_TOGGLE_DELETE_MODAL
    case CUTTING_PATTERNS_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // CUTTING_PATTERNS_FILTER_SET_KEYWORD
    case CUTTING_PATTERNS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CUTTING_PATTERNS_FILTER_SET_PERTINAX_MODE
    case CUTTING_PATTERNS_FILTER_SET_PERTINAX_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          pertinaxMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CUTTING_PATTERNS_FILTER_SET_CLEANING_MODE
    case CUTTING_PATTERNS_FILTER_SET_CLEANING_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          cleaningMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CUTTING_PATTERNS_FILTER_SET_PUTTING_MODE
    case CUTTING_PATTERNS_FILTER_SET_PUTTING_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          puttingMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CUTTING_PATTERNS_CLEAR_FILTER
    case CUTTING_PATTERNS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          pertinaxMode: 0,
          cleaningMode: 0,
          puttingMode: 0,
        },
      }
    // CUTTING_PATTERNS_SORT
    case CUTTING_PATTERNS_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CUTTING_PATTERNS_SET_ERRORS
    case CUTTING_PATTERNS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // CUTTING_PATTERNS_SET_CURRENT_PAGE
    case CUTTING_PATTERNS_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // CUTTING_PATTERNS_SET_PAGINATION_PER_PAGE
    case CUTTING_PATTERNS_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // CUTTING_PATTERNS_SET_QUERY_SETTINGS
    case CUTTING_PATTERNS_SET_QUERY_SETTINGS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        filter: {
          ...state.filter,
          pertinaxMode: parseInt(action.pertinax_mode, 10) || 0,
          cleaningMode: parseInt(action.cleaning_mode, 10) || 0,
          puttingMode: parseInt(action.putting_mode, 10) || 0,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // CUTTING_PATTERNS_CLEAR
    case CUTTING_PATTERNS_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default cuttingPatternsReducer
