import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const PROCESS_TYPES_FETCH_REQUEST = 'PROCESS_TYPES_FETCH_REQUEST'
export const PROCESS_TYPES_FETCH_SUCCESS = 'PROCESS_TYPES_FETCH_SUCCESS'
export const PROCESS_TYPES_FETCH_FAILURE = 'PROCESS_TYPES_FETCH_FAILURE'
// CREATE
export const PROCESS_TYPES_CREATE_REQUEST = 'PROCESS_TYPES_CREATE_REQUEST'
export const PROCESS_TYPES_CREATE_SUCCESS = 'PROCESS_TYPES_CREATE_SUCCESS'
export const PROCESS_TYPES_CREATE_FAILURE = 'PROCESS_TYPES_CREATE_FAILURE'
// UPDATE
export const PROCESS_TYPES_UPDATE_REQUEST = 'PROCESS_TYPES_UPDATE_REQUEST'
export const PROCESS_TYPES_UPDATE_SUCCESS = 'PROCESS_TYPES_UPDATE_SUCCESS'
export const PROCESS_TYPES_UPDATE_FAILURE = 'PROCESS_TYPES_UPDATE_FAILURE'
// DELETE
export const PROCESS_TYPES_DELETE_REQUEST = 'PROCESS_TYPES_DELETE_REQUEST'
export const PROCESS_TYPES_DELETE_SUCCESS = 'PROCESS_TYPES_DELETE_SUCCESS'
export const PROCESS_TYPES_DELETE_FAILURE = 'PROCESS_TYPES_DELETE_FAILURE'
// DELETE
export const PROCESS_TYPES_MOVE_REQUEST = 'PROCESS_TYPES_MOVE_REQUEST'
export const PROCESS_TYPES_MOVE_SUCCESS = 'PROCESS_TYPES_MOVE_SUCCESS'
export const PROCESS_TYPES_MOVE_FAILURE = 'PROCESS_TYPES_MOVE_FAILURE'
// RESOURCES
export const PROCESS_TYPES_SET_AVAILABLE_MACHINES =
  'PROCESS_TYPES_SET_AVAILABLE_MACHINES'
// MODALS
export const PROCESS_TYPES_TOGGLE_ADD_MODAL = 'PROCESS_TYPES_TOGGLE_ADD_MODAL'
export const PROCESS_TYPES_TOGGLE_EDIT_MODAL = 'PROCESS_TYPES_TOGGLE_EDIT_MODAL'
export const PROCESS_TYPES_TOGGLE_DELETE_MODAL =
  'PROCESS_TYPES_TOGGLE_DELETE_MODAL'
// FILTERS
export const PROCESS_TYPES_FILTER_SET_KEYWORD =
  'PROCESS_TYPES_FILTER_SET_KEYWORD'
export const PROCESS_TYPES_FILTER_SELECT_MACHINE =
  'PROCESS_TYPES_FILTER_SELECT_MACHINE'
export const PROCESS_TYPES_CLEAR_FILTER = 'PROCESS_TYPES_CLEAR_FILTER'
export const PROCESS_TYPES_FILTER_SET_MACHINES_MODE =
  'PROCESS_TYPES_FILTER_SET_MACHINES_MODE'
export const PROCESS_TYPES_FILTER_SET_DEFAULT_MACHINE_MODE =
  'PROCESS_TYPES_FILTER_SET_DEFAULT_MACHINE_MODE'
// Other
export const PROCESS_TYPES_SET_ERRORS = 'PROCESS_TYPES_SET_ERRORS'
export const PROCESS_TYPES_SET_CURRENT_PAGE = 'PROCESS_TYPES_SET_CURRENT_PAGE'
export const PROCESS_TYPES_SET_PAGINATION_PER_PAGE =
  'PROCESS_TYPES_SET_PAGINATION_PER_PAGE'
export const PROCESS_TYPES_SET_QUERY_SETTINGS =
  'PROCESS_TYPES_SET_QUERY_SETTINGS'
export const PROCESS_TYPES_CLEAR = 'PROCESS_TYPES_CLEAR'

/**
 * FETCH PROCESS TYPES
 * * Get process types list with pagination
 */
export const fetchProcessTypes = () => async (dispatch, getState) => {
  // Get current state
  const {
    process_types: { pagination, filter },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.process_types.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          machines_mode: filter.machinesMode,
          default_machine_mode: filter.defaultMachineMode,
          machine_id: filter.machine,
        },
      }),
    beforeRequest: () => dispatch({ type: PROCESS_TYPES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: PROCESS_TYPES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: PROCESS_TYPES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('process_types:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE PROCESS TYPE
 * * Create process type and display validation errors
 */
export const createProcessType =
  ({ template, unit, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.process_types.root, {
          template: template || null,
          unit: unit || null,
          ...params,
        }),
      beforeRequest: () => dispatch({ type: PROCESS_TYPES_CREATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('process_types:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: PROCESS_TYPES_CREATE_SUCCESS })
        // Reload process types list
        dispatch(fetchProcessTypes())
        // Close modal and reset errors
        dispatch({ type: PROCESS_TYPES_TOGGLE_ADD_MODAL, state: false })
        dispatch({ type: PROCESS_TYPES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PROCESS_TYPES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PROCESS_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('process_types:alerts.cannot_create'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PROCESS_TYPES_TOGGLE_ADD_MODAL, state: false }),
    })

/**
 * UPDATE PROCESS TYPE
 * * Update process type and reload process types list
 */
export const updateProcessType =
  ({ id, name, short_name, template, unit, is_visible }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.process_types.root}/${id}`, {
          name,
          short_name,
          template,
          unit,
          is_visible,
        }),
      beforeRequest: () => dispatch({ type: PROCESS_TYPES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('process_types:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: PROCESS_TYPES_UPDATE_SUCCESS })
        // Reload process types list
        dispatch(fetchProcessTypes())
        // Close modal and reset errors
        dispatch({ type: PROCESS_TYPES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: PROCESS_TYPES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PROCESS_TYPES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PROCESS_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('process_types:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PROCESS_TYPES_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE PROCESS TYPE
 * * Delete process type and fetch process types list
 */
export const deleteProcessType = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.process_types.root}/${id}`),
    beforeRequest: () => dispatch({ type: PROCESS_TYPES_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(
        i18n.t('process_types:alerts.successfully_deleted'),
        'success'
      )
      // Disable loading
      dispatch({ type: PROCESS_TYPES_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: PROCESS_TYPES_TOGGLE_DELETE_MODAL, state: false })
      // Reload process types list
      dispatch(fetchProcessTypes())
    },
    onError: () => dispatch({ type: PROCESS_TYPES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('process_types:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: PROCESS_TYPES_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * MOVE PROCESS TYPE
 * * Move process type and fetch process types list
 */
export const moveProcessType =
  ({ id, direction }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.process_types.root}/${id}/move`, { direction }),
      beforeRequest: () => dispatch({ type: PROCESS_TYPES_MOVE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('process_types:alerts.successfully_moved'),
          'success'
        )
        // Disable loading
        dispatch({ type: PROCESS_TYPES_MOVE_SUCCESS })
        // Reload process types list
        dispatch(fetchProcessTypes())
      },
      onError: () => {
        dispatch({ type: PROCESS_TYPES_MOVE_FAILURE })
        displayAlert(i18n.t('process_types:alerts.cannot_move'), 'error')
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    })

/**
 * FETCH MACHINES
 * * Fetch machines list
 */
export const fetchMachines = () => async (dispatch) =>
  defaultRequest({
    request: () =>
      api.get(`${config.api.machines.root}`, { params: { per_page: 100 } }), // TODO: Update limit
    onSuccess: (response) =>
      dispatch({
        type: PROCESS_TYPES_SET_AVAILABLE_MACHINES,
        items: response.data,
      }),
    onError: () =>
      displayAlert(i18n.t('machines:alerts.cannot_fetch'), 'error'),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: PROCESS_TYPES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchProcessTypes())
}
