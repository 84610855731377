import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import Loading from 'components/pages/Loading'

const UnauthenticatedRoute = (props) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const session = useSelector((state) => state.session)

  const Layout = props.layout

  useEffect(() => {
    setIsChecked(session.isChecked)
    setIsSignedIn(session.isSignedIn)
  }, [session])

  if (!isChecked) {
    return <Loading />
  }

  if (isSignedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout>
      <Route {...props} />
    </Layout>
  )
}

export default UnauthenticatedRoute
