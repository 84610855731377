import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFoundError from 'components/pages/NotFound'

/* --------------------------------- Layouts -------------------------------- */
import UserLayout from 'components/layouts/UserLayout'
import GuestLayout from 'components/layouts/GuestLayout'

/* ------------------------------- Components ------------------------------- */
import AuthenticatedRoute from './AuthenticatedRoute'
import UnauthenticatedRoute from './UnauthenticatedRoute'

const Home = lazy(() => import('components/pages/Home'))
const AccountPermissions = lazy(
  () => import('components/pages/Account/Permissions')
)
const AccountSettings = lazy(() => import('components/pages/Account/Settings'))

const OrdersAll = lazy(() => import('components/pages/Orders/All'))
const OrdersAdd = lazy(() => import('components/pages/Orders/Add'))
const OrdersView = lazy(() => import('components/pages/Orders/View'))
const OrdersEdit = lazy(() => import('components/pages/Orders/Edit'))

const EventsList = lazy(() => import('components/pages/Events/List'))

const ContractorsView = lazy(() => import('components/pages/contractors/View'))
const ContractorsList = lazy(() => import('components/pages/contractors/list'))
const StoragesList = lazy(() => import('components/pages/storages/list'))
const StorageLocations = lazy(
  () => import('components/pages/storages/locations/list')
)
const PaperStocks = lazy(
  () => import('components/pages/warehouse/stocks/paper-stocks')
)
const AddressesList = lazy(() => import('components/pages/Addresses/List'))
const AddressView = lazy(() => import('components/pages/Addresses/View'))

const PaperDemandsList = lazy(
  () => import('components/pages/PaperDemands/List')
)
const PaperMaterialsList = lazy(
  () => import('components/pages/Materials/List/PaperMaterialsList')
)

const PaperSizesList = lazy(() => import('components/pages/PaperSizes/List'))
const PaperTypeGroupsList = lazy(
  () => import('components/pages/PaperTypeGroups/List')
)
const PaperTypesList = lazy(() => import('components/pages/PaperTypes/List'))
const ColorTypesList = lazy(() => import('components/pages/ColorTypes/List'))
const VarnishTypesList = lazy(
  () => import('components/pages/VarnishTypes/List')
)
const CuttingPatternsList = lazy(
  () => import('components/pages/CuttingPatterns/List')
)
const MachinesList = lazy(() => import('components/pages/Machines/List'))
const ProcessTypesList = lazy(
  () => import('components/pages/ProcessTypes/List')
)

const DeliveryNotesList = lazy(
  () => import('components/pages/DeliveryNotes/List')
)
const DeliveryNotesView = lazy(
  () => import('components/pages/DeliveryNotes/View')
)
const DeliveryNotesAdd = lazy(
  () => import('components/pages/DeliveryNotes/Add')
)
const DeliveryNotesAddByOrder = lazy(
  () => import('components/pages/DeliveryNotes/AddByOrder')
)
const DeliveryNotesEdit = lazy(
  () => import('components/pages/DeliveryNotes/Edit')
)

const SignIn = lazy(() => import('components/pages/SignIn'))
const SignUp = lazy(() => import('components/pages/SignUp'))
const ResetPassword = lazy(() => import('components/pages/ResetPassword'))

const EmployeesList = lazy(() => import('components/pages/Employees/List'))
const IssuesList = lazy(() => import('components/pages/Issues/List'))
const EmployeeTimeEntriesList = lazy(
  () => import('components/pages/EmployeeTimeEntries/List')
)

const UsersList = lazy(() => import('components/pages/Users/List'))
const UsersView = lazy(() => import('components/pages/Users/View'))
const RolesList = lazy(() => import('components/pages/Roles/List'))
const RolesPermissions = lazy(
  () => import('components/pages/Roles/Permissions')
)
const UsersPermissions = lazy(
  () => import('components/pages/Users/Permissions')
)
const RegistrationKeysList = lazy(
  () => import('components/pages/RegistrationKeys/List')
)
const VersionsList = lazy(() => import('components/pages/Versions/List'))
const VersionsView = lazy(() => import('components/pages/Versions/View'))
const EmployeeProductivity = lazy(
  () => import('components/pages/Employees/Productivity')
)

const Changelog = lazy(() => import('components/pages/changelog'))
const Docs = lazy(() => import('components/pages/Docs'))

/* -------------------------------------------------------------------------- */
/*                              Routes Component                              */
/* -------------------------------------------------------------------------- */
const Routes = () => (
  <Switch>
    <AuthenticatedRoute exact path="/" component={Home} layout={UserLayout} />
    {/* Account */}
    <AuthenticatedRoute
      exact
      path="/account/permissions"
      component={AccountPermissions}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/account/settings"
      component={AccountSettings}
      layout={UserLayout}
    />
    {/* Orders */}
    <AuthenticatedRoute
      exact
      path="/orders"
      component={OrdersAll}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/orders/new"
      component={OrdersAdd}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/orders/:id/edit"
      component={OrdersEdit}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/orders/:id/:view?"
      component={OrdersView}
      layout={UserLayout}
    />
    {/* Events */}
    <AuthenticatedRoute
      exact
      path="/machines/:machine_id/events"
      component={EventsList}
      layout={UserLayout}
    />
    {/* Contractors */}
    <AuthenticatedRoute
      exact
      path="/contractors"
      component={ContractorsList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/contractors/:id"
      component={ContractorsView}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/storages"
      component={StoragesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/storages/:id/locations"
      component={StorageLocations}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/warehouse/stocks/paper"
      component={PaperStocks}
      layout={UserLayout}
    />
    {/* Addresses */}
    <AuthenticatedRoute
      exact
      path="/addresses"
      component={AddressesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/addresses/:id"
      component={AddressView}
      layout={UserLayout}
    />
    {/* Logistics */}
    <AuthenticatedRoute
      exact
      path="/delivery_notes/new"
      component={DeliveryNotesAdd}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/delivery_notes/new/:order_id"
      component={DeliveryNotesAddByOrder}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/delivery_notes/:id/edit"
      component={DeliveryNotesEdit}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/delivery_notes/:id/printing_preview"
      component={DeliveryNotesView}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/delivery_notes/order/:order_id"
      component={DeliveryNotesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/delivery_notes"
      component={DeliveryNotesList}
      layout={UserLayout}
    />
    {/* Demands */}
    <AuthenticatedRoute
      exact
      path="/paper_demands"
      component={PaperDemandsList}
      layout={UserLayout}
    />
    {/* Materials */}
    <AuthenticatedRoute
      exact
      path="/materials/:type"
      component={PaperMaterialsList}
      layout={UserLayout}
    />
    {/* Components */}
    <AuthenticatedRoute
      exact
      path="/paper_sizes"
      component={PaperSizesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/paper_type_groups"
      component={PaperTypeGroupsList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/paper_types"
      component={PaperTypesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/color_types"
      component={ColorTypesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/varnish_types"
      component={VarnishTypesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/cutting_patterns"
      component={CuttingPatternsList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/machines"
      component={MachinesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/process_types"
      component={ProcessTypesList}
      layout={UserLayout}
    />
    {/* Management */}
    <AuthenticatedRoute
      exact
      path="/employees"
      component={EmployeesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/employees/:id/productivity"
      component={EmployeeProductivity}
      layout={UserLayout}
    />
    {/* Issues */}
    <AuthenticatedRoute
      exact
      path="/issues"
      component={IssuesList}
      layout={UserLayout}
    />
    {/* Employee Time Entries */}
    <AuthenticatedRoute
      exact
      path="/employee_time_entries"
      component={EmployeeTimeEntriesList}
      layout={UserLayout}
    />
    {/* System */}
    <AuthenticatedRoute
      exact
      path="/users"
      component={UsersList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/users/:id"
      component={UsersView}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/users/:id/permissions"
      component={UsersPermissions}
      layout={UserLayout}
    />

    <AuthenticatedRoute
      exact
      path="/roles"
      component={RolesList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/roles/:id/permissions"
      component={RolesPermissions}
      layout={UserLayout}
    />

    <AuthenticatedRoute
      exact
      path="/registration_keys"
      component={RegistrationKeysList}
      layout={UserLayout}
    />

    <AuthenticatedRoute
      exact
      path="/versions"
      component={VersionsList}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/versions/:id"
      component={VersionsView}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/docs/:name"
      component={Docs}
      layout={UserLayout}
    />
    <AuthenticatedRoute
      exact
      path="/changelog"
      component={Changelog}
      layout={UserLayout}
    />
    {/* Other */}
    <UnauthenticatedRoute
      exact
      path="/signin"
      component={SignIn}
      layout={GuestLayout}
    />
    <UnauthenticatedRoute
      exact
      path="/signup/:key?"
      component={SignUp}
      layout={GuestLayout}
    />
    <UnauthenticatedRoute
      exact
      path="/reset/:token?"
      component={ResetPassword}
      layout={GuestLayout}
    />
    <Route component={NotFoundError} layout={GuestLayout} />
  </Switch>
)

export default Routes
