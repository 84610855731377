import config from 'config'

import {
  VERSIONS_FETCH_REQUEST,
  VERSIONS_FETCH_SUCCESS,
  VERSIONS_FETCH_FAILURE,
  VERSIONS_FILTER_SET_KEYWORD,
  VERSIONS_FILTER_SELECT_AUTHOR,
  VERSIONS_CLEAR_FILTER,
  VERSIONS_SORT,
  VERSIONS_SET_CURRENT_PAGE,
  VERSIONS_SET_PAGINATION_PER_PAGE,
  VERSIONS_SET_QUERY_SETTINGS,
  VERSIONS_CLEAR,
} from '../actions/versions'

const initialState = {
  items: [],
  isLoading: true,
  loadingFailed: false,
  filter: {
    keyword: '',
    author: null,
  },
  sorting: {
    column: 'versions.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  errors: [],
  settingsFetched: false,
}

const versionsReducer = (state = initialState, action) => {
  switch (action.type) {
    // VERSIONS_FETCH_REQUEST
    case VERSIONS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // VERSIONS_FETCH_SUCCESS
    case VERSIONS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // VERSIONS_FETCH_FAILURE
    case VERSIONS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // VERSIONS_FILTER_SET_KEYWORD
    case VERSIONS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // VERSIONS_FILTER_SELECT_AUTHOR
    case VERSIONS_FILTER_SELECT_AUTHOR:
      return {
        ...state,
        filter: {
          ...state.filter,
          author: action.author,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // VERSIONS_CLEAR_FILTER
    case VERSIONS_CLEAR_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      }
    // VERSIONS_SORT
    case VERSIONS_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // VERSIONS_SET_CURRENT_PAGE
    case VERSIONS_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // VERSIONS_SET_PAGINATION_PER_PAGE
    case VERSIONS_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // VERSIONS_SET_QUERY_SETTINGS
    case VERSIONS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          author: parseInt(action.author, 10) || null,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // VERSIONS_CLEAR
    case VERSIONS_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default versionsReducer
