import config from 'config'
import {
  PAPER_SIZES_FETCH_REQUEST,
  PAPER_SIZES_FETCH_SUCCESS,
  PAPER_SIZES_FETCH_FAILURE,
  PAPER_SIZES_CREATE_REQUEST,
  PAPER_SIZES_CREATE_SUCCESS,
  PAPER_SIZES_CREATE_FAILURE,
  PAPER_SIZES_UPDATE_REQUEST,
  PAPER_SIZES_UPDATE_SUCCESS,
  PAPER_SIZES_UPDATE_FAILURE,
  PAPER_SIZES_DELETE_REQUEST,
  PAPER_SIZES_DELETE_SUCCESS,
  PAPER_SIZES_DELETE_FAILURE,
  PAPER_SIZES_TOGGLE_ADD_MODAL,
  PAPER_SIZES_TOGGLE_EDIT_MODAL,
  PAPER_SIZES_TOGGLE_DELETE_MODAL,
  PAPER_SIZES_FILTER_SET_KEYWORD,
  PAPER_SIZES_FILTER_SET_WIDTH,
  PAPER_SIZES_FILTER_SET_HEIGHT,
  PAPER_SIZES_CLEAR_FILTER,
  PAPER_SIZES_SORT,
  PAPER_SIZES_SET_ERRORS,
  PAPER_SIZES_SET_CURRENT_PAGE,
  PAPER_SIZES_SET_PAGINATION_PER_PAGE,
  PAPER_SIZES_SET_QUERY_SETTINGS,
  PAPER_SIZES_CLEAR,
  PAPER_SIZES_ADD_ITEM_SELECTION,
  PAPER_SIZES_REMOVE_ITEM_SELECTION,
  PAPER_SIZES_CLEAR_ITEM_SELECTIONS,
  PAPER_SIZES_TOGGLE_ITEM_SELECTIONS,
  PAPER_SIZES_TOGGLE_REPLACE_MODAL,
} from '../actions/paper_sizes'

const initialState = {
  items: [],
  selectedItems: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  isReplaceModalOpen: false,
  filter: {
    keyword: '',
    width: {
      min: config.inputs.range.paper_sizes.min,
      max: config.inputs.range.paper_sizes.max,
    },
    height: {
      min: config.inputs.range.paper_sizes.min,
      max: config.inputs.range.paper_sizes.max,
    },
  },
  sorting: {
    column: 'paper_sizes.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
}

const paperSizesReducer = (state = initialState, action) => {
  switch (action.type) {
    // PAPER_SIZES_FETCH_REQUEST
    case PAPER_SIZES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // PAPER_SIZES_FETCH_SUCCESS
    case PAPER_SIZES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // PAPER_SIZES_FETCH_FAILURE
    case PAPER_SIZES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // PAPER_SIZES_CREATE_REQUEST
    // PAPER_SIZES_UPDATE_REQUEST
    // PAPER_SIZES_DELETE_REQUEST
    case PAPER_SIZES_CREATE_REQUEST:
    case PAPER_SIZES_UPDATE_REQUEST:
    case PAPER_SIZES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // PAPER_SIZES_CREATE_SUCCESS
    // PAPER_SIZES_UPDATE_SUCCESS
    // PAPER_SIZES_DELETE_SUCCESS
    // PAPER_SIZES_CREATE_FAILURE
    // PAPER_SIZES_UPDATE_FAILURE
    // PAPER_SIZES_DELETE_FAILURE
    case PAPER_SIZES_CREATE_SUCCESS:
    case PAPER_SIZES_UPDATE_SUCCESS:
    case PAPER_SIZES_DELETE_SUCCESS:
    case PAPER_SIZES_CREATE_FAILURE:
    case PAPER_SIZES_UPDATE_FAILURE:
    case PAPER_SIZES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // PAPER_SIZES_TOGGLE_ADD_MODAL
    case PAPER_SIZES_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }
    // PAPER_SIZES_TOGGLE_EDIT_MODAL
    case PAPER_SIZES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // PAPER_SIZES_TOGGLE_DELETE_MODAL
    case PAPER_SIZES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // PAPER_SIZES_TOGGLE_REPLACE_MODAL
    case PAPER_SIZES_TOGGLE_REPLACE_MODAL:
      return {
        ...state,
        isReplaceModalOpen: action.state,
      }
    // PAPER_SIZES_FILTER_SET_KEYWORD
    case PAPER_SIZES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PAPER_SIZES_FILTER_SET_WIDTH
    case PAPER_SIZES_FILTER_SET_WIDTH:
      return {
        ...state,
        filter: {
          ...state.filter,
          width: action.values,
        },
      }
    // PAPER_SIZES_FILTER_SET_HEIGHT
    case PAPER_SIZES_FILTER_SET_HEIGHT:
      return {
        ...state,
        filter: {
          ...state.filter,
          height: action.values,
        },
      }
    // PAPER_SIZES_CLEAR_FILTER
    case PAPER_SIZES_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          width: initialState.filter.width,
          height: initialState.filter.height,
        },
      }

    // PAPER_SIZES_ADD_ITEM_SELECTION
    case PAPER_SIZES_ADD_ITEM_SELECTION:
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.id],
      }
    // PAPER_SIZES_REMOVE_ITEM_SELECTION
    case PAPER_SIZES_REMOVE_ITEM_SELECTION:
      return {
        ...state,
        selectedItems: state.selectedItems.filter((id) => id !== action.id),
      }
    // PAPER_SIZES_TOGGLE_ITEM_SELECTIONS
    case PAPER_SIZES_TOGGLE_ITEM_SELECTIONS:
      if (state.selectedItems.length === state.items.length) {
        return {
          ...state,
          selectedItems: initialState.selectedItems,
        }
      }

      return {
        ...state,
        selectedItems: state.items.map(({ id }) => id),
      }
    // PAPER_SIZES_CLEAR_ITEM_SELECTIONS
    case PAPER_SIZES_CLEAR_ITEM_SELECTIONS:
      return {
        ...state,
        selectedItems: initialState.selectedItems,
      }
    // PAPER_SIZES_SORT
    case PAPER_SIZES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PAPER_SIZES_SET_ERRORS
    case PAPER_SIZES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // PAPER_SIZES_SET_CURRENT_PAGE
    case PAPER_SIZES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // PAPER_SIZES_SET_PAGINATION_PER_PAGE
    case PAPER_SIZES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // PAPER_SIZES_SET_QUERY_SETTINGS
    case PAPER_SIZES_SET_QUERY_SETTINGS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        filter: {
          ...state.filter,
          width: {
            min:
              parseInt(action.width.min, 10) || initialState.filter.width.min,
            max:
              parseInt(action.width.max, 10) || initialState.filter.width.max,
          },
          height: {
            min:
              parseInt(action.height.min, 10) || initialState.filter.height.min,
            max:
              parseInt(action.height.max, 10) || initialState.filter.height.max,
          },
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // PAPER_SIZES_CLEAR
    case PAPER_SIZES_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default paperSizesReducer
