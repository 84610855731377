import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const MACHINES_FETCH_REQUEST = 'MACHINES_FETCH_REQUEST'
export const MACHINES_FETCH_SUCCESS = 'MACHINES_FETCH_SUCCESS'
export const MACHINES_FETCH_FAILURE = 'MACHINES_FETCH_FAILURE'
// CREATE
export const MACHINES_CREATE_REQUEST = 'MACHINES_CREATE_REQUEST'
export const MACHINES_CREATE_SUCCESS = 'MACHINES_CREATE_SUCCESS'
export const MACHINES_CREATE_FAILURE = 'MACHINES_CREATE_FAILURE'
// UPDATE
export const MACHINES_UPDATE_REQUEST = 'MACHINES_UPDATE_REQUEST'
export const MACHINES_UPDATE_SUCCESS = 'MACHINES_UPDATE_SUCCESS'
export const MACHINES_UPDATE_FAILURE = 'MACHINES_UPDATE_FAILURE'
// DELETE
export const MACHINES_DELETE_REQUEST = 'MACHINES_DELETE_REQUEST'
export const MACHINES_DELETE_SUCCESS = 'MACHINES_DELETE_SUCCESS'
export const MACHINES_DELETE_FAILURE = 'MACHINES_DELETE_FAILURE'
// MODALS
export const MACHINES_TOGGLE_ADD_MODAL = 'MACHINES_TOGGLE_ADD_MODAL'
export const MACHINES_TOGGLE_EDIT_MODAL = 'MACHINES_TOGGLE_EDIT_MODAL'
export const MACHINES_TOGGLE_DELETE_MODAL = 'MACHINES_TOGGLE_DELETE_MODAL'
// FILTERS
export const MACHINES_FILTER_SET_KEYWORD = 'MACHINES_FILTER_SET_KEYWORD'
export const MACHINES_FILTER_SELECT_TEMPLATE = 'MACHINES_FILTER_SELECT_TEMPLATE'
export const MACHINES_CLEAR_FILTER = 'MACHINES_CLEAR_FILTER'
// Other
export const MACHINES_SORT = 'MACHINES_SORT'
export const MACHINES_SET_ERRORS = 'MACHINES_SET_ERRORS'
export const MACHINES_SET_CURRENT_PAGE = 'MACHINES_SET_CURRENT_PAGE'
export const MACHINES_SET_PAGINATION_PER_PAGE =
  'MACHINES_SET_PAGINATION_PER_PAGE'
export const MACHINES_SET_QUERY_SETTINGS = 'MACHINES_SET_QUERY_SETTINGS'
export const MACHINES_CLEAR = 'MACHINES_CLEAR'

/**
 * FETCH MACHINES
 * * Get machines list with pagination
 */
export const fetchMachines = () => async (dispatch, getState) => {
  // Get current state
  const {
    machines: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.machines.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          template: filter.template,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: MACHINES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: MACHINES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: MACHINES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('machines:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE MACHINE
 * * Create machine and display validation errors
 */
export const createMachine =
  ({ template, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.machines.root, {
          template: template || null,
          ...params,
        }),
      beforeRequest: () => dispatch({ type: MACHINES_CREATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('machines:alerts.successfully_created'), 'success')
        // Disable loading
        dispatch({ type: MACHINES_CREATE_SUCCESS })
        // Reload machines list
        dispatch(fetchMachines())
        // Close modal and reset errors
        dispatch({ type: MACHINES_TOGGLE_ADD_MODAL, state: false })
        dispatch({ type: MACHINES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: MACHINES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: MACHINES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('machines:alerts.cannot_create'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: MACHINES_TOGGLE_ADD_MODAL, state: false }),
    })

/**
 * UPDATE MACHINE
 * * Update machine and reload machines list
 */
export const updateMachine =
  ({ id, name, template }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.machines.root}/${id}`, { name, template }),
      beforeRequest: () => dispatch({ type: MACHINES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('machines:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: MACHINES_UPDATE_SUCCESS })
        // Reload machines list
        dispatch(fetchMachines())
        // Close modal and reset errors
        dispatch({ type: MACHINES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: MACHINES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: MACHINES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: MACHINES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('machines:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: MACHINES_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE MACHINE
 * * Delete machine and fetch machines list
 */
export const deleteMachine = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.machines.root}/${id}`),
    beforeRequest: () => dispatch({ type: MACHINES_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('machines:alerts.successfully_deleted'), 'success')
      // Disable loading
      dispatch({ type: MACHINES_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: MACHINES_TOGGLE_DELETE_MODAL, state: false })
      // Reload machines list
      dispatch(fetchMachines())
    },
    onError: () => dispatch({ type: MACHINES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('machines:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: MACHINES_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: MACHINES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchMachines())
}
