import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Loading = () => (
  <div className="gd-fullscreen-loading">
    <FontAwesomeIcon icon={faCircleNotch} spin />
  </div>
)

export default Loading
