import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const VARNISH_TYPES_FETCH_REQUEST = 'VARNISH_TYPES_FETCH_REQUEST'
export const VARNISH_TYPES_FETCH_SUCCESS = 'VARNISH_TYPES_FETCH_SUCCESS'
export const VARNISH_TYPES_FETCH_FAILURE = 'VARNISH_TYPES_FETCH_FAILURE'
// CREATE
export const VARNISH_TYPES_CREATE_REQUEST = 'VARNISH_TYPES_CREATE_REQUEST'
export const VARNISH_TYPES_CREATE_SUCCESS = 'VARNISH_TYPES_CREATE_SUCCESS'
export const VARNISH_TYPES_CREATE_FAILURE = 'VARNISH_TYPES_CREATE_FAILURE'
// UPDATE
export const VARNISH_TYPES_UPDATE_REQUEST = 'VARNISH_TYPES_UPDATE_REQUEST'
export const VARNISH_TYPES_UPDATE_SUCCESS = 'VARNISH_TYPES_UPDATE_SUCCESS'
export const VARNISH_TYPES_UPDATE_FAILURE = 'VARNISH_TYPES_UPDATE_FAILURE'
// DELETE
export const VARNISH_TYPES_DELETE_REQUEST = 'VARNISH_TYPES_DELETE_REQUEST'
export const VARNISH_TYPES_DELETE_SUCCESS = 'VARNISH_TYPES_DELETE_SUCCESS'
export const VARNISH_TYPES_DELETE_FAILURE = 'VARNISH_TYPES_DELETE_FAILURE'
// REPLACE
export const VARNISH_TYPES_REPLACE_REQUEST = 'VARNISH_TYPES_REPLACE_REQUEST'
export const VARNISH_TYPES_REPLACE_SUCCESS = 'VARNISH_TYPES_REPLACE_SUCCESS'
export const VARNISH_TYPES_REPLACE_FAILURE = 'VARNISH_TYPES_REPLACE_FAILURE'
// MODALS
export const VARNISH_TYPES_TOGGLE_ADD_MODAL = 'VARNISH_TYPES_TOGGLE_ADD_MODAL'
export const VARNISH_TYPES_TOGGLE_EDIT_MODAL = 'VARNISH_TYPES_TOGGLE_EDIT_MODAL'
export const VARNISH_TYPES_TOGGLE_DELETE_MODAL =
  'VARNISH_TYPES_TOGGLE_DELETE_MODAL'
// FILTERS
export const VARNISH_TYPES_FILTER_SET_KEYWORD =
  'VARNISH_TYPES_FILTER_SET_KEYWORD'
export const VARNISH_TYPES_CLEAR_FILTER = 'VARNISH_TYPES_CLEAR_FILTER'
// SELECTION
export const VARNISH_TYPES_ADD_ITEM_SELECTION =
  'VARNISH_TYPES_ADD_ITEM_SELECTION'
export const VARNISH_TYPES_REMOVE_ITEM_SELECTION =
  'VARNISH_TYPES_REMOVE_ITEM_SELECTION'
export const VARNISH_TYPES_TOGGLE_ITEM_SELECTIONS =
  'VARNISH_TYPES_TOGGLE_ITEM_SELECTIONS'
export const VARNISH_TYPES_CLEAR_ITEM_SELECTIONS =
  'VARNISH_TYPES_CLEAR_ITEM_SELECTIONS'
// Other
export const VARNISH_TYPES_SORT = 'VARNISH_TYPES_SORT'
export const VARNISH_TYPES_SET_ERRORS = 'VARNISH_TYPES_SET_ERRORS'
export const VARNISH_TYPES_SET_CURRENT_PAGE = 'VARNISH_TYPES_SET_CURRENT_PAGE'
export const VARNISH_TYPES_SET_PAGINATION_PER_PAGE =
  'VARNISH_TYPES_SET_PAGINATION_PER_PAGE'
export const VARNISH_TYPES_SET_QUERY_SETTINGS =
  'VARNISH_TYPES_SET_QUERY_SETTINGS'
export const VARNISH_TYPES_CLEAR = 'VARNISH_TYPES_CLEAR'
export const VARNISH_TYPES_TOGGLE_REPLACE_MODAL =
  'VARNISH_TYPES_TOGGLE_REPLACE_MODAL'

/**
 * FETCH VARNISH TYPES
 * * Get varnish types list with pagination
 */
export const fetchVarnishTypes = () => async (dispatch, getState) => {
  // Get current state
  const {
    varnish_types: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.varnish_types.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: VARNISH_TYPES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: VARNISH_TYPES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: VARNISH_TYPES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('varnish_types:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE VARNISH TYPE
 * * Create varnish type and display validation errors
 */
export const createVarnishType =
  ({ name }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.post(config.api.varnish_types.root, { name }),
      beforeRequest: () => dispatch({ type: VARNISH_TYPES_CREATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('varnish_types:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: VARNISH_TYPES_CREATE_SUCCESS })
        // Reload varnish types list
        dispatch(fetchVarnishTypes())
        // Close modal and reset errors
        dispatch({ type: VARNISH_TYPES_TOGGLE_ADD_MODAL, state: false })
        dispatch({ type: VARNISH_TYPES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: VARNISH_TYPES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: VARNISH_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('varnish_types:alerts.cannot_create'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: VARNISH_TYPES_TOGGLE_ADD_MODAL, state: false }),
    })

/**
 * UPDATE VARNISH TYPE
 * * Update varnish type and reload varnish types list
 */
export const updateVarnishType =
  ({ id, name }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.varnish_types.root}/${id}`, { name }),
      beforeRequest: () => dispatch({ type: VARNISH_TYPES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('varnish_types:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: VARNISH_TYPES_UPDATE_SUCCESS })
        // Reload varnish types list
        dispatch(fetchVarnishTypes())
        // Close modal and reset errors
        dispatch({ type: VARNISH_TYPES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: VARNISH_TYPES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: VARNISH_TYPES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: VARNISH_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('varnish_types:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: VARNISH_TYPES_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE VARNISH TYPE
 * * Delete varnish type and fetch varnish types list
 */
export const deleteVarnishType = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.varnish_types.root}/${id}`),
    beforeRequest: () => dispatch({ type: VARNISH_TYPES_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(
        i18n.t('varnish_types:alerts.successfully_deleted'),
        'success'
      )
      // Disable loading
      dispatch({ type: VARNISH_TYPES_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: VARNISH_TYPES_TOGGLE_DELETE_MODAL, state: false })
      // Reload varnish types list
      dispatch(fetchVarnishTypes())
    },
    onError: () => dispatch({ type: VARNISH_TYPES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('varnish_types:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: VARNISH_TYPES_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: VARNISH_TYPES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchVarnishTypes())
}

/**
 * REPLACE VARNISH TYPES
 * * Replace selected varnish types
 */
export const replaceVarnishTypes =
  ({ target_varnish_type_id, varnish_type_ids }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(config.api.varnish_types.replace, {
          target_varnish_type_id,
          varnish_type_ids,
        }),
      beforeRequest: () => dispatch({ type: VARNISH_TYPES_REPLACE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('varnish_types:alerts.successfully_replaced'),
          'success'
        )

        // Disable loading
        dispatch({ type: VARNISH_TYPES_REPLACE_SUCCESS })
        // Reload paper demands list
        dispatch(fetchVarnishTypes())
        // Close modal and reset errors
        dispatch({ type: VARNISH_TYPES_TOGGLE_REPLACE_MODAL, state: false })
        dispatch({ type: VARNISH_TYPES_SET_ERRORS, errors: [] })
        // Clear selections
        dispatch({ type: VARNISH_TYPES_CLEAR_ITEM_SELECTIONS })
      },
      onError: () => dispatch({ type: VARNISH_TYPES_REPLACE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: VARNISH_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('varnish_types:alerts.cannot_replace'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: VARNISH_TYPES_TOGGLE_REPLACE_MODAL, state: false }),
    })
