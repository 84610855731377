import axios from 'axios'
import config from 'config'
import store from 'store'
import {
  GLOBAL_SET_NETWORK_ERROR,
  GLOBAL_SET_SESSION_EXPIRED,
  GLOBAL_SET_NOT_FOUND,
} from 'redux/actions/global'
import { signOut } from 'redux/actions/session'

const api = axios.create({
  baseURL: config.api.fullUrl,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem('token')}`
      },
    },
  },
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      store.dispatch({ type: GLOBAL_SET_NETWORK_ERROR })
    } else {
      switch (error.response.status) {
        case 401:
          store.dispatch({ type: GLOBAL_SET_SESSION_EXPIRED })
          store.dispatch(signOut())
          break
        case 404:
          store.dispatch({ type: GLOBAL_SET_NOT_FOUND })
          break
        default:
      }
    }
    return Promise.reject(error)
  }
)

export default api
