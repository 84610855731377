export const parsePagination = (headers) => {
  const pagination = {
    page: parseInt(headers['x-page'], 10),
    per_page: parseInt(headers['x-per-page'], 10),
    total: parseInt(headers['x-total'], 10),
  }

  const max = Math.ceil(pagination.total / pagination.per_page)
  return { ...pagination, page: pagination.page > max ? max : pagination.page }
}

export default parsePagination
