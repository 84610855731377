import config from 'config'

import {
  USERS_FETCH_REQUEST,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAILURE,
  USERS_UPDATE_REQUEST,
  USERS_UPDATE_SUCCESS,
  USERS_UPDATE_FAILURE,
  USERS_TOGGLE_EDIT_MODAL,
  USERS_FILTER_SET_KEYWORD,
  USERS_SELECT_TYPE,
  USERS_SELECT_ROLE,
  USERS_CLEAR_FILTER,
  USERS_SORT,
  USERS_SET_ERRORS,
  USERS_SET_CURRENT_PAGE,
  USERS_SET_PAGINATION_PER_PAGE,
  USERS_SET_QUERY_SETTINGS,
  USERS_CLEAR,
  USERS_SET_ROLES_MODE,
  USERS_SET_ACTIVE_MODE,
} from '../actions/users'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  filter: {
    keyword: '',
    userType: null,
    role: null,
    rolesMode: 0,
    activeMode: 0,
  },
  sorting: {
    column: 'users.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  availableRoles: [],
  errors: [],
  settingsFetched: false,
}

const contractorsReducer = (state = initialState, action) => {
  switch (action.type) {
    // USERS_FETCH_REQUEST
    case USERS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // USERS_FETCH_SUCCESS
    case USERS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // USERS_FETCH_FAILURE
    case USERS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // USERS_UPDATE_REQUEST
    case USERS_UPDATE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // USERS_UPDATE_SUCCESS
    // USERS_UPDATE_FAILURE
    case USERS_UPDATE_SUCCESS:
    case USERS_UPDATE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // USERS_TOGGLE_EDIT_MODAL
    case USERS_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // USERS_FILTER_SET_KEYWORD
    case USERS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }

    // USERS_SELECT_TYPE
    case USERS_SELECT_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          userType: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // USERS_SELECT_ROLE
    case USERS_SELECT_ROLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          role: action.role,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // USERS_SET_ROLES_MODE
    case USERS_SET_ROLES_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          rolesMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // USERS_SET_ACTIVE_MODE
    case USERS_SET_ACTIVE_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          activeMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // USERS_CLEAR_FILTER
    case USERS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          role: null,
          rolesMode: 0,
          activeMode: 0,
        },
      }
    // USERS_SORT
    case USERS_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // USERS_SET_ERRORS
    case USERS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // USERS_SET_CURRENT_PAGE
    case USERS_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // USERS_SET_PAGINATION_PER_PAGE
    case USERS_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // USERS_SET_QUERY_SETTINGS
    case USERS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          userType: action.user_type || null,
          role: parseInt(action.role, 10) || null,
          rolesMode: parseInt(action.roles_mode, 10) || 0,
          activeMode: parseInt(action.active_mode, 10) || 0,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // USERS_CLEAR
    case USERS_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default contractorsReducer
