import React from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useTranslation } from 'react-i18next'

/* ---------------------------------- Icons --------------------------------- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons'

/* -------------------------------------------------------------------------- */
/*                        Notification Toast Component                        */
/* -------------------------------------------------------------------------- */
const NotificationToast = ({ text, type = 'info' }) => {
  const [t] = useTranslation()

  const getIcon = () => {
    switch (type) {
      case 'warning':
        return faTriangleExclamation
      default:
        return faInfoCircle
    }
  }

  /* --------------------------------- Render --------------------------------- */
  return (
    <div className={`gd-toast gd-toast--${type}`}>
      <header className="gd-toast__header">
        <FontAwesomeIcon icon={getIcon()} className="me-2" />{' '}
        {t(`notifications.${type}.title`)}
      </header>
      <div className="gd-toast__body">{text}</div>
    </div>
  )
}

export default NotificationToast
