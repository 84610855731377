import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

import Logo from 'assets/images/logo-dark.png'

const NotFound = ({ onExit }) => {
  const [t] = useTranslation()

  return (
    <div className="vh-100 bg-dark d-flex justify-content-center align-items-center">
      <div className="row mx-5">
        <div className="col-3 pe-4 d-none d-md-block">
          <Link to="/">
            <img src={Logo} alt="" className="img-fluid" />
          </Link>
        </div>
        <div className="col-12 col-md-9 ps-4 text-white text-center text-md-left">
          <div className="h2">{t('errors.not_found.title')}</div>
          <p>{t('errors.not_found.content')}</p>
          <Link to="/">
            <Button
              color="light"
              style={{ textDecoration: 'none' }}
              onClick={onExit}
            >
              {t('actions.go_home')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
