// import testItems from '../../components/pages/JobsReports/List/List.config'

import moment from 'moment'
import {
  EMPLOYEE_TIME_ENTRIES_FETCH_REQUEST,
  EMPLOYEE_TIME_ENTRIES_FETCH_SUCCESS,
  EMPLOYEE_TIME_ENTRIES_FETCH_FAILURE,
  EMPLOYEE_TIME_ENTRIES_UPDATE_REQUEST,
  EMPLOYEE_TIME_ENTRIES_UPDATE_SUCCESS,
  EMPLOYEE_TIME_ENTRIES_UPDATE_FAILURE,
  EMPLOYEE_TIME_ENTRIES_DELETE_REQUEST,
  EMPLOYEE_TIME_ENTRIES_DELETE_SUCCESS,
  EMPLOYEE_TIME_ENTRIES_DELETE_FAILURE,
  EMPLOYEE_TIME_ENTRIES_TOGGLE_EDIT_MODAL,
  EMPLOYEE_TIME_ENTRIES_TOGGLE_DELETE_MODAL,
  EMPLOYEE_TIME_ENTRIES_SET_ERRORS,
  EMPLOYEE_TIME_ENTRIES_SET_TIME,
  EMPLOYEE_TIME_ENTRIES_UPDATE_ITEM,
  EMPLOYEE_TIME_ENTRIES_DELETE_ITEM,
} from '../actions/employee_time_entries'

const initialState = {
  items: [],
  isLoading: true,
  isFirstLoad: true,
  isFormLoading: false,
  loadingFailed: false,
  editId: null,
  errors: [],
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  timeStart: moment().startOf('day').format(),
  timeEnd: moment().endOf('day').format(),
}

const EmployeeTimeEntriesReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ----------------------- EMPLOYEE_TIME_ENTRIES_FETCH_REQUEST ----------------------- */
    case EMPLOYEE_TIME_ENTRIES_FETCH_REQUEST:
      return { ...state, isLoading: true }

    /* ----------------------- EMPLOYEE_TIME_ENTRIES_FETCH_SUCCESS ----------------------- */
    case EMPLOYEE_TIME_ENTRIES_FETCH_SUCCESS:
      return {
        ...state,
        items: [
          ...state.items.filter(
            ({ id }) => !state.items.map((item) => item.id).includes(id)
          ),
          ...action.items,
        ],
        isLoading: false,
        isFirstLoad: false,
        loadingFailed: false,
      }

    /* ----------------------- EMPLOYEE_TIME_ENTRIES_FETCH_FAILURE ----------------------- */
    case EMPLOYEE_TIME_ENTRIES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFirstLoad: false,
        loadingFailed: true,
      }

    /* ----------------------- EMPLOYEE_TIME_ENTRIES_UPDATE_REQUEST ---------------------- */
    /* ----------------------- EMPLOYEE_TIME_ENTRIES_DELETE_REQUEST ---------------------- */
    case EMPLOYEE_TIME_ENTRIES_UPDATE_REQUEST:
    case EMPLOYEE_TIME_ENTRIES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    /* ----------------------- EMPLOYEE_TIME_ENTRIES_UPDATE_SUCCESS ---------------------- */
    /* ----------------------- EMPLOYEE_TIME_ENTRIES_UPDATE_FAILURE ---------------------- */
    /* ----------------------- EMPLOYEE_TIME_ENTRIES_DELETE_SUCCESS ---------------------- */
    /* ----------------------- EMPLOYEE_TIME_ENTRIES_DELETE_FAILURE ---------------------- */
    case EMPLOYEE_TIME_ENTRIES_UPDATE_SUCCESS:
    case EMPLOYEE_TIME_ENTRIES_UPDATE_FAILURE:
    case EMPLOYEE_TIME_ENTRIES_DELETE_SUCCESS:
    case EMPLOYEE_TIME_ENTRIES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    /* --------------------- EMPLOYEE_TIME_ENTRIES_TOGGLE_EDIT_MODAL --------------------- */
    case EMPLOYEE_TIME_ENTRIES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }

    /* -------------------- EMPLOYEE_TIME_ENTRIES_TOGGLE_DELETE_MODAL -------------------- */
    case EMPLOYEE_TIME_ENTRIES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    /* ------------------------- EMPLOYEE_TIME_ENTRIES_SET_ERRORS ------------------------ */
    case EMPLOYEE_TIME_ENTRIES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }

    /* ------------------------- EMPLOYEE_TIME_ENTRIES_SET_TIME ------------------------ */
    case EMPLOYEE_TIME_ENTRIES_SET_TIME:
      return {
        ...state,
        timeStart: action.timeStart,
        timeEnd: action.timeEnd,
      }

    /* -------------------- EMPLOYEE_TIME_ENTRIES_UPDATE_ITEM ------------------- */
    case EMPLOYEE_TIME_ENTRIES_UPDATE_ITEM:
      return {
        ...state,
        items: [
          ...state.items.map((item) =>
            item.id === action.item.id ? action.item : item
          ),
        ],
      }

    /* -------------------- EMPLOYEE_TIME_ENTRIES_DELETE_ITEM ------------------- */
    case EMPLOYEE_TIME_ENTRIES_DELETE_ITEM:
      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== action.item.id)],
      }

    /* --------------------------------- DEFAULT -------------------------------- */
    default:
      return state
  }
}

export default EmployeeTimeEntriesReducer
