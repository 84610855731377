import {
  PAPER_DEMANDS_SELECTION_TYPE_BASE,
  PAPER_DEMANDS_SELECTION_TYPE_EMPTY,
  PAPER_DEMANDS_SELECTION_TYPE_ALL,
} from 'config'

import {
  PAPER_DEMANDS_FETCH_REQUEST,
  PAPER_DEMANDS_FETCH_SUCCESS,
  PAPER_DEMANDS_FETCH_FAILURE,
  PAPER_DEMANDS_UPDATE_REQUEST,
  PAPER_DEMANDS_UPDATE_SUCCESS,
  PAPER_DEMANDS_UPDATE_FAILURE,
  PAPER_DEMANDS_MOVE_REQUEST,
  PAPER_DEMANDS_MOVE_SUCCESS,
  PAPER_DEMANDS_MOVE_FAILURE,
  PAPER_DEMANDS_TOGGLE_EDIT_MODAL,
  PAPER_DEMANDS_TOGGLE_FILL_MODAL,
  PAPER_DEMANDS_FILTER_SET_DATE,
  PAPER_DEMANDS_FILTER_SET_KEYWORD,
  PAPER_DEMANDS_FILTER_SELECT_PAPER_TYPE,
  PAPER_DEMANDS_FILTER_SELECT_BASE_PAPER_SIZE,
  PAPER_DEMANDS_FILTER_SELECT_TARGET_PAPER_SIZE,
  PAPER_DEMANDS_CLEAR_FILTER,
  PAPER_DEMANDS_ADD_ITEM_SELECTION,
  PAPER_DEMANDS_REMOVE_ITEM_SELECTION,
  PAPER_DEMANDS_CLEAR_ITEM_SELECTIONS,
  PAPER_DEMANDS_SORT,
  PAPER_DEMANDS_SET_ERRORS,
  PAPER_DEMANDS_SET_QUERY_SETTINGS,
  PAPER_DEMANDS_CLEAR,
  PAPER_DEMANDS_TOGGLE_ITEM_SELECTIONS,
  PAPER_DEMANDS_SET_SELECTION_TYPE,
  PAPER_DEMANDS_SET_SELECTIONS_HIGHLIGHT,
} from '../actions/paper_demands'

const initialState = {
  items: [],
  selectedItems: [],
  selectedQuantity: 0,
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isEditModalOpen: false,
  isFillModalOpen: false,
  filter: {
    date: '',
    keyword: '',
    paper_type: null,
    base_paper_size: null,
    target_paper_size: null,
  },
  sorting: {
    column: 'paper_types.id',
    direction: 'desc',
  },
  editId: null,
  errors: [],
  settingsFetched: false,
  selectionType: 0,
  selectionsHighlight: false,
}

const paperDemandsReducer = (state = initialState, action) => {
  let availableItems

  switch (action.type) {
    // PAPER_DEMANDS_FETCH_REQUEST
    // PAPER_DEMANDS_MOVE_REQUEST
    case PAPER_DEMANDS_FETCH_REQUEST:
    case PAPER_DEMANDS_MOVE_REQUEST:
      return { ...state, isLoading: true }
    // PAPER_DEMANDS_FETCH_SUCCESS
    case PAPER_DEMANDS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        loadingFailed: false,
      }
    // PAPER_DEMANDS_MOVE_SUCCESS
    case PAPER_DEMANDS_MOVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadingFailed: false,
      }
    // PAPER_DEMANDS_FETCH_FAILURE
    case PAPER_DEMANDS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // PAPER_DEMANDS_MOVE_FAILURE
    case PAPER_DEMANDS_MOVE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    // PAPER_DEMANDS_UPDATE_REQUEST
    case PAPER_DEMANDS_UPDATE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // PAPER_DEMANDS_UPDATE_SUCCESS
    // PAPER_DEMANDS_UPDATE_FAILURE
    case PAPER_DEMANDS_UPDATE_SUCCESS:
    case PAPER_DEMANDS_UPDATE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // PAPER_DEMANDS_TOGGLE_EDIT_MODAL
    case PAPER_DEMANDS_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // PAPER_DEMANDS_TOGGLE_FILL_MODAL
    case PAPER_DEMANDS_TOGGLE_FILL_MODAL:
      return {
        ...state,
        isFillModalOpen: action.state,
      }
    // PAPER_DEMANDS_FILTER_SET_DATE
    case PAPER_DEMANDS_FILTER_SET_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
        },
      }
    // PAPER_DEMANDS_FILTER_SET_KEYWORD
    case PAPER_DEMANDS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
      }
    // PAPER_DEMANDS_FILTER_SELECT_PAPER_TYPE
    case PAPER_DEMANDS_FILTER_SELECT_PAPER_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_type: action.paper_type,
        },
      }
    // PAPER_DEMANDS_FILTER_SELECT_BASE_PAPER_SIZE
    case PAPER_DEMANDS_FILTER_SELECT_BASE_PAPER_SIZE:
      return {
        ...state,
        filter: {
          ...state.filter,
          base_paper_size: action.base_paper_size,
        },
      }
    // PAPER_DEMANDS_FILTER_SELECT_TARGET_PAPER_SIZE
    case PAPER_DEMANDS_FILTER_SELECT_TARGET_PAPER_SIZE:
      return {
        ...state,
        filter: {
          ...state.filter,
          target_paper_size: action.target_paper_size,
        },
      }
    // PAPER_DEMANDS_CLEAR_FILTER
    case PAPER_DEMANDS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          date: initialState.filter.date,
          keyword: '',
          paper_type: initialState.filter.paper_type,
          base_paper_size: initialState.filter.base_paper_size,
          target_paper_size: initialState.filter.target_paper_size,
        },
      }

    // PAPER_DEMANDS_ADD_ITEM_SELECTION
    case PAPER_DEMANDS_ADD_ITEM_SELECTION:
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.id],
        selectedQuantity: state.selectedQuantity + action.quantity,
      }
    // PAPER_DEMANDS_REMOVE_ITEM_SELECTION
    case PAPER_DEMANDS_REMOVE_ITEM_SELECTION:
      return {
        ...state,
        selectedItems: state.selectedItems.filter((id) => id !== action.id),
        selectedQuantity: state.selectedQuantity - action.quantity,
      }
    // PAPER_DEMANDS_TOGGLE_ITEM_SELECTIONS
    case PAPER_DEMANDS_TOGGLE_ITEM_SELECTIONS:
      switch (state.selectionType) {
        case PAPER_DEMANDS_SELECTION_TYPE_ALL:
          availableItems = state.items
          break
        case PAPER_DEMANDS_SELECTION_TYPE_BASE:
          availableItems = state.items.filter(
            ({ base_paper_size, base_quantity }) =>
              base_paper_size && base_quantity
          )
          break
        case PAPER_DEMANDS_SELECTION_TYPE_EMPTY:
          availableItems = state.items.filter(
            ({ base_paper_size, base_quantity }) =>
              !base_paper_size || !base_quantity
          )
          break
        default:
          availableItems = []
      }

      if (state.selectedItems.length === availableItems.length) {
        return {
          ...state,
          selectedItems: initialState.selectedItems,
          selectedQuantity: initialState.selectedQuantity,
        }
      }

      return {
        ...state,
        selectedItems: availableItems.map(({ id }) => id),
        selectedQuantity: availableItems.reduce(
          (acc, { target_quantity, target_additional_quantity }) =>
            acc + target_quantity + target_additional_quantity,
          0
        ),
      }
    // PAPER_DEMANDS_CLEAR_ITEM_SELECTIONS
    case PAPER_DEMANDS_CLEAR_ITEM_SELECTIONS:
      return {
        ...state,
        selectedItems: initialState.selectedItems,
        selectedQuantity: initialState.selectedQuantity,
      }
    // PAPER_DEMANDS_SORT
    case PAPER_DEMANDS_SORT:
      return {
        ...state,
        sorting: action.sorting,
      }
    // PAPER_DEMANDS_SET_ERRORS
    case PAPER_DEMANDS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // PAPER_DEMANDS_SET_QUERY_SETTINGS
    case PAPER_DEMANDS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_type: parseInt(action.paper_type, 10) || null,
          base_paper_size: parseInt(action.base_paper_size, 10) || null,
          target_paper_size: parseInt(action.target_paper_size, 10) || null,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // PAPER_DEMANDS_CLEAR
    case PAPER_DEMANDS_CLEAR:
      return {
        ...state,
        items: [],
      }
    // PAPER_DEMANDS_SET_SELECTION_TYPE
    case PAPER_DEMANDS_SET_SELECTION_TYPE:
      return {
        ...state,
        selectionType: action.selectionType,
        selectedItems: initialState.selectedItems,
        selectedQuantity: initialState.selectedQuantity,
      }
    // PAPER_DEMANDS_SET_SELECTIONS_HIGHLIGHT
    case PAPER_DEMANDS_SET_SELECTIONS_HIGHLIGHT:
      return {
        ...state,
        selectionsHighlight: action.value,
      }
    default:
      return state
  }
}

export default paperDemandsReducer
