import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { GLOBAL_CLEAR_UNAUTHORIZED } from 'redux/actions/global'
import Unauthorized from './Unauthorized'

// Actions

const UnauthorizedContainer = () => {
  const message = useSelector((state) => state.global.unauthorized.message)
  const dispatch = useDispatch()

  const handleExit = () => {
    dispatch({ type: GLOBAL_CLEAR_UNAUTHORIZED })
  }

  return <Unauthorized message={message} onExit={handleExit} />
}

export default UnauthorizedContainer
