import React from 'react'

/* ---------------------------------- Icons --------------------------------- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* ------------------------------- Components ------------------------------- */
import { Link } from 'react-router-dom'

/* -------------------------------------------------------------------------- */
/*                               User Menu Item                               */
/* -------------------------------------------------------------------------- */
const UserMenuItem = ({
  tag = 'div',
  color = 'primary',
  children,
  active = false,
  icon = null,
  ...rest
}) => {
  const Component = tag === 'link' ? Link : tag

  return (
    <Component
      className={`${
        active ? `tw-bg-${color}-200 tw-text-${color}-800` : 'tw-text-slate-700'
      } tw-group tw-flex tw-gap-x-2 tw-w-full tw-items-center tw-rounded-md tw-px-4 tw-py-2 tw-text-sm tw-cursor-pointer`}
      {...rest}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{children}</span>
    </Component>
  )
}

export default UserMenuItem
