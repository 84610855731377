import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { defaultRequest } from 'utils/requests'
import { displayAlert, displayUserToast } from 'utils/helpers/toasts'

import { ORDERS_UPDATE_ITEM_EVENT } from './orders'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const EVENTS_FETCH_REQUEST = 'EVENTS_FETCH_REQUEST'
export const EVENTS_FETCH_SUCCESS = 'EVENTS_FETCH_SUCCESS'
export const EVENTS_FETCH_FAILURE = 'EVENTS_FETCH_FAILURE'
// MOVE
export const EVENTS_MOVE_REQUEST = 'EVENTS_MOVE_REQUEST'
export const EVENTS_MOVE_SUCCESS = 'EVENTS_MOVE_SUCCESS'
export const EVENTS_MOVE_FAILURE = 'EVENTS_MOVE_FAILURE'
// FILTERS
export const EVENTS_FILTER_SET_KEYWORD = 'EVENTS_FILTER_SET_KEYWORD'
export const EVENTS_FILTER_SET_STATUS = 'EVENTS_FILTER_SET_STATUS'
export const EVENTS_CLEAR_FILTER = 'EVENTS_CLEAR_FILTER'
// OTHER
export const EVENTS_STOP_UPDATING = 'EVENTS_STOP_UPDATING'
export const EVENTS_SORT = 'EVENTS_SORT'
export const EVENTS_SET_QUERY_SETTINGS = 'EVENTS_SET_QUERY_SETTINGS'
export const EVENTS_CLEAR = 'EVENTS_CLEAR'
export const EVENTS_ADD_ITEM = 'EVENTS_ADD_ITEM'
export const EVENTS_UPDATE_ITEM = 'EVENTS_UPDATE_ITEM'
export const EVENTS_REMOVE_ITEM = 'EVENTS_REMOVE_ITEM'
export const EVENTS_MOVE_ITEM = 'EVENTS_MOVE_ITEM'
export const EVENTS_APPEND_ITEMS = 'EVENTS_APPEND_ITEMS'
export const EVENTS_SET_CURRENT_MACHINE = 'EVENTS_SET_CURRENT_MACHINE'
export const EVENTS_TOGGLE_ITEM_SELECTION = 'EVENTS_TOGGLE_ITEM_SELECTION'
export const EVENTS_TOGGLE_FILTER_RESULTS_SELECTION =
  'EVENTS_TOGGLE_FILTER_RESULTS_SELECTION'
export const EVENTS_CLEAR_ITEM_SELECTION = 'EVENTS_CLEAR_ITEM_SELECTION'
export const EVENTS_SET_MULTI_SELECTION = 'EVENTS_SET_MULTI_SELECTION'
export const EVENTS_POST_COMMENT = 'EVENTS_POST_COMMENT'
export const EVENTS_DESTROY_COMMENT = 'EVENTS_DESTROY_COMMENT'
export const EVENTS_HIGHLIGHT_ITEMS = 'EVENTS_HIGHLIGHT_ITEMS'
export const EVENTS_CLEAR_HIGHLIGHTS = 'EVENTS_CLEAR_HIGHLIGHTS'
export const EVENTS_UPDATE_ORDER_STATUS = 'EVENTS_UPDATE_ORDER_STATUS'

/**
 * FETCH EVENTS
 * * Get events list
 */
export const fetchEvents =
  ({ machine_id }) =>
  async (dispatch, getState) => {
    // Get current state
    const {
      events: { filter, sorting },
    } = getState()

    defaultRequest({
      request: () =>
        api.get(`${config.api.machines.root}/${machine_id}/events`, {
          params: {
            search: filter.keyword,
            status: filter.status,
            sort: sorting.column,
            direction: sorting.direction,
          },
        }),
      beforeRequest: () => dispatch({ type: EVENTS_FETCH_REQUEST }),
      onSuccess: (response) => {
        // Dispatch success action
        dispatch({ type: EVENTS_FETCH_SUCCESS, items: response.data })
      },
      onError: () => dispatch({ type: EVENTS_FETCH_FAILURE }),
      onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
      onUnknownError: () =>
        displayAlert(i18n.t('events:alerts.cannot_fetch'), 'error'),
    })
  }

/**
 * EXPORT EVENTS
 * * Export events list
 */
export const exportEvents =
  ({
    machine_id,
    beforeRequest = () => {},
    afterRequest = () => {},
    onSuccess = () => {},
    onError = () => {},
  }) =>
  async (dispatch) => {
    // Get current state
    defaultRequest({
      request: () =>
        api.get(`${config.api.machines.root}/${machine_id}/events/export`, {
          responseType: 'blob',
        }),
      beforeRequest,
      afterRequest,
      onSuccess,
      onError,
      onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
      onUnknownError: () =>
        displayAlert(i18n.t('events:alerts.cannot_fetch'), 'error'),
    })
  }

/**
 * UPDATE EVENT STATUS
 * * Update event status
 */
export const updateStatus =
  ({
    id,
    event,
    beforeRequest = () => {},
    afterRequest = () => {},
    onError = () => {},
    onSuccess = () => {},
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.events.root}/${id}/status`, { event }),
      beforeRequest,
      onSuccess: ({ data }) => {
        onSuccess(data)
        // Display successfull message
        displayAlert(
          i18n.t('events:alerts.successfully_updated_status'),
          'success'
        )
        // Update order item
        dispatch({ type: ORDERS_UPDATE_ITEM_EVENT, data })
        // Update events item
        dispatch({ type: EVENTS_UPDATE_ITEM, data })
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: () =>
        displayAlert(i18n.t('events:alerts.cannot_update_status'), 'error'),
      onUnknownError: () =>
        displayAlert(i18n.t('events:alerts.cannot_update_status'), 'error'),
      afterRequest,
      onError,
    })

/**
 * UPDATE EVENT MACHINE
 * * Update event machine
 */
export const updateMachine =
  ({
    id,
    machine_id,
    beforeRequest = () => {},
    afterRequest = () => {},
    onError = () => {},
    onSuccess = () => {},
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.events.root}/${id}/machine`, { machine_id }),
      beforeRequest,
      onSuccess: ({ data }) => {
        onSuccess(data)
        // Display successfull message
        displayAlert(
          i18n.t('events:alerts.successfully_updated_machine'),
          'success'
        )
        // Update order item
        dispatch({ type: ORDERS_UPDATE_ITEM_EVENT, data })
        // Remove events item
        dispatch({ type: EVENTS_REMOVE_ITEM, id: data.id })
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: () =>
        displayAlert(i18n.t('events:alerts.cannot_update_machine'), 'error'),
      onUnknownError: () =>
        displayAlert(i18n.t('events:alerts.cannot_update_machine'), 'error'),
      afterRequest,
      onError,
    })

/**
 * UPDATE ITEM AFTER MACHINE UPDATE
 * * Update item after machine update
 */
export const updateAfterUpdateMachine = (data) => (dispatch, getState) => {
  const {
    events: { items, currentMachine },
    session: { currentUser },
  } = getState()
  const { event, sender, source_machine_name } = data

  if (sender.id !== currentUser.id) {
    const itemExists = items.map((item) => item.id).includes(event.id)

    if (event.machine?.id === currentMachine.id) {
      dispatch({
        type: itemExists ? EVENTS_UPDATE_ITEM : EVENTS_ADD_ITEM,
        data: event,
        updating: true,
      })
    } else if (itemExists) {
      dispatch({ type: EVENTS_REMOVE_ITEM, id: event.id })
    }

    const target_machine_name = event.machine?.name
    const toastVariables = {
      order_number: event.order_number,
      source_machine_name,
      target_machine_name,
    }

    displayUserToast(
      i18n.t(`events:alerts.asynchronously_updated_machine`, {
        event_name: event.event_type.name,
      }),
      sender,
      { order_number: event.order_number, event_machine: toastVariables }
    )
  }
}

/**
 * UPDATE ITEM AFTER STATUS UPDATE
 * * Update item after status update
 */
export const updateAfterUpdateStatus = (data) => (dispatch, getState) => {
  const {
    events: { currentMachine },
    session: { currentUser },
  } = getState()
  const { event, sender, prev_status } = data

  const { activeStatuses } = config.options.events

  if (event.machine.id === currentMachine.id) {
    if (
      !activeStatuses.includes(event.status) &&
      activeStatuses.includes(prev_status)
    ) {
      dispatch({ type: EVENTS_REMOVE_ITEM, id: event.id })
    } else if (
      activeStatuses.includes(event.status) &&
      !activeStatuses.includes(prev_status)
    ) {
      dispatch({ type: EVENTS_ADD_ITEM, data: event, updating: true })
    }

    if (sender.id !== currentUser.id) {
      dispatch({ type: EVENTS_UPDATE_ITEM, data: event, updating: true })

      const options = {
        order_number: event.order_number,
        event_status: {
          prev: prev_status,
          current: event.status,
        },
      }
      displayUserToast(
        i18n.t('events:alerts.asynchronously_updated_status', {
          event_name: event.event_type.name,
        }),
        sender,
        options
      )
    }
  }
}

/**
 * UPDATE LIST AFTER CREATE
 * * Update list after create
 */
export const updateAfterCreate = (data) => (dispatch, getState) => {
  const {
    session: { currentUser },
    events: { currentMachine },
  } = getState()
  const { sender } = data

  const { event } = data
  if (sender.id !== currentUser.id && event.machine?.id === currentMachine.id) {
    dispatch({ type: EVENTS_ADD_ITEM, data: event, updating: true })

    const values = { event_name: event.event_type.name }
    displayUserToast(
      i18n.t('events:alerts.asynchronously_created', values),
      sender,
      { order_number: event.order_number }
    )
  }
}

/**
 * UPDATE LIST AFTER DELETE
 * * Update list after delete
 */
export const updateAfterDelete = (data) => (dispatch, getState) => {
  const {
    session: { currentUser },
  } = getState()
  const { event, sender } = data

  const { id } = event
  if (sender.id !== currentUser.id) {
    // Remove events item
    dispatch({ type: EVENTS_REMOVE_ITEM, id })
    const values = { event_name: event.event_type.name }
    displayUserToast(
      i18n.t('events:alerts.asynchronously_removed', values),
      sender,
      { order_number: event.order_number }
    )
  }
}

/**
 * MOVE EVENT
 * * Move event
 */
export const moveEvent =
  ({ id, direction }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.events.root}/${id}/move`, { direction }),
      beforeRequest: () => dispatch({ type: EVENTS_MOVE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('events:alerts.successfully_moved'), 'success')
        dispatch({ type: EVENTS_MOVE_SUCCESS })
        dispatch({ type: EVENTS_MOVE_ITEM, id, direction })
      },
      onError: () => {
        displayAlert(i18n.t('events:alerts.cannot_move'), 'error')
        dispatch({ type: EVENTS_MOVE_FAILURE })
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    })

/**
 * APPEND EVENTS
 * * Append events
 */
export const appendEvents =
  ({ id }) =>
  async (dispatch, getState) => {
    const {
      events: { selectedItems },
    } = getState()

    return defaultRequest({
      request: () =>
        api.put(`${config.api.events.root}/${id}/append`, {
          event_ids: selectedItems,
        }),
      beforeRequest: () => dispatch({ type: EVENTS_MOVE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('events:alerts.successfully_moved'), 'success')
        dispatch({ type: EVENTS_MOVE_SUCCESS })
        dispatch({ type: EVENTS_APPEND_ITEMS, id, items: selectedItems })
        dispatch({ type: EVENTS_CLEAR_ITEM_SELECTION })
      },
      onError: () => {
        displayAlert(i18n.t('events:alerts.cannot_move'), 'error')
        dispatch({ type: EVENTS_MOVE_FAILURE })
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    })
  }

/**
 * UPDATE ITEM AFTER MOVE
 * * Update item after move
 */
export const updateAfterMove = (data) => (dispatch, getState) => {
  const {
    session: { currentUser },
    events: { items },
  } = getState()
  const { sender } = data

  const { id, direction } = data
  if (
    sender.id !== currentUser.id &&
    items.map((item) => item.id).includes(id)
  ) {
    dispatch({ type: EVENTS_MOVE_ITEM, id, direction, updating: true })
    displayUserToast(
      i18n.t('events:alerts.asynchronously_moved', {
        value: i18n.t('events:dictionary.events', { count: 2 }),
      }),
      sender
    )
  }
}

/**
 * UPDATE ITEM AFTER APPEND
 * * Update item after append
 */
export const updateAfterAppend = (data) => (dispatch, getState) => {
  const {
    session: { currentUser },
    events: { items },
  } = getState()
  const { sender } = data

  const { id, event_ids } = data
  if (
    sender.id !== currentUser.id &&
    items.map((item) => item.id).includes(id)
  ) {
    dispatch({
      type: EVENTS_APPEND_ITEMS,
      id,
      items: event_ids,
      updating: true,
    })

    const count = event_ids.length
    const plural = i18n.t('events:dictionary.events', { count })
    displayUserToast(
      i18n.t('events:alerts.asynchronously_moved', { value: plural }),
      sender
    )
  }
}

/**
 * UPDATE ITEM AFTER POST COMMENT
 * * Update item after post comment
 */
export const updateAfterPostComment = (data) => (dispatch, getState) => {
  const {
    events: { items },
    session: { currentUser },
  } = getState()
  const { comment, sender } = data

  if (items.map(({ id }) => id).includes(comment.commentable.id)) {
    dispatch({ type: EVENTS_POST_COMMENT, data: comment })

    if (sender.id !== currentUser.id) {
      displayUserToast(
        i18n.t('events:alerts.asynchronously_posted_comment', {
          event_name: comment.commentable.event_type.name,
          order_number: comment.commentable.order_number,
        }),
        sender
      )
    }
  }
}

/**
 * UPDATE ITEM AFTER DESTROY COMMENT
 * * Update item after destroy comment
 */
export const updateAfterDestroyComment = (data) => (dispatch, getState) => {
  const {
    events: { items },
    session: { currentUser },
  } = getState()
  const { comment, sender } = data

  if (items.map(({ id }) => id).includes(comment.commentable.id)) {
    dispatch({ type: EVENTS_DESTROY_COMMENT, data: comment })

    if (sender.id !== currentUser.id) {
      displayUserToast(
        i18n.t('events:alerts.asynchronously_destroyed_comment', {
          event_name: comment.commentable.event_type.name,
          order_number: comment.commentable.order_number,
        }),
        sender
      )
    }
  }
}

/**
 * UPDATE AFTER UPDATE COMPLETED QUANTITY
 * * update After Update Completed Quantity
 */
export const updateAfterUpdateCompletedQuantity =
  (data) => (dispatch, getState) => {
    const {
      events: { currentMachine },
    } = getState()
    const { event, sender, relative_quantity } = data

    if (event.machine.id === currentMachine.id) {
      dispatch({ type: EVENTS_UPDATE_ITEM, data: event, updating: true })

      const options = {
        order_number: event.order_number,
        relative_quantity,
        completed_quantity: event.completed_quantity,
        machine_quantity: event.machine_quantity,
      }
      displayUserToast(
        i18n.t('events:alerts.asynchronously_updated_completed_quantity'),
        sender,
        options
      )
    }
  }

/**
 * POST COMMENT
 * * Post comment
 */
export const postComment =
  ({ id, content, beforeRequest, afterRequest }) =>
  () => {
    return defaultRequest({
      request: () =>
        api.post(`${config.api.events.root}/${id}/comments`, { content }),
      beforeRequest,
      onBadRequest: () => {
        displayAlert(i18n.t('events:alerts.cannot_post_comment'), 'error')
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      afterRequest,
    })
  }

/**
 * DELETE COMMENT
 * * Delete comment
 */
export const deleteComment =
  ({ id, comment_id, beforeRequest, onSuccess }) =>
  () => {
    return defaultRequest({
      request: () =>
        api.delete(`${config.api.events.root}/${id}/comments/${comment_id}`),
      beforeRequest,
      onSuccess: ({ data }) => onSuccess(data),
      onBadRequest: () => {
        displayAlert(i18n.t('events:alerts.cannot_delete_comment'), 'error')
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    })
  }

/**
 * UPDATE ITEM AFTER UPDATE ORDER STATUS
 * * Update item after update order status
 */
export const updateAfterUpdateOrderStatus = (data) => (dispatch, getState) => {
  const {
    events: { items },
    session: { currentUser },
  } = getState()
  const { order, prev_status, sender } = data

  if (items.map(({ order: { id } }) => id).includes(order.id)) {
    dispatch({ type: EVENTS_UPDATE_ORDER_STATUS, data: order })

    if (sender.id !== currentUser.id) {
      const options = {
        order_number: order.order_number,
        order_status: {
          prev: prev_status,
          current: order.status,
        },
      }

      displayUserToast(
        i18n.t('orders:alerts.asynchronously_updated_status', {
          order_number: order.order_number,
        }),
        sender,
        options
      )
    }
  }
}
