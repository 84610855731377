import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import { getSetting } from 'utils/helpers/settings'
import { displayNotificationToast } from 'utils/helpers/toasts'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import { GLOBAL_SET_MAINTENANCE_MODE } from 'redux/actions/global'

/* ------------------------------- Components ------------------------------- */
import { ToastContainer } from 'react-toastify'
import Channel from 'components/providers/Channel'
import Aside from './Aside'
import Header from './Header'

/* -------------------------------------------------------------------------- */
/*                            User Layout Component                           */
/* -------------------------------------------------------------------------- */
const UserLayout = ({ children }) => {
  const dispatch = useDispatch()

  const handleMessageReceive = (message) => {
    switch (message.type) {
      case 'toast':
        displayNotificationToast(message.data.text, message.data, {
          autoClose: false,
        })
        break
      case 'maintenance_mode':
        dispatch({
          type: GLOBAL_SET_MAINTENANCE_MODE,
          state: message.data.state === 'true',
        })
        break
      default:
    }
  }

  return (
    <div className="gd-wrapper">
      <Aside />
      <Channel channel="SystemChannel" onReceive={handleMessageReceive}>
        <div className="gd-wrapper__content">
          <Header />
          {children}
          <ToastContainer
            position="bottom-right"
            closeOnClick={false}
            hideProgressBar
            newestOnTop
            autoClose={getSetting('notifications.toasts.time')}
            limit={getSetting('notifications.toasts.limit')}
            containerId="toasts"
          />
          <ToastContainer
            position="top-right"
            closeOnClick={true}
            hideProgressBar
            containerId="alerts"
            autoClose={getSetting('notifications.alerts.time')}
            stacked
            className="tw-top-28"
          />
        </div>
      </Channel>
      <div id="tooltip-portal"></div>
    </div>
  )
}

export default UserLayout
