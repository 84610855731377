import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const PAPER_TYPES_FETCH_REQUEST = 'PAPER_TYPES_FETCH_REQUEST'
export const PAPER_TYPES_FETCH_SUCCESS = 'PAPER_TYPES_FETCH_SUCCESS'
export const PAPER_TYPES_FETCH_FAILURE = 'PAPER_TYPES_FETCH_FAILURE'
// CREATE
export const PAPER_TYPES_CREATE_REQUEST = 'PAPER_TYPES_CREATE_REQUEST'
export const PAPER_TYPES_CREATE_SUCCESS = 'PAPER_TYPES_CREATE_SUCCESS'
export const PAPER_TYPES_CREATE_FAILURE = 'PAPER_TYPES_CREATE_FAILURE'
// UPDATE
export const PAPER_TYPES_UPDATE_REQUEST = 'PAPER_TYPES_UPDATE_REQUEST'
export const PAPER_TYPES_UPDATE_SUCCESS = 'PAPER_TYPES_UPDATE_SUCCESS'
export const PAPER_TYPES_UPDATE_FAILURE = 'PAPER_TYPES_UPDATE_FAILURE'
// DELETE
export const PAPER_TYPES_DELETE_REQUEST = 'PAPER_TYPES_DELETE_REQUEST'
export const PAPER_TYPES_DELETE_SUCCESS = 'PAPER_TYPES_DELETE_SUCCESS'
export const PAPER_TYPES_DELETE_FAILURE = 'PAPER_TYPES_DELETE_FAILURE'
// REPLACE
export const PAPER_TYPES_REPLACE_REQUEST = 'PAPER_TYPES_REPLACE_REQUEST'
export const PAPER_TYPES_REPLACE_SUCCESS = 'PAPER_TYPES_REPLACE_SUCCESS'
export const PAPER_TYPES_REPLACE_FAILURE = 'PAPER_TYPES_REPLACE_FAILURE'
// MODALS
export const PAPER_TYPES_TOGGLE_ADD_MODAL = 'PAPER_TYPES_TOGGLE_ADD_MODAL'
export const PAPER_TYPES_TOGGLE_EDIT_MODAL = 'PAPER_TYPES_TOGGLE_EDIT_MODAL'
export const PAPER_TYPES_TOGGLE_DELETE_MODAL = 'PAPER_TYPES_TOGGLE_DELETE_MODAL'
export const PAPER_TYPES_TOGGLE_REPLACE_MODAL =
  'PAPER_TYPES_TOGGLE_REPLACE_MODAL'
// FILTERS
export const PAPER_TYPES_FILTER_SET_KEYWORD = 'PAPER_TYPES_FILTER_SET_KEYWORD'
export const PAPER_TYPES_FILTER_SET_WEIGHT = 'PAPER_TYPES_FILTER_SET_WEIGHT'
export const PAPER_TYPES_CLEAR_FILTER = 'PAPER_TYPES_CLEAR_FILTER'
// SELECTION
export const PAPER_TYPES_ADD_ITEM_SELECTION = 'PAPER_TYPES_ADD_ITEM_SELECTION'
export const PAPER_TYPES_REMOVE_ITEM_SELECTION =
  'PAPER_TYPES_REMOVE_ITEM_SELECTION'
export const PAPER_TYPES_TOGGLE_ITEM_SELECTIONS =
  'PAPER_TYPES_TOGGLE_ITEM_SELECTIONS'
export const PAPER_TYPES_CLEAR_ITEM_SELECTIONS =
  'PAPER_TYPES_CLEAR_ITEM_SELECTIONS'
// Other
export const PAPER_TYPES_SORT = 'PAPER_TYPES_SORT'
export const PAPER_TYPES_SET_ERRORS = 'PAPER_TYPES_SET_ERRORS'
export const PAPER_TYPES_SET_CURRENT_PAGE = 'PAPER_TYPES_SET_CURRENT_PAGE'
export const PAPER_TYPES_SET_PAGINATION_PER_PAGE =
  'PAPER_TYPES_SET_PAGINATION_PER_PAGE'
export const PAPER_TYPES_SET_QUERY_SETTINGS = 'PAPER_TYPES_SET_QUERY_SETTINGS'
export const PAPER_TYPES_CLEAR = 'PAPER_TYPES_CLEAR'

/**
 * FETCH PAPER TYPES
 * * Get paper types list with pagination
 */
export const fetchPaperTypes = () => async (dispatch, getState) => {
  // Get current state
  const {
    paper_types: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.paper_types.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          weight_min: filter.weight.min,
          weight_max: filter.weight.max,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: PAPER_TYPES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: PAPER_TYPES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: PAPER_TYPES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_types:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE PAPER TYPE
 * * Create paper type and display validation errors
 */
export const createPaperType =
  ({ name, paper_type_group_id, weight }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.paper_types.root, {
          name,
          paper_type_group_id,
          weight,
        }),
      beforeRequest: () => dispatch({ type: PAPER_TYPES_CREATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_types:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: PAPER_TYPES_CREATE_SUCCESS })
        // Reload paper types list
        dispatch(fetchPaperTypes())
        // Close modal and reset errors
        dispatch({ type: PAPER_TYPES_TOGGLE_ADD_MODAL, state: false })
        dispatch({ type: PAPER_TYPES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PAPER_TYPES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_types:alerts.cannot_create'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_TYPES_TOGGLE_ADD_MODAL, state: false }),
    })

/**
 * UPDATE PAPER TYPE
 * * Update paper type and reload paper types list
 */
export const updatePaperType = ({ id, name, paper_type_group_id, weight }) => {
  return async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.paper_types.root}/${id}`, {
          name,
          paper_type_group_id,
          weight,
        }),
      beforeRequest: () => dispatch({ type: PAPER_TYPES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_types:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: PAPER_TYPES_UPDATE_SUCCESS })
        // Reload paper types list
        dispatch(fetchPaperTypes())
        // Close modal and reset errors
        dispatch({ type: PAPER_TYPES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: PAPER_TYPES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PAPER_TYPES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_types:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_TYPES_TOGGLE_EDIT_MODAL, state: false }),
    })
}

/**
 * DELETE PAPER TYPE
 * * Delete paper type and fetch paper types list
 */
export const deletePaperType = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.paper_types.root}/${id}`),
    beforeRequest: () => dispatch({ type: PAPER_TYPES_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('paper_types:alerts.successfully_deleted'), 'success')
      // Disable loading
      dispatch({ type: PAPER_TYPES_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: PAPER_TYPES_TOGGLE_DELETE_MODAL, state: false })
      // Reload paper types list
      dispatch(fetchPaperTypes())
    },
    onError: () => dispatch({ type: PAPER_TYPES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_types:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: PAPER_TYPES_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * REPLACE PAPER TYPES
 * * Replace selected paper types
 */
export const replacePaperTypes =
  ({ target_paper_type_id, paper_type_ids }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(config.api.paper_types.replace, {
          target_paper_type_id,
          paper_type_ids,
        }),
      beforeRequest: () => dispatch({ type: PAPER_TYPES_REPLACE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_types:alerts.successfully_replaced'),
          'success'
        )

        // Disable loading
        dispatch({ type: PAPER_TYPES_REPLACE_SUCCESS })
        // Reload paper types list
        dispatch(fetchPaperTypes())
        // Close modal and reset errors
        dispatch({ type: PAPER_TYPES_TOGGLE_REPLACE_MODAL, state: false })
        dispatch({ type: PAPER_TYPES_SET_ERRORS, errors: [] })
        // Clear selections
        dispatch({ type: PAPER_TYPES_CLEAR_ITEM_SELECTIONS })
      },
      onError: () => dispatch({ type: PAPER_TYPES_REPLACE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_TYPES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_types:alerts.cannot_replace'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_TYPES_TOGGLE_REPLACE_MODAL, state: false }),
    })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: PAPER_TYPES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchPaperTypes())
}
