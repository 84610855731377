import React from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useSelector } from 'react-redux'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

/* ---------------------------------- Icons --------------------------------- */
import {
  faReceipt,
  faScroll,
  faInboxOut,
  faCube,
} from '@fortawesome/pro-solid-svg-icons'

/* ------------------------------- Components ------------------------------- */
import UsersOnline from 'components/elements/interactive/UsersOnline'
import Nav from 'components/atomic/molecules/nav'
import Navbar from 'components/atomic/molecules/navbar'
import EventsMachines from './EventsMachines'
import UserMenu from './user-menu'

/* -------------------------------------------------------------------------- */
/*                                   Header                                   */
/* -------------------------------------------------------------------------- */
const Header = () => {
  const [
    canReadOrders,
    canSearchMachines,
    canReadEvents,
    canReadPaperDemands,
    canReadMaterials,
    canReadDeliveryNotes,
    canShowOnlineUsers,
  ] = useAuth([
    'orders.read',
    'machines.search',
    'events.read',
    'paper_demands.read',
    'materials.read',
    'delivery_notes.read',
    'users.show_online',
  ])

  const session = useSelector((state) => state.session)
  const { isChecked, isSignedIn, currentUser } = session
  const [t] = useTranslation()

  if (!isChecked || !isSignedIn) {
    return null
  }

  return (
    <Navbar className="tw-z-[999] tw-relative">
      <Nav>
        {canReadOrders && (
          <Nav.Item to="/orders" icon={faReceipt}>
            {t('orders:title')}
          </Nav.Item>
        )}
        {canSearchMachines && canReadEvents && <EventsMachines />}
        {canReadPaperDemands && (
          <Nav.Item to="/paper_demands" icon={faScroll}>
            {t('paper_demands:short_title')}
          </Nav.Item>
        )}
        {canReadMaterials && (
          <Nav.Item to="/materials/paper" icon={faCube}>
            {t('materials:paper.title')}
          </Nav.Item>
        )}
        {canReadDeliveryNotes && (
          <Nav.Item to="/delivery_notes" icon={faInboxOut}>
            {t('delivery_notes:title')}
          </Nav.Item>
        )}
      </Nav>
      <Nav>
        {/* <NavItem>
            <NavLink to="/notifications">
              <FontAwesomeIcon icon={faBell} />
            </NavLink>
          </NavItem> */}
        <UserMenu {...currentUser} />
        {canShowOnlineUsers && <UsersOnline />}
      </Nav>
    </Navbar>
  )
}

export default Header
