import { Menu } from '@headlessui/react'
import React from 'react'

// Hooks
import { useTranslation } from 'react-i18next'

// Components
import { Link } from 'react-router-dom'

const MachineGroup = ({ template, items }) => {
  const [t] = useTranslation()

  return (
    <Menu.Item className="tw-col-span-4 tw-p-4" as="div">
      {({ close }) => (
        <>
          <div className="tw-font-bold tw-text-gray-700 tw-mb-2 tw-capitalize">
            {t(`process_types:values.template.${template}`)}
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            {items.map(({ id, name }) => (
              <Link
                key={id}
                to={`/machines/${id}/events`}
                className="tw-px-3 tw-py-1 tw-bg-slate-100 tw-rounded-lg tw-text-slate-700 hover:tw-bg-primary-300/60 hover:tw-text-primary-800 tw-text-sm"
                onClick={close}
              >
                {name}
              </Link>
            ))}
          </div>
        </>
      )}
    </Menu.Item>
  )
}

export default MachineGroup
