import React, { useEffect } from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import { getSystemInfo } from 'redux/actions/global'

/* ---------------------------------- Icons --------------------------------- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons'

/* -------------------------------------------------------------------------- */
/*                         Maintenance Mode Component                         */
/* -------------------------------------------------------------------------- */
const MaintenanceMode = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const isMaintenanceMode = useSelector(
    (state) => state.global.isMaintenanceMode
  )

  useEffect(() => {
    dispatch(getSystemInfo())
  }, [dispatch])

  if (!isMaintenanceMode) {
    return null
  }

  /* --------------------------------- Render --------------------------------- */
  return (
    <div className="gd-maintenance">
      <div className="gd-maintenance__container">
        <div className="gd-maintenance__title">{t('maintenance.title')}</div>
        <div className="gd-maintenance__subtitle">
          {t('maintenance.subtitle')}
        </div>
        <div className="mt-4">
          <FontAwesomeIcon icon={faCircleNotch} size="4x" spin />
        </div>
      </div>
    </div>
  )
}

export default MaintenanceMode
