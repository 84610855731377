import React from 'react'
import { userDecorator } from 'utils/decorators'

import UserAvatar from 'components/elements/basic/UserAvatar'
import EventStatusToast from './EventStatusToast'
import OrderStatusToast from './OrderStatusToast'
import EventMachineToast from './EventMachineToast'
import OrderDueDateToast from './OrderDueDateToast'
import RelativeQuantityToast from './RelativeQuantityToast'

const UserToast = ({
  text,
  user,
  order_number = null,
  event_status = null,
  order_status = null,
  event_machine = null,
  due_date = null,
  relative_quantity = null,
  completed_quantity = null,
  machine_quantity = null,
}) => (
  <div className="gd-toast gd-toast--user">
    <header className="gd-toast__header">
      <UserAvatar {...user} />
      {userDecorator(user)}
      {order_number && (
        <div className="gd-toast__order-number">{order_number}</div>
      )}
    </header>
    <div className="gd-toast__body">
      {text}
      {event_status && <EventStatusToast {...event_status} />}
      {order_status && <OrderStatusToast {...order_status} />}
      {due_date && <OrderDueDateToast {...due_date} />}
      {event_machine && <EventMachineToast {...event_machine} />}
      {relative_quantity && (
        <RelativeQuantityToast
          {...{ relative_quantity, completed_quantity, machine_quantity }}
        />
      )}
    </div>
  </div>
)

export default UserToast
