import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const ADDRESSES_FETCH_REQUEST = 'ADDRESSES_FETCH_REQUEST'
export const ADDRESSES_FETCH_SUCCESS = 'ADDRESSES_FETCH_SUCCESS'
export const ADDRESSES_FETCH_FAILURE = 'ADDRESSES_FETCH_FAILURE'
// CREATE
export const ADDRESSES_CREATE_REQUEST = 'ADDRESSES_CREATE_REQUEST'
export const ADDRESSES_CREATE_SUCCESS = 'ADDRESSES_CREATE_SUCCESS'
export const ADDRESSES_CREATE_FAILURE = 'ADDRESSES_CREATE_FAILURE'
// UPDATE
export const ADDRESSES_UPDATE_REQUEST = 'ADDRESSES_UPDATE_REQUEST'
export const ADDRESSES_UPDATE_SUCCESS = 'ADDRESSES_UPDATE_SUCCESS'
export const ADDRESSES_UPDATE_FAILURE = 'ADDRESSES_UPDATE_FAILURE'
// DELETE
export const ADDRESSES_DELETE_REQUEST = 'ADDRESSES_DELETE_REQUEST'
export const ADDRESSES_DELETE_SUCCESS = 'ADDRESSES_DELETE_SUCCESS'
export const ADDRESSES_DELETE_FAILURE = 'ADDRESSES_DELETE_FAILURE'
// RESOURCES
export const ADDRESSES_SET_AVAILABLE_CITIES = 'ADDRESSES_SET_AVAILABLE_CITIES'
export const ADDRESSES_SET_AVAILABLE_COUNTRIES =
  'ADDRESSES_SET_AVAILABLE_COUNTRIES'
// MODALS
export const ADDRESSES_TOGGLE_EDIT_MODAL = 'ADDRESSES_TOGGLE_EDIT_MODAL'
export const ADDRESSES_TOGGLE_DELETE_MODAL = 'ADDRESSES_TOGGLE_DELETE_MODAL'
// FILTERS
export const ADDRESSES_FILTER_SET_KEYWORD = 'ADDRESSES_FILTER_SET_KEYWORD'
export const ADDRESSES_CLEAR_FILTER = 'ADDRESSES_CLEAR_FILTER'
export const ADDRESSES_SELECT_CITY = 'ADDRESSES_SELECT_CITY'
export const ADDRESSES_SELECT_COUNTRY = 'ADDRESSES_SELECT_COUNTRY'
export const ADDRESSES_SET_WITH_CONTRACTORS = 'ADDRESSES_SET_WITH_CONTRACTORS'
// Other
export const ADDRESSES_SORT = 'ADDRESSES_SORT'
export const ADDRESSES_SET_ERRORS = 'ADDRESSES_SET_ERRORS'
export const ADDRESSES_SET_CURRENT_PAGE = 'ADDRESSES_SET_CURRENT_PAGE'
export const ADDRESSES_SET_PAGINATION_PER_PAGE =
  'ADDRESSES_SET_PAGINATION_PER_PAGE'
export const ADDRESSES_SET_QUERY_SETTINGS = 'ADDRESSES_SET_QUERY_SETTINGS'
export const ADDRESSES_CLEAR = 'ADDRESSES_CLEAR'
export const ADDRESSES_ADD_AVAILABLE_CITY = 'ADDRESSES_ADD_AVAILABLE_CITY'

/**
 * FETCH ADDRESSES
 * * Get addresses list with pagination
 */
export const fetchAddresses = () => async (dispatch, getState) => {
  // Get current state
  const {
    addresses: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.addresses.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          city_id: filter.city,
          country_id: filter.country,
          contractors_mode: filter.withContractors,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: ADDRESSES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: ADDRESSES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: ADDRESSES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('addresses:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE ADDRESS
 * * Create address and display validation errors
 */
export const createAddress =
  ({ data, onClose, onCreate = () => {} }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.post(config.api.addresses.root, data),
      beforeRequest: () => dispatch({ type: ADDRESSES_CREATE_REQUEST }),
      onSuccess: ({ data: responseData }) => {
        // Display successfull message
        displayAlert(i18n.t('addresses:alerts.successfully_created'), 'success')
        // Disable loading
        dispatch({ type: ADDRESSES_CREATE_SUCCESS })
        // Reload addresses list
        dispatch(fetchAddresses())
        // Call onCreate method
        onCreate(responseData)
        // Close modal and reset errors
        onClose()
        dispatch({ type: ADDRESSES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: ADDRESSES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (errors) =>
        dispatch({ type: ADDRESSES_SET_ERRORS, errors }),
      onUnknownError: () =>
        displayAlert(i18n.t('addresses:alerts.cannot_create'), 'error'),
    })

/**
 * UPDATE ADDRESS
 * * Update address and reload addresses list
 */
export const updateAddress =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.put(`${config.api.addresses.root}/${id}`, params),
      beforeRequest: () => dispatch({ type: ADDRESSES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('addresses:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: ADDRESSES_UPDATE_SUCCESS })
        // Reload addresses list
        dispatch(fetchAddresses())
        // Close modal and reset errors
        dispatch({ type: ADDRESSES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: ADDRESSES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: ADDRESSES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: ADDRESSES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('addresses:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        displayAlert(i18n.t('addresses:alerts.cannot_edit'), 'error'),
    })

/**
 * DELETE ADDRESS
 * * Delete address and fetch addresses list
 */
export const deleteAddress = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.addresses.root}/${id}`),
    beforeRequest: () => dispatch({ type: ADDRESSES_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('addresses:alerts.successfully_deleted'), 'success')
      // Disable loading
      dispatch({ type: ADDRESSES_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: ADDRESSES_TOGGLE_DELETE_MODAL, state: false })
      // Reload addresses list
      dispatch(fetchAddresses())
    },
    onError: () => dispatch({ type: ADDRESSES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('addresses:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      displayAlert(i18n.t('addresses:alerts.cannot_delete'), 'error'),
  })

/**
 * FETCH CITIES
 * * Fetch cities list
 */
export const fetchCities = () => async (dispatch) =>
  defaultRequest({
    request: () => api.get(`${config.api.cities.root}`),
    onSuccess: (response) =>
      dispatch({ type: ADDRESSES_SET_AVAILABLE_CITIES, items: response.data }),
    onError: () => displayAlert(i18n.t('cities:alerts.cannot_fetch'), 'error'),
  })

/**
 * FETCH COUNTRIES
 * * Fetch countries list
 */
export const fetchCountries = () => async (dispatch) =>
  defaultRequest({
    request: () => api.get(`${config.api.countries}`),
    onSuccess: (response) =>
      dispatch({
        type: ADDRESSES_SET_AVAILABLE_COUNTRIES,
        items: response.data,
      }),
    onError: () =>
      displayAlert(i18n.t('countries:alerts.cannot_fetch'), 'error'),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: ADDRESSES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchAddresses())
}
