import config from 'config'
import _ from 'lodash'

export const filterByKeys = (source, condition) =>
  Object.keys(source)
    .filter((key) => condition(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: source[key],
      }),
      {}
    )

export const mapToValue = (item, decorator) => ({
  value: _.isObjectLike(item) ? item.id : item,
  label: decorator(item),
})

export const mapItemsToValue = (items, decorator) =>
  items.map((item) => mapToValue(item, decorator))

export const sortEvents = (events) => {
  const printingEvents = events.filter(
    ({ event_type: { template } }) => template === 'printing'
  )
  const printingEventsIds = printingEvents.map(({ id }) => id)
  const otherEvents = events.filter(({ id }) => !printingEventsIds.includes(id))

  return [...printingEvents, ...otherEvents]
}

export const isMac = () => navigator.userAgent.indexOf('Mac OS') !== -1

export const removeSpecialChars = (text) => text.replace(/[^a-zA-Z\d ]/g, '')

export const toDash = (text) =>
  removeSpecialChars(text).replace(/\s+/g, '-').toLowerCase()

export const getColorByIndex = (index) =>
  config.charts.colors[index % config.charts.colors.length]

export const mapTableHeaders = (headers, translationFn, options) =>
  headers.map(({ label, sortingColumn, ...rest }) => ({
    title: translationFn(label),
    label,
    sortingColumn:
      typeof sortingColumn === 'function' && options
        ? sortingColumn(options)
        : sortingColumn,
    ...rest,
  }))

export const mapToOption = (value, label) => ({ value, label })

export const mapValuesToOptions = (values) =>
  values.map((value) => ({
    value,
    label: value,
  }))

export const mapObjectsToOptions = (objects, decorator, key = 'id') =>
  objects.map((object) => ({ value: object[key], label: decorator(object) }))

export const mapToOptionsWithTranslation = (keys, translationFn) =>
  keys.map((key) => mapToOption(key, translationFn(key)))

export const getReversedSortingDirection = (direction) =>
  direction === 'asc' ? 'desc' : 'asc'

export const prepareSettings = (settings, namespace) => [
  `${namespace}.pagination.per_page`,
  `${namespace}.sorting`,
  ...settings.map((setting) => `${namespace}.filters.${setting}`),
]
