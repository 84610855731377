import config from 'config'
import { camelCase, snakeCase } from 'lodash'
import i18n from 'initializers/i18n'
import moment from 'moment'

export const contractorDecorator = ({ name }) => name

export const contractorTypeDecorator = (type) =>
  i18n.t(`contractors:states.${type}`)

export const storageTypeDecorator = (type) => i18n.t(`storages:types.${type}`)

export const addressDecorator = ({ name, street, post_code, city }) =>
  `${street}, ${post_code} ${city.name}${name ? ` (${name})` : ''}`

export const subcontractorDecorator = ({ name }) => name

export const languageDecorator = ({ name }) => name

export const cityDecorator = ({ name }) => name
export const countryDecorator = ({ name }) => name

export const userDecorator = ({ first_name, last_name }) =>
  `${first_name} ${last_name}`

export const employeeDecorator = ({ user }) => userDecorator(user)

export const registrationKeyDecorator = ({ key }) =>
  key.match(/.{1,4}/g).join('-')

export const basicPaperSizeDecorator = ({ width, height }) =>
  `${width}x${height}`

export const paperWidthDecorator = ({ width }) =>
  `${width} ${config.units.paper_sizes}`

export const paperHeightDecorator = ({ height }) =>
  `${height} ${config.units.paper_sizes}`

export const paperSizeDecorator = ({ width, height }) =>
  `${width}x${height} ${config.units.paper_sizes}`

export const paperTypeDecorator = ({ name, weight }) =>
  `${name} ${weight}${config.units.paper_types}`

export const paperTypeGroupDecorator = ({ name }) => name

export const paperWeightDecorator = (name) =>
  `${name}${config.units.paper_types}`

export const paperNameDecorator = ({
  paper_type,
  paper_type_group,
  paper_weight,
}) => {
  if (paper_type) {
    return `${paperTypeGroupDecorator(paper_type_group)} ${paperWeightDecorator(
      paper_weight
    )} (${paperTypeDecorator(paper_type)})`
  }

  return `${paperTypeGroupDecorator(paper_type_group)} ${paperWeightDecorator(
    paper_weight
  )}`
}

export const colorTypeDecorator = ({ name }) => name

export const varnishTypeDecorator = ({ name }) => name

export const cuttingPatternDecorator = ({ internal_id, external_id }) => {
  if (external_id) {
    return `${internal_id} (${external_id})`
  }

  return internal_id
}

export const machineDecorator = ({ name }) => name

export const orderDecorator = ({ order_number, contractor = null }) => {
  if (!contractor) return order_number

  return `${order_number} - ${contractor.name}`
}

export const orderNumberDecorator = ({ order_number, contractor = null }) => {
  if (!contractor) return order_number

  return `${order_number}`
}

export const orderItemDecorator = ({ name, symbol, external_identifier }) =>
  symbol || external_identifier
    ? `${name} - ${symbol || '?'} - ${external_identifier || '?'}`
    : `${name}`

export const eventTypeDecorator = ({ short_name, name }) => short_name || name

export const eventDecorator = ({ order_number, event_type }) =>
  `${order_number} - ${eventTypeDecorator(event_type)}`

export const printingEventDecorator = ({
  paper_type_group,
  paper_weight,
  paper_type,
  paper_size,
  varnish_type,
}) => {
  let result = [
    paper_type_group
      ? paperNameDecorator({ paper_type_group, paper_weight, paper_type })
      : paperTypeDecorator(paper_type),
    paperSizeDecorator(paper_size),
  ]
  if (varnish_type) {
    result = [...result, varnishTypeDecorator(varnish_type)]
  }

  return result.join(', ')
}

export const cuttingEventDecorator = ({ cutting_pattern }) =>
  cutting_pattern ? cuttingPatternDecorator(cutting_pattern) : '-'

export const roleDecorator = ({ name }) => name

export const paperDemandDecorator = ({
  order_number,
  paper_type,
  target_paper_size,
}) =>
  `${order_number} - ${paperTypeDecorator(paper_type)}, ${paperSizeDecorator(
    target_paper_size
  )}`

export const materialDecorator = ({
  materialable_type,
  quantity_value,
  quantity_unit,
}) =>
  `${i18n.t(
    `materials:types.${snakeCase(materialable_type)}`
  )} - ${quantity_value} ${quantity_unit}`

export const deliveryNoteDecorator = ({ sequence_number, contractors }) =>
  `${sequence_number} - ${contractors.map(contractorDecorator).join(', ')}`

export const deliveryNoteItemDecorator = ({
  name,
  symbol,
  external_identifier,
}) => `${name} - ${symbol} - ${external_identifier}`

export const eventOrderPositionDecorator = (order_position, template) =>
  template === 'printing'
    ? String.fromCharCode(64 + order_position)
    : order_position

export const millisDecorator = (value) => `${value / 1000}s`

export const timeEntryDecorator = (
  employee,
  event,
  started_at,
  ended_at,
  type
) => {
  const start = moment(started_at).format('YYYY-MM-DD HH:mm')
  const end = ended_at
    ? moment(ended_at).format('HH:mm')
    : moment().format('HH:mm')
  const timeEntryType = i18n.t(`employee_time_entries:types.${type}`)
  return `
    ${employeeDecorator(employee)}
    ${
      event
        ? `${machineDecorator(event.machine)} / ${event.event_type.name}`
        : ''
    }
    ${start} - ${end}
    ${timeEntryType}
  `
}

export const durationDecorator = (value) =>
  moment.duration(value, 'seconds').format('HH:mm', { trim: false })

export const storageLocationLabelDecorator = (item) =>
  `${item?.location_x} ${item?.location_y ? item?.location_y : ''} ${
    item?.location_z ? item?.location_z : ''
  }`

export const storageDecorator = (item) =>
  `${item?.name} (${i18n.t(`storages:types.${item?.type}`).toLowerCase()})`

export const storageLocationDecorator = ({ storage, location }) =>
  `${storage?.name} - ${location}`

export const paperStockItemDecorator = ({ stock, stock_item }) =>
  `${stock.resource.paper_type && paperTypeDecorator(stock.resource.paper_type)}
  ${stock.resource.paper_size && paperSizeDecorator(stock.resource.paper_size)}
  ${stock_item.quantity}
  ${
    stock_item.storage_location
      ? storageLocationDecorator(stock_item.storage_location)
      : ''
  }`

export const idPadDecorator = (
  id,
  padLength = config.warehouse.stocks.identifierLength
) => {
  return id.toString().padStart(padLength, '0')
}

export const stockItemDecorator = (item) =>
  `[${idPadDecorator(item.id)}] ${item.quantity}${
    item.storage_location
      ? ` (${storageLocationDecorator(item.storage_location)})`
      : ''
  }`

const decoratorsMap = {
  contractorDecorator,
  subcontractorDecorator,
  addressDecorator,
  orderDecorator,
  paperDemandDecorator,
  materialDecorator,
  deliveryNoteDecorator,
  deliveryNoteItemDecorator,
  paperSizeDecorator,
  paperWidthDecorator,
  paperHeightDecorator,
  paperTypeDecorator,
  colorTypeDecorator,
  varnishTypeDecorator,
  cuttingPatternDecorator,
  machineDecorator,
  eventTypeDecorator,
  userDecorator,
  employeeDecorator,
  registrationKeyDecorator,
  eventDecorator,
  orderItemDecorator,
  printingEventDecorator,
  cuttingEventDecorator,
  timeEntryDecorator,
  paperStockItemDecorator,
  storageLocationDecorator,
  idPadDecorator,
  stockItemDecorator,
  contractorTypeDecorator,
}

export const decorateObject = (object_name, object) =>
  decoratorsMap[`${camelCase(object_name)}Decorator`](object)
