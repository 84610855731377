import config from 'config'
import _ from 'lodash'
import {
  ORDERS_LOAD_REQUEST,
  ORDERS_LOAD_SUCCESS,
  ORDERS_LOAD_FAILURE,
  ORDERS_LOAD_RESET,
  ORDERS_FETCH_REQUEST,
  ORDERS_FETCH_SUCCESS,
  ORDERS_FETCH_FAILURE,
  ORDERS_CREATE_REQUEST,
  ORDERS_CREATE_SUCCESS,
  ORDERS_CREATE_FAILURE,
  ORDERS_UPDATE_REQUEST,
  ORDERS_UPDATE_SUCCESS,
  ORDERS_UPDATE_FAILURE,
  ORDERS_FILTER_SET_KEYWORD,
  ORDERS_FILTER_SET_STATUS,
  ORDERS_FILTER_SET_YEARS,
  ORDERS_FILTER_SET_DAYS,
  ORDERS_FILTER_SELECT_CONTRACTOR,
  ORDERS_FILTER_SELECT_MANAGER,
  ORDERS_CLEAR_FILTER,
  ORDERS_SORT,
  ORDERS_SET_QUERY_SETTINGS,
  ORDERS_STOP_UPDATING,
  ORDERS_CLEAR,
  ORDERS_SET_ERRORS,
  ORDERS_CLEAR_REDIRECT,
  ORDERS_UPDATE_ITEM,
  ORDERS_UPDATE_ITEM_EVENT,
  ORDERS_INCREASE_NEW_ITEMS,
  ORDERS_FILTER_SELECT_COLOR_TYPES,
  ORDERS_FILTER_SELECT_VARNISH_TYPE,
  ORDERS_FILTER_SELECT_PAPER_SIZES,
  ORDERS_FILTER_SELECT_PAPER_TYPE_GROUPS,
  ORDERS_FILTER_SELECT_PAPER_WEIGHTS,
  ORDERS_FILTER_SELECT_PAPER_TYPES,
  ORDERS_FILTER_SELECT_CUTTING_PATTERN,
  ORDERS_FILTER_SET_EVENT_DESCRIPTION,
  ORDERS_SET_ADVANCED_FILTERS_UPDATED,
  ORDERS_FILTER_SET_DUE_DATE,
} from '../actions/orders'

const initialState = {
  items: [],
  total: 9999,
  isLoading: false,
  loadingFailed: false,
  filter: {
    keyword: '',
    status: config.options.orders.defaultStatus,
    years: [],
    contractor: null,
    manager: null,
    days: 0,
    due_date: null,
    color_types: [],
    varnish_type: null,
    paper_sizes: [],
    paper_type_group: null,
    paper_weights: [],
    paper_types: [],
    cutting_pattern: null,
    event_description: '',
  },
  sorting: {
    column: 'orders.created_at',
    direction: 'asc',
  },
  errors: [],
  settingsFetched: false,
  redirectTo: null,
  newItems: 0,
  advancedFiltersUpdated: false,
  analytics: null,
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    // ORDERS_LOAD_REQUEST
    // ORDERS_FETCH_REQUEST
    case ORDERS_LOAD_REQUEST:
    case ORDERS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // ORDERS_LOAD_SUCCESS
    case ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        items: [...state.items, ...action.items],
        total: action.total,
        isLoading: false,
        loadingFailed: false,
        newItems: 0,
      }
    // ORDERS_FETCH_SUCCESS
    case ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        total: action.total,
        isLoading: false,
        loadingFailed: false,
        newItems: 0,
      }
    // ORDERS_LOAD_FAILURE
    // ORDERS_FETCH_FAILURE
    case ORDERS_LOAD_FAILURE:
    case ORDERS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // ORDERS_LOAD_RESET
    case ORDERS_LOAD_RESET:
      return {
        ...state,
        items: [],
        total: initialState.total,
      }
    // ORDERS_CREATE_REQUEST
    // ORDERS_UPDATE_REQUEST
    case ORDERS_CREATE_REQUEST:
    case ORDERS_UPDATE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // ORDERS_CREATE_SUCCESS
    // ORDERS_UPDATE_SUCCESS
    case ORDERS_CREATE_SUCCESS:
    case ORDERS_UPDATE_SUCCESS:
      return {
        ...state,
        isFormLoading: false,
        redirectTo: action.id,
      }
    // ORDERS_CREATE_FAILURE
    // ORDERS_UPDATE_FAILURE
    case ORDERS_CREATE_FAILURE:
    case ORDERS_UPDATE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // ORDERS_FILTER_SET_KEYWORD
    case ORDERS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
      }
    // ORDERS_FILTER_SET_STATUS
    case ORDERS_FILTER_SET_STATUS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.status,
        },
      }
    // ORDERS_FILTER_SET_YEARS
    case ORDERS_FILTER_SET_YEARS:
      return {
        ...state,
        filter: {
          ...state.filter,
          years: action.years,
        },
      }
    // ORDERS_FILTER_SET_DAYS
    case ORDERS_FILTER_SET_DAYS:
      return {
        ...state,
        filter: {
          ...state.filter,
          days: action.days,
        },
      }
    // ORDERS_FILTER_SET_DUE_DATE
    case ORDERS_FILTER_SET_DUE_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          due_date: action.due_date,
        },
      }
    // ORDERS_FILTER_SELECT_COLOR_TYPES
    case ORDERS_FILTER_SELECT_COLOR_TYPES:
      return {
        ...state,
        filter: {
          ...state.filter,
          color_types: action.ids,
        },
      }
    // ORDERS_FILTER_SELECT_VARNISH_TYPE
    case ORDERS_FILTER_SELECT_VARNISH_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          varnish_type: action.id,
        },
      }
    // ORDERS_FILTER_SELECT_PAPER_SIZES
    case ORDERS_FILTER_SELECT_PAPER_SIZES:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_sizes: action.ids,
        },
      }
    // ORDERS_FILTER_SELECT_PAPER_TYPE_GROUPS
    case ORDERS_FILTER_SELECT_PAPER_TYPE_GROUPS:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_type_group: action.id,
        },
      }
    // ORDERS_FILTER_SELECT_PAPER_WEIGHTS
    case ORDERS_FILTER_SELECT_PAPER_WEIGHTS:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_weights: action.values,
        },
      }
    // ORDERS_FILTER_SELECT_PAPER_TYPES
    case ORDERS_FILTER_SELECT_PAPER_TYPES:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_types: action.ids,
        },
      }
    // ORDERS_FILTER_SELECT_CUTTING_PATTERN
    case ORDERS_FILTER_SELECT_CUTTING_PATTERN:
      return {
        ...state,
        filter: {
          ...state.filter,
          cutting_pattern: action.id,
        },
      }
    // ORDERS_FILTER_SET_EVENT_DESCRIPTION
    case ORDERS_FILTER_SET_EVENT_DESCRIPTION:
      return {
        ...state,
        filter: {
          ...state.filter,
          event_description: action.value,
        },
      }
    // ORDERS_FILTER_SELECT_CONTRACTOR
    case ORDERS_FILTER_SELECT_CONTRACTOR:
      return {
        ...state,
        filter: {
          ...state.filter,
          contractor: action.contractor,
        },
      }
    // ORDERS_FILTER_SELECT_MANAGER
    case ORDERS_FILTER_SELECT_MANAGER:
      return {
        ...state,
        filter: {
          ...state.filter,
          manager: action.manager,
        },
      }
    // ORDERS_CLEAR_FILTER
    case ORDERS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          status: initialState.filter.status,
          years: initialState.filter.years,
          contractor: null,
          manager: null,
          days: initialState.filter.days,
          due_date: initialState.filter.due_date,
          color_types: initialState.filter.color_types,
          varnish_type: initialState.filter.varnish_type,
          paper_size: initialState.filter.paper_size,
          paper_type_group: initialState.filter.paper_type_group,
          paper_weights: initialState.filter.paper_weights,
          paper_type: initialState.filter.paper_type,
          cutting_pattern: initialState.filter.cutting_pattern,
        },
        advancedFiltersUpdated: _.uniqueId('af'),
      }
    // ORDERS_SORT
    case ORDERS_SORT:
      return {
        ...state,
        sorting: action.sorting,
      }
    // ORDERS_SET_ERRORS
    case ORDERS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // ORDERS_SET_QUERY_SETTINGS
    case ORDERS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.status || initialState.filter.status,
          years: (action.years || []).map((e) => parseInt(e, 10)),
          contractor: parseInt(action.contractor, 10) || null,
          manager: parseInt(action.manager, 10) || null,
          days: parseInt(action.days, 10) || initialState.filter.days,
          due_date: action.due_date || initialState.filter.due_date,
          color_types: (action.color_types || []).map((e) => parseInt(e, 10)),
          varnish_type: parseInt(action.varnish_type, 10) || null,
          paper_sizes: (action.paper_sizes || []).map((e) => parseInt(e, 10)),
          paper_type_group: action.paper_type_group || null,
          paper_weights: (action.paper_weights || []).map((e) =>
            parseInt(e, 10)
          ),
          paper_types: (action.paper_types || []).map((e) => parseInt(e, 10)),
          cutting_pattern: parseInt(action.cutting_pattern, 10) || null,
          keyword: action.search || null,
          event_description: action.event_description || null,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // ORDERS_CLEAR
    case ORDERS_CLEAR:
      return {
        ...state,
        items: [],
      }
    // ORDERS_STOP_UPDATING
    case ORDERS_STOP_UPDATING:
      return {
        ...state,
        items: state.items.map((order) =>
          order.id !== action.id ? order : { ...order, updating: false }
        ),
      }
    // ORDERS_CLEAR_REDIRECT
    case ORDERS_CLEAR_REDIRECT:
      return {
        ...state,
        redirectTo: null,
      }
    // ORDERS_UPDATE_ITEM
    case ORDERS_UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.data.id ? { ...action.data } : item
        ),
      }
    // ORDERS_UPDATE_ITEM_EVENT
    case ORDERS_UPDATE_ITEM_EVENT:
      return {
        ...state,
        items: state.items.map((order) =>
          order.id === action.data.order.id
            ? {
                ...{ ...order, updating: action.updating },
                events: order.events.map((event) =>
                  event.id === action.data.id ? action.data : event
                ),
                progress: action.data.order.progress,
              }
            : order
        ),
      }
    // ORDERS_INCREASE_NEW_ITEMS
    case ORDERS_INCREASE_NEW_ITEMS:
      return {
        ...state,
        newItems: state.newItems + 1,
      }
    // ORDERS_SET_ADVANCED_FILTERS_UPDATED
    case ORDERS_SET_ADVANCED_FILTERS_UPDATED:
      return {
        ...state,
        advancedFiltersUpdated: action.value,
      }
    default:
      return state
  }
}

export default ordersReducer
