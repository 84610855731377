import React, { useRef } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import config from 'config'

/* ------------------------------- Components ------------------------------- */
import Moment from 'react-moment'
import ReactTimeAgo from 'react-timeago'
import { UncontrolledTooltip } from 'reactstrap'

/* --------------------------------- Common --------------------------------- */
import polishStrings from 'react-timeago/lib/language-strings/pl'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

const formatter = buildFormatter(polishStrings)

/* -------------------------------------------------------------------------- */
/*                               Date Component                               */
/* -------------------------------------------------------------------------- */
const Date = ({
  format = config.formatting.date.short,
  date,
  tooltip = false,
  ...rest
}) => {
  const dateRef = useRef()

  /* --------------------------------- Render --------------------------------- */
  return (
    <>
      <span ref={dateRef}>
        <Moment locale="pl" format={format} date={date} {...rest} />
      </span>
      {tooltip && (
        <UncontrolledTooltip
          placement="bottom"
          target={dateRef}
          boundariesElement="table"
        >
          <ReactTimeAgo date={date} formatter={formatter} />
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default Date
