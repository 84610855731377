import { isMac } from 'utils/functions'
import {
  KEYS_CONTROL,
  KEYS_COMMAND,
  KEYS_ESCAPE,
  KEYS_A,
  KEYS_ENTER,
  KEYS_DELETE,
} from 'utils/keys'

const url = process.env.REACT_APP_API_ADDRESS
const version = 'v1'
const fullUrl = `${url}/api/${version}`
const ws = process.env.REACT_APP_WS_ADDRESS
const env = process.env.REACT_APP_ENV
const controlKey = isMac() ? KEYS_COMMAND : KEYS_CONTROL

export default {
  env,
  version: '0.6.2',
  api: {
    url,
    fullUrl,
    version,
    account: {
      root: '/account',
      permissions: '/account/permissions',
      password: '/account/password',
      settings: '/account/settings',
    },
    sessions: {
      root: '/sessions',
    },
    orders: {
      root: '/orders',
      sheet: '/orders/sheet',
      search: '/orders/search',
    },
    contractors: {
      root: '/contractors',
      search: '/contractors/search',
    },
    suppliers: {
      root: '/suppliers',
      search: '/suppliers/search',
    },
    addresses: {
      root: '/addresses',
      search: '/addresses/search',
    },
    employees: {
      root: '/employees',
      search: '/employees/search',
    },
    employee_time_entries: {
      root: '/employee_time_entries',
    },
    issues: {
      root: '/issues',
      search: '/issues/search',
    },
    users: {
      root: '/users',
      search: '/users/search',
      online: '/users/online',
    },
    languages: {
      root: '/languages',
      search: '/languages/search',
    },
    cities: {
      root: '/cities',
      search: '/cities/search',
    },
    countries: '/countries',
    roles: {
      root: '/roles',
      search: '/roles/search',
    },
    registration_keys: '/registration_keys',
    paper_sizes: {
      root: '/paper_sizes',
      search: '/paper_sizes/search',
      replace: '/paper_sizes/replace',
    },
    paper_types: {
      root: '/paper_types',
      search: '/paper_types/search',
      replace: '/paper_types/replace',
    },
    paper_type_groups: {
      root: '/paper_type_groups',
      search: '/paper_type_groups/search',
    },
    color_types: {
      root: '/color_types',
      search: '/color_types/search',
      replace: '/color_types/replace',
    },
    varnish_types: {
      root: '/varnish_types',
      search: '/varnish_types/search',
      replace: '/varnish_types/replace',
    },
    cutting_patterns: {
      root: '/cutting_patterns',
      search: '/cutting_patterns/search',
    },
    machines: {
      root: '/machines',
      search: '/machines/search',
    },
    process_types: {
      root: '/event_types',
      search: '/event_types/search',
    },
    events: {
      root: '/events',
    },
    paper_demands: {
      root: '/paper_demands',
      materials: '/paper_demands/materials',
      fill: '/paper_demands/fill',
    },
    materials: {
      root: '/materials',
    },
    delivery_notes: {
      root: '/delivery_notes',
    },
    versions: {
      root: '/versions',
    },
    system: {
      root: '/system',
    },
    storages: {
      root: '/storages',
      search: '/storages/search',
    },
    stocks: {
      root: '/warehouse/stocks',
      search: '/warehouse/stocks/search',
    },
    storage_locations: {
      search: '/storages/locations/search',
    },
  },
  ws: {
    fullUrl: ws,
  },
  business: {
    contractor: {
      title: 'GAL-DRUK',
      address: {
        street: 'ul. Wspólna 26',
        city: 'Brzeźnio',
        post_code: '98-275',
        country: 'Poland',
      },
      bdo: '000026672',
    },
  },
  formatting: {
    date: {
      short: 'DD.MM.YY',
      long: 'DD.MM.YYYY',
    },
  },
  cookies: {
    aside: 'gd.aside.frozen',
  },
  alerts: {
    // Alerts visibility in ms
    visibilityTime: 6000,
  },
  sessions: {
    requestInterval: 60000,
  },
  toasts: {
    visibilityTime: 7000,
  },
  pagination: {
    limits: [25, 50, 75, 100],
  },
  addresses: {
    defaultCountry: 'Polska',
  },
  debounceTime: 300,
  warehouse: {
    stocks: {
      identifierLength: 7,
      typesToRelease: ['in_stock', 'reserved'],
      typesToReturn: ['released'],
      typesToSplit: ['in_stock'],
      typesToJoin: ['in_stock'],
      typesToMoveToInStock: ['scheduled'],
      typeToPrint: ['in_stock'],
    },
  },
  inputs: {
    range: {
      paper_sizes: {
        min: 0,
        max: 2000,
      },
      paper_types: {
        min: 0,
        max: 1000,
      },
      order_days: {
        min: 0,
        max: 100,
      },
      delivery_note_days: {
        min: 0,
        max: 500,
      },
    },
  },
  units: {
    paper_sizes: 'mm',
    paper_types: 'g',
  },
  options: {
    contractors: {
      types: ['customer', 'supplier', 'subcontractor'],
      filters: {
        is_active: {
          values: ['all', 'active', 'inactive'],
          defaultValue: 'all',
        },
      },
    },
    color_types: ['cmyk', 'pantone', 'other'],
    machines: {
      templates: ['basic', 'printing', 'cutting'],
    },
    process_types: {
      templates: ['basic', 'printing', 'cutting'],
      units: ['sheets', 'pcs'],
      defaultUnit: 'sheets',
    },
    orders: {
      statuses: [
        'all',
        'not_started',
        'active',
        'pending',
        'awaiting_for_approval',
        'in_progress',
        'completed',
        'stored',
        'sent',
        'rejected',
        'cancelled',
        'planned',
      ],
      defaultStatus: 'active',
      events: {
        pending: ['for_approval', 'start', 'cancel'],
        awaiting_for_approval: ['start', 'cancel', 'restart'],
        in_progress: ['complete', 'cancel', 'restart'],
        completed: ['store', 'release', 'cancel', 'restart'],
        stored: ['release', 'cancel', 'restart'],
        sent: ['reject'],
        rejected: ['complete', 'cancel'],
        cancelled: ['for_approval', 'start', 'restart'],
      },
      activeStatuses: [
        'pending',
        'awaiting_for_approval',
        'in_progress',
        'rejected',
      ],
      completedStatuses: ['stored', 'sent'],
      defaultOrderItems: [
        {
          name: '',
          internal_identifier: '',
          external_identifier: '',
          quantity: null,
        },
      ],
      defaultPrintingEvents: [
        {
          machine: null,
          quantity: null,
          front_page_color_types: [],
          front_page_varnish_type: null,
          back_page_color_types: [],
          back_page_varnish_type: null,
          paper_type: null,
          paper_size: null,
          eventable: {
            colors_annotation: null,
            varnish_type_annotation: null,
            paper_size_annotation: null,
            paper_type_annotation: null,
          },
        },
      ],
      defaultBasicEvents: [
        {
          event_type: { key: 'packing' },
          quantity: 0,
          disabled: true,
          prepend: true,
        },
        {
          event_type: { key: 'quality_control' },
          quantity: 0,
          disabled: true,
        },
      ],
      eventsLimit: 15,
      // Highlight upcoming due date [days]
      upcomingDueDateLimit: -2,
    },
    events: {
      defaultStatus: 'all',
      events: {
        pending: ['start', 'cancel'],
        in_progress: ['complete', 'suspend', 'restart', 'cancel'],
        completed: ['restart', 'start', 'suspend', 'cancel'],
        suspended: ['start', 'cancel'],
        cancelled: ['restart', 'start'],
      },
      changeMachine: {
        forbiddenStatuses: ['in_progress', 'completed'],
      },
      activeStatuses: ['pending', 'in_progress', 'suspended'],
      changeStatus: {
        noMachine: {
          forbiddenEvents: ['start'],
        },
      },
    },
    materials: {
      statuses: ['all', 'pending', 'ordered', 'supplied', 'cancelled'],
      defaultStatus: 'pending',
      events: {
        pending: ['make_order', 'supply', 'cancel'],
        ordered: ['supply', 'cancel', 'restart'],
        supplied: ['cancel', 'restart'],
        cancelled: ['restart'],
      },
      changeStatus: {
        noSupplier: {
          forbiddenEvents: ['make_order'],
        },
      },
    },
    currencies: ['PLN', 'EUR', 'USD', 'GBP'],
    settings: {
      notifications: {
        alerts: {
          time: [2000, 3000, 5000, 7000],
        },
        toasts: {
          limit: [1, 2, 3, 4],
          time: [2000, 3000, 5000, 7000, 10000],
        },
      },
      sync: {
        orders: {
          highlight_time: [500, 1000, 2000, 3000, 5000],
        },
        events: {
          highlight_time: [500, 1000, 2000, 3000, 5000],
        },
      },
    },
    countries: {
      priority: ['Polska'],
    },
    delivery_notes: {
      units: ['pcs', 'sheets', 'kgs', 'sets'],
      printing: {
        default_copies: 4,
        max_copies: 20,
        default_language: 'en',
        supported_languages: ['pl', 'en'],
        simplified_languages: ['pl'],
      },
    },
    users: {
      types: ['employee', 'customer'],
    },
    issues: {
      types: [
        'device_failure',
        'maintenance',
        'out_of_stock',
        'employee_inactive',
        'other',
      ],
      statuses: ['opened', 'resolved', 'closed', 'all'],
      defaultStatus: 'opened',
      events: {
        opened: ['resolve', 'close'],
        resolved: ['restart', 'close'],
        closed: ['restart', 'resolve'],
      },
      eventStatus: {
        resolve: 'resolved',
        restart: 'opened',
        close: 'closed',
      },
    },
    employee_time_entries: {
      types: [
        'break',
        'device_failure',
        'maintenance',
        'out_of_stock',
        'work',
        'prepare',
        'other',
      ],
      end_reasons: [
        'completed',
        'break',
        'end_of_shift',
        'end_of_batch',
        'maintenance',
        'out_of_stock',
        'device_failure',
        'other',
      ],
    },
    storages: {
      types: ['paper'],
    },
  },
  colors: {
    cmyk: {
      c: '#0ff',
      m: '#f0f',
      y: '#ff0',
      k: '#000',
    },
  },
  startYear: 2009,
  orders: {
    eventStatus: {
      start: 'in_progress',
      for_approval: 'awaiting_for_approval',
      complete: 'completed',
      store: 'stored',
      release: 'sent',
      reject: 'rejected',
      restart: 'pending',
      cancel: 'cancelled',
    },
  },
  events: {
    eventStatus: {
      start: 'in_progress',
      complete: 'completed',
      suspend: 'suspended',
      restart: 'pending',
      cancel: 'cancelled',
    },
    templates: {
      basic: 'basic',
      printing: 'printing',
      cutting: 'cutting',
      default: ['basic', 'cutting'],
      extraFields: ['cutting', 'printing'],
    },
    noPrintingColumns: ['quality_control'],
    withOrderItems: ['packing'],
  },
  materials: {
    eventStatus: {
      make_order: 'ordered',
      supply: 'supplied',
      cancel: 'cancelled',
      restart: 'pending',
    },
  },
  keys: {
    control: controlKey,
    multiSelection: controlKey,
    selectionsHighlight: controlKey,
    selectAll: KEYS_A,
    exit: KEYS_ESCAPE,
    clear: KEYS_DELETE,
    confirm: KEYS_ENTER,
  },
  settings: {
    notifications: {
      alerts: {
        time: 3000,
      },
      toasts: {
        enabled: true,
        sounds: true,
        time: 5000,
        limit: 2,
      },
    },
    sync: {
      orders: {
        highlight_time: 3000,
      },
      events: {
        highlight_time: 3000,
      },
    },
    interactions: {
      data: {
        sequence_number: {
          copy_to_clipboard: {
            enabled: true,
          },
        },
      },
      action_links: {
        orders: {
          show_in_new_tab: {
            enabled: true,
          },
        },
        delivery_notes: {
          show_in_new_tab: {
            enabled: true,
          },
        },
      },
      popovers: {
        orders: {
          close_after_request: {
            enabled: true,
          },
        },
        events: {
          close_after_request: {
            status: {
              enabled: true,
            },
            machine: {
              enabled: true,
            },
          },
        },
      },
    },
  },
  text: {
    orders: {
      truncateLength: 25,
    },
  },
  regex: {
    cutting_patterns: {
      // internal_id: /(([A-Z]-[0-9]+)|([0-9]+-[A-Z]))/,
      external_id: /(GALDRUK\s?[\w_-]+|(?:\w+[_-])+\w+)/,
      is_pertinax: /pertin/,
      is_cleaning_section: /czyszcz/,
      is_putting_section: /odkład/,
    },
  },
  roles: {
    blocked_keys: ['admin'],
  },
  date: {
    defaultFormat: 'DD.MM.YY',
  },
  timeline: {
    keys: {
      groupIdKey: 'id',
      groupTitleKey: 'title',
      groupRightTitleKey: 'rightTitle',
      itemIdKey: 'id',
      itemTitleKey: 'title',
      itemDivTitleKey: 'title',
      itemGroupKey: 'machine_id',
      itemTimeStartKey: 'started_at',
      itemTimeEndKey: 'virtual_ended_at',
      groupLabelKey: 'title',
    },
    timeRanges: {
      week: 7 * 24 * 60 * 60 * 1000, // [ms]
      year: 365 * 24 * 60 * 60 * 1000,
    },
  },
  employee_time_entries: {
    types: [
      'work',
      'prepare',
      'break',
      'maintenance',
      'out_of_stock',
      'device_failure',
      'other',
    ],
    colors: {
      work: '#ffc107',
      prepare: '#17a2b8',
      break: '#6c757d',
      maintenance: '#6746d3',
      out_of_stock: '#fd7e14',
      device_failure: '#dc3545',
      other: '#333',
      session: '#0d6efd',
    },
  },
  startYearAnalysis: 2023,
  labelMaxLength: 10,
  charts: {
    colors: [
      'green',
      'cyan',
      'violet',
      'rose',
      'sky',
      'emerald',
      'indigo',
      'purple',
      'blue',
      'fuchsia',
      'teal',
      'pink',
    ],
  },
}

export const PAPER_DEMANDS_SELECTION_TYPE_ALL = 0
export const PAPER_DEMANDS_SELECTION_TYPE_BASE = 1
export const PAPER_DEMANDS_SELECTION_TYPE_EMPTY = 2
export const MAX_MACHINE_IN_LINE = 3
