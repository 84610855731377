import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const PAPER_SIZES_FETCH_REQUEST = 'PAPER_SIZES_FETCH_REQUEST'
export const PAPER_SIZES_FETCH_SUCCESS = 'PAPER_SIZES_FETCH_SUCCESS'
export const PAPER_SIZES_FETCH_FAILURE = 'PAPER_SIZES_FETCH_FAILURE'
// CREATE
export const PAPER_SIZES_CREATE_REQUEST = 'PAPER_SIZES_CREATE_REQUEST'
export const PAPER_SIZES_CREATE_SUCCESS = 'PAPER_SIZES_CREATE_SUCCESS'
export const PAPER_SIZES_CREATE_FAILURE = 'PAPER_SIZES_CREATE_FAILURE'
// UPDATE
export const PAPER_SIZES_UPDATE_REQUEST = 'PAPER_SIZES_UPDATE_REQUEST'
export const PAPER_SIZES_UPDATE_SUCCESS = 'PAPER_SIZES_UPDATE_SUCCESS'
export const PAPER_SIZES_UPDATE_FAILURE = 'PAPER_SIZES_UPDATE_FAILURE'
// DELETE
export const PAPER_SIZES_DELETE_REQUEST = 'PAPER_SIZES_DELETE_REQUEST'
export const PAPER_SIZES_DELETE_SUCCESS = 'PAPER_SIZES_DELETE_SUCCESS'
export const PAPER_SIZES_DELETE_FAILURE = 'PAPER_SIZES_DELETE_FAILURE'
// REPLACE
export const PAPER_SIZES_REPLACE_REQUEST = 'PAPER_SIZES_REPLACE_REQUEST'
export const PAPER_SIZES_REPLACE_SUCCESS = 'PAPER_SIZES_REPLACE_SUCCESS'
export const PAPER_SIZES_REPLACE_FAILURE = 'PAPER_SIZES_REPLACE_FAILURE'
// MODALS
export const PAPER_SIZES_TOGGLE_ADD_MODAL = 'PAPER_SIZES_TOGGLE_ADD_MODAL'
export const PAPER_SIZES_TOGGLE_EDIT_MODAL = 'PAPER_SIZES_TOGGLE_EDIT_MODAL'
export const PAPER_SIZES_TOGGLE_DELETE_MODAL = 'PAPER_SIZES_TOGGLE_DELETE_MODAL'
export const PAPER_SIZES_TOGGLE_REPLACE_MODAL =
  'PAPER_SIZES_TOGGLE_REPLACE_MODAL'
// FILTERS
export const PAPER_SIZES_FILTER_SET_KEYWORD = 'PAPER_SIZES_FILTER_SET_KEYWORD'
export const PAPER_SIZES_FILTER_SET_WIDTH = 'PAPER_SIZES_FILTER_SET_WIDTH'
export const PAPER_SIZES_FILTER_SET_HEIGHT = 'PAPER_SIZES_FILTER_SET_HEIGHT'
export const PAPER_SIZES_CLEAR_FILTER = 'PAPER_SIZES_CLEAR_FILTER'
// SELECTION
export const PAPER_SIZES_ADD_ITEM_SELECTION = 'PAPER_SIZES_ADD_ITEM_SELECTION'
export const PAPER_SIZES_REMOVE_ITEM_SELECTION =
  'PAPER_SIZES_REMOVE_ITEM_SELECTION'
export const PAPER_SIZES_TOGGLE_ITEM_SELECTIONS =
  'PAPER_SIZES_TOGGLE_ITEM_SELECTIONS'
export const PAPER_SIZES_CLEAR_ITEM_SELECTIONS =
  'PAPER_SIZES_CLEAR_ITEM_SELECTIONS'
// Other
export const PAPER_SIZES_SORT = 'PAPER_SIZES_SORT'
export const PAPER_SIZES_SET_ERRORS = 'PAPER_SIZES_SET_ERRORS'
export const PAPER_SIZES_SET_CURRENT_PAGE = 'PAPER_SIZES_SET_CURRENT_PAGE'
export const PAPER_SIZES_SET_PAGINATION_PER_PAGE =
  'PAPER_SIZES_SET_PAGINATION_PER_PAGE'
export const PAPER_SIZES_SET_QUERY_SETTINGS = 'PAPER_SIZES_SET_QUERY_SETTINGS'
export const PAPER_SIZES_CLEAR = 'PAPER_SIZES_CLEAR'

/**
 * FETCH PAPER SIZES
 * * Get paper sizes list with pagination
 */
export const fetchPaperSizes = () => async (dispatch, getState) => {
  // Get current state
  const {
    paper_sizes: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.paper_sizes.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          width_min: filter.width.min,
          width_max: filter.width.max,
          height_min: filter.height.min,
          height_max: filter.height.max,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: PAPER_SIZES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: PAPER_SIZES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: PAPER_SIZES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_sizes:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE PAPER SIZE
 * * Create paper size and display validation errors
 */
export const createPaperSize =
  ({ name, width, height }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.paper_sizes.root, { name, width, height }),
      beforeRequest: () => dispatch({ type: PAPER_SIZES_CREATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_sizes:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: PAPER_SIZES_CREATE_SUCCESS })
        // Reload paper sizes list
        dispatch(fetchPaperSizes())
        // Close modal and reset errors
        dispatch({ type: PAPER_SIZES_TOGGLE_ADD_MODAL, state: false })
        dispatch({ type: PAPER_SIZES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PAPER_SIZES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_SIZES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_sizes:alerts.cannot_create'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_SIZES_TOGGLE_ADD_MODAL, state: false }),
    })

/**
 * UPDATE PAPER SIZE
 * * Update paper size and reload paper sizes list
 */
export const updatePaperSize =
  ({ id, name, width, height }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.paper_sizes.root}/${id}`, {
          name,
          width,
          height,
        }),
      beforeRequest: () => dispatch({ type: PAPER_SIZES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_sizes:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: PAPER_SIZES_UPDATE_SUCCESS })
        // Reload paper sizes list
        dispatch(fetchPaperSizes())
        // Close modal and reset errors
        dispatch({ type: PAPER_SIZES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: PAPER_SIZES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PAPER_SIZES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_SIZES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_sizes:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_SIZES_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE PAPER SIZE
 * * Delete paper size and fetch paper sizes list
 */
export const deletePaperSize = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.paper_sizes.root}/${id}`),
    beforeRequest: () => dispatch({ type: PAPER_SIZES_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('paper_sizes:alerts.successfully_deleted'), 'success')
      // Disable loading
      dispatch({ type: PAPER_SIZES_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: PAPER_SIZES_TOGGLE_DELETE_MODAL, state: false })
      // Reload paper sizes list
      dispatch(fetchPaperSizes())
    },
    onError: () => dispatch({ type: PAPER_SIZES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_sizes:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: PAPER_SIZES_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * REPLACE PAPER SIZES
 * * Replace selected paper sizes
 */
export const replacePaperSizes =
  ({ target_paper_size_id, paper_size_ids }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(config.api.paper_sizes.replace, {
          target_paper_size_id,
          paper_size_ids,
        }),
      beforeRequest: () => dispatch({ type: PAPER_SIZES_REPLACE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_sizes:alerts.successfully_replaced'),
          'success'
        )

        // Disable loading
        dispatch({ type: PAPER_SIZES_REPLACE_SUCCESS })
        // Reload paper sizes list
        dispatch(fetchPaperSizes())
        // Close modal and reset errors
        dispatch({ type: PAPER_SIZES_TOGGLE_REPLACE_MODAL, state: false })
        dispatch({ type: PAPER_SIZES_SET_ERRORS, errors: [] })
        // Clear selections
        dispatch({ type: PAPER_SIZES_CLEAR_ITEM_SELECTIONS })
      },
      onError: () => dispatch({ type: PAPER_SIZES_REPLACE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_SIZES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_sizes:alerts.cannot_replace'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_SIZES_TOGGLE_REPLACE_MODAL, state: false }),
    })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: PAPER_SIZES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchPaperSizes())
}
