import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const CITIES_FETCH_REQUEST = 'CITIES_FETCH_REQUEST'
export const CITIES_FETCH_SUCCESS = 'CITIES_FETCH_SUCCESS'
export const CITIES_FETCH_FAILURE = 'CITIES_FETCH_FAILURE'
// CREATE
export const CITIES_CREATE_REQUEST = 'CITIES_CREATE_REQUEST'
export const CITIES_CREATE_SUCCESS = 'CITIES_CREATE_SUCCESS'
export const CITIES_CREATE_FAILURE = 'CITIES_CREATE_FAILURE'
// UPDATE
export const CITIES_UPDATE_REQUEST = 'CITIES_UPDATE_REQUEST'
export const CITIES_UPDATE_SUCCESS = 'CITIES_UPDATE_SUCCESS'
export const CITIES_UPDATE_FAILURE = 'CITIES_UPDATE_FAILURE'
// DELETE
export const CITIES_DELETE_REQUEST = 'CITIES_DELETE_REQUEST'
export const CITIES_DELETE_SUCCESS = 'CITIES_DELETE_SUCCESS'
export const CITIES_DELETE_FAILURE = 'CITIES_DELETE_FAILURE'
// RESOURCES
export const CITIES_SET_AVAILABLE_CITIES = 'CITIES_SET_AVAILABLE_CITIES'
export const CITIES_SET_AVAILABLE_COUNTRIES = 'CITIES_SET_AVAILABLE_COUNTRIES'
// MODALS
export const CITIES_TOGGLE_EDIT_MODAL = 'CITIES_TOGGLE_EDIT_MODAL'
export const CITIES_TOGGLE_DELETE_MODAL = 'CITIES_TOGGLE_DELETE_MODAL'
// FILTERS
export const CITIES_FILTER_SET_KEYWORD = 'CITIES_FILTER_SET_KEYWORD'
export const CITIES_CLEAR_FILTER = 'CITIES_CLEAR_FILTER'
export const CITIES_SELECT_CITY = 'CITIES_SELECT_CITY'
export const CITIES_SELECT_COUNTRY = 'CITIES_SELECT_COUNTRY'
export const CITIES_SET_WITH_CONTRACTORS = 'CITIES_SET_WITH_CONTRACTORS'
// Other
export const CITIES_SORT = 'CITIES_SORT'
export const CITIES_SET_ERRORS = 'CITIES_SET_ERRORS'
export const CITIES_SET_CURRENT_PAGE = 'CITIES_SET_CURRENT_PAGE'
export const CITIES_SET_PAGINATION_PER_PAGE = 'CITIES_SET_PAGINATION_PER_PAGE'
export const CITIES_SET_QUERY_SETTINGS = 'CITIES_SET_QUERY_SETTINGS'
export const CITIES_CLEAR = 'CITIES_CLEAR'
export const CITIES_ADD_AVAILABLE_CITY = 'CITIES_ADD_AVAILABLE_CITY'

/**
 * FETCH CITIES
 * * Get cities list with pagination
 */
export const fetchCities = () => async (dispatch, getState) => {
  // Get current state
  const {
    cities: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.cities.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: CITIES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: CITIES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: CITIES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('cities:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE CITY
 * * Create city and display validation errors
 */
export const createCity =
  ({ data, onClose, onCreate = () => {} }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.post(config.api.cities.root, data),
      beforeRequest: () => dispatch({ type: CITIES_CREATE_REQUEST }),
      onSuccess: ({ data: responseData }) => {
        // Display successfull message
        displayAlert(i18n.t('cities:alerts.successfully_created'), 'success')
        // Disable loading
        dispatch({ type: CITIES_CREATE_SUCCESS })
        // Reload cities list
        dispatch(fetchCities())
        // Call onCreate method
        onCreate(responseData)
        // Close modal and reset errors
        onClose()
        dispatch({ type: CITIES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: CITIES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (errors) =>
        dispatch({ type: CITIES_SET_ERRORS, errors }),
      onUnknownError: () =>
        displayAlert(i18n.t('cities:alerts.cannot_create'), 'error'),
    })
