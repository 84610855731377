import React, { useEffect } from 'react'

/* ------------------------------- Components ------------------------------- */
import { useDispatch, useSelector } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import { createOrRenewSession } from 'redux/actions/session'

/* ---------------------------------- Utils --------------------------------- */
import config from 'config'

/* -------------------------------------------------------------------------- */
/*                         Session Provider Component                         */
/* -------------------------------------------------------------------------- */
const SessionProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { isSignedIn } = useSelector((state) => state.session)

  useEffect(() => {
    if (isSignedIn) {
      dispatch(createOrRenewSession())
      const interval = setInterval(
        () => dispatch(createOrRenewSession()),
        config.sessions.requestInterval
      )

      return () => clearInterval(interval)
    }
    return undefined
  }, [dispatch, isSignedIn])

  return <>{children}</>
}

export default SessionProvider
