import config from 'config'
import _ from 'lodash'
import {
  EVENTS_FETCH_REQUEST,
  EVENTS_FETCH_SUCCESS,
  EVENTS_FETCH_FAILURE,
  EVENTS_MOVE_REQUEST,
  EVENTS_MOVE_SUCCESS,
  EVENTS_MOVE_FAILURE,
  EVENTS_FILTER_SET_KEYWORD,
  EVENTS_FILTER_SET_STATUS,
  EVENTS_CLEAR_FILTER,
  EVENTS_SORT,
  EVENTS_SET_QUERY_SETTINGS,
  EVENTS_CLEAR,
  EVENTS_ADD_ITEM,
  EVENTS_UPDATE_ITEM,
  EVENTS_REMOVE_ITEM,
  EVENTS_SET_CURRENT_MACHINE,
  EVENTS_MOVE_ITEM,
  EVENTS_APPEND_ITEMS,
  EVENTS_TOGGLE_ITEM_SELECTION,
  EVENTS_TOGGLE_FILTER_RESULTS_SELECTION,
  EVENTS_CLEAR_ITEM_SELECTION,
  EVENTS_SET_MULTI_SELECTION,
  EVENTS_STOP_UPDATING,
  EVENTS_POST_COMMENT,
  EVENTS_DESTROY_COMMENT,
  EVENTS_HIGHLIGHT_ITEMS,
  EVENTS_UPDATE_ORDER_STATUS,
  EVENTS_CLEAR_HIGHLIGHTS,
} from '../actions/events'

const initialState = {
  items: [],
  isLoading: true,
  isLoadingFailed: false,
  isMoveLoading: false,
  filter: {
    keyword: '',
    status: config.options.events.defaultStatus,
  },
  sorting: {
    column: 'orders.created_at',
    direction: 'asc',
  },
  settingsFetched: false,
  currentMachine: null,
  selectedItems: [],
  highlightedIds: [],
  highlightedFocusId: null,
  isMultiSelection: false,
}

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    // EVENTS_FETCH_REQUEST
    case EVENTS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // EVENTS_FETCH_SUCCESS
    case EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        isLoadingFailed: false,
        newItems: 0,
      }
    // EVENTS_FETCH_FAILURE
    case EVENTS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingFailed: true,
      }
    // EVENTS_MOVE_REQUEST
    case EVENTS_MOVE_REQUEST:
      return {
        ...state,
        isMoveLoading: true,
      }
    // EVENTS_MOVE_SUCCESS
    // EVENTS_MOVE_FAILURE
    case EVENTS_MOVE_SUCCESS:
    case EVENTS_MOVE_FAILURE:
      return {
        ...state,
        isMoveLoading: false,
      }
    // EVENTS_FILTER_SET_KEYWORD
    case EVENTS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
      }
    // EVENTS_FILTER_SET_STATUS
    case EVENTS_FILTER_SET_STATUS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.status,
        },
      }
    // EVENTS_CLEAR_FILTER
    case EVENTS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          status: initialState.filter.status,
        },
      }
    // EVENTS_SORT
    case EVENTS_SORT:
      return {
        ...state,
        sorting: action.sorting,
      }
    // EVENTS_SET_QUERY_SETTINGS
    case EVENTS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.status || initialState.filter.status,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // EVENTS_CLEAR
    case EVENTS_CLEAR:
      return {
        ...state,
        items: [],
      }
    // EVENTS_STOP_UPDATING
    case EVENTS_STOP_UPDATING:
      return {
        ...state,
        items: state.items.map((event) =>
          event.id !== action.id ? event : { ...event, updating: false }
        ),
      }
    // EVENTS_ADD_ITEM
    case EVENTS_ADD_ITEM:
      return {
        ...state,
        items: [...state.items, { ...action.data, updating: action.updating }],
      }
    // EVENTS_UPDATE_ITEM
    case EVENTS_UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((event) =>
          event.id !== action.data.id
            ? event
            : { ...action.data, updating: action.updating || false }
        ),
      }
    // EVENTS_REMOVE_ITEM
    case EVENTS_REMOVE_ITEM:
      return {
        ...state,
        items: state.items
          .filter(({ id }) => id !== action.id)
          .map((event, index) => ({ ...event, machine_position: index + 1 })),
      }
    // EVENTS_SET_CURRENT_MACHINE
    case EVENTS_SET_CURRENT_MACHINE:
      return {
        ...state,
        currentMachine: action.data,
      }
    // EVENTS_MOVE_ITEM
    case EVENTS_MOVE_ITEM:
      const itemIndex = state.items.findIndex((e) => e.id === action.id)
      const targetIndex =
        action.direction === 'up' ? itemIndex - 1 : itemIndex + 1
      return {
        ...state,
        items: state.items.map((event, index) => {
          if (index === itemIndex || index === targetIndex) {
            const newIndex = index === itemIndex ? targetIndex : itemIndex
            return {
              ...state.items[newIndex],
              updating: action.updating,
              machine_position: index + 1,
            }
          }

          return { ...event, machine_position: index + 1 }
        }),
      }
    // EVENTS_APPEND_ITEMS
    case EVENTS_APPEND_ITEMS:
      const appendTargetIndex =
        state.items.findIndex((e) => e.id === action.id) + 1
      // Get first block without selected items
      const firstBlock = state.items
        .slice(0, appendTargetIndex)
        .filter((e) => !action.items.includes(e.id))
      // Create array with moved objects
      const newBlock = state.items
        .filter((e) => action.items.includes(e.id))
        .sort((a, b) => a.machine_position - b.machine_position)
        .map((event) => ({ ...event, updating: action.updating }))
      // Get last block without selected items
      const lastBlock = state.items
        .slice(appendTargetIndex)
        .filter((e) => !action.items.includes(e.id))

      return {
        ...state,
        items: [...firstBlock, ...newBlock, ...lastBlock].map(
          (event, index) => ({ ...event, machine_position: index + 1 })
        ),
      }
    // EVENTS_TOGGLE_ITEM_SELECTION
    case EVENTS_TOGGLE_ITEM_SELECTION:
      const itemExists = state.selectedItems.includes(action.id)
      return {
        ...state,
        selectedItems: itemExists
          ? state.selectedItems.filter((id) => id !== action.id)
          : [...state.selectedItems, action.id],
      }
    // EVENTS_TOGGLE_FILTER_RESULTS_SELECTION
    case EVENTS_TOGGLE_FILTER_RESULTS_SELECTION:
      let selectedItems = [...state.selectedItems, ...state.highlightedIds]
      if (
        _.intersection(state.selectedItems, state.highlightedIds).length ===
        state.highlightedIds.length
      ) {
        selectedItems = _.difference(state.selectedItems, state.highlightedIds)
      }

      return {
        ...state,
        selectedItems,
      }
    // EVENTS_CLEAR_ITEM_SELECTION
    case EVENTS_CLEAR_ITEM_SELECTION:
      return {
        ...state,
        selectedItems: [],
      }
    // EVENTS_CLEAR_HIGHLIGHTS
    case EVENTS_CLEAR_HIGHLIGHTS:
      return {
        ...state,
        highlightedIds: [],
        highlightedFocusId: null,
      }
    // EVENTS_SET_MULTI_SELECTION
    case EVENTS_SET_MULTI_SELECTION:
      return {
        ...state,
        isMultiSelection: action.value,
      }
    // EVENTS_POST_COMMENT
    case EVENTS_POST_COMMENT:
      return {
        ...state,
        items: state.items.map((event) =>
          event.id !== action.data.commentable.id
            ? event
            : { ...event, comments: [...event.comments, action.data] }
        ),
      }
    // EVENTS_DESTROY_COMMENT
    case EVENTS_DESTROY_COMMENT:
      return {
        ...state,
        items: state.items.map((event) =>
          event.id !== action.data.commentable.id
            ? event
            : {
                ...event,
                comments: event.comments.filter(
                  ({ id }) => id !== action.data.id
                ),
              }
        ),
      }
    // EVENTS_HIGHLIGHT_ITEMS
    case EVENTS_HIGHLIGHT_ITEMS:
      return {
        ...state,
        highlightedIds: action.ids,
        highlightedFocusId: action.highlighted_id,
      }
    // EVENTS_UPDATE_ORDER_STATUS
    case EVENTS_UPDATE_ORDER_STATUS:
      return {
        ...state,
        items: state.items.map((event) =>
          event.order.id === action.data.id
            ? { ...event, order: action.data }
            : event
        ),
      }
    default:
      return state
  }
}

export default eventsReducer
