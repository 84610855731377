import config from 'config'
import {
  PAPER_TYPES_FETCH_REQUEST,
  PAPER_TYPES_FETCH_SUCCESS,
  PAPER_TYPES_FETCH_FAILURE,
  PAPER_TYPES_CREATE_REQUEST,
  PAPER_TYPES_CREATE_SUCCESS,
  PAPER_TYPES_CREATE_FAILURE,
  PAPER_TYPES_UPDATE_REQUEST,
  PAPER_TYPES_UPDATE_SUCCESS,
  PAPER_TYPES_UPDATE_FAILURE,
  PAPER_TYPES_DELETE_REQUEST,
  PAPER_TYPES_DELETE_SUCCESS,
  PAPER_TYPES_DELETE_FAILURE,
  PAPER_TYPES_TOGGLE_ADD_MODAL,
  PAPER_TYPES_TOGGLE_EDIT_MODAL,
  PAPER_TYPES_TOGGLE_DELETE_MODAL,
  PAPER_TYPES_FILTER_SET_KEYWORD,
  PAPER_TYPES_FILTER_SET_WEIGHT,
  PAPER_TYPES_CLEAR_FILTER,
  PAPER_TYPES_SORT,
  PAPER_TYPES_SET_ERRORS,
  PAPER_TYPES_SET_CURRENT_PAGE,
  PAPER_TYPES_SET_PAGINATION_PER_PAGE,
  PAPER_TYPES_SET_QUERY_SETTINGS,
  PAPER_TYPES_CLEAR,
  PAPER_TYPES_ADD_ITEM_SELECTION,
  PAPER_TYPES_REMOVE_ITEM_SELECTION,
  PAPER_TYPES_CLEAR_ITEM_SELECTIONS,
  PAPER_TYPES_TOGGLE_ITEM_SELECTIONS,
  PAPER_TYPES_TOGGLE_REPLACE_MODAL,
} from '../actions/paper_types'

const initialState = {
  items: [],
  selectedItems: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  isReplaceModalOpen: false,
  filter: {
    keyword: '',
    weight: {
      min: config.inputs.range.paper_types.min,
      max: config.inputs.range.paper_types.max,
    },
  },
  sorting: {
    column: 'paper_types.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
}

const paperTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    // PAPER_TYPES_FETCH_REQUEST
    case PAPER_TYPES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // PAPER_TYPES_FETCH_SUCCESS
    case PAPER_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // PAPER_TYPES_FETCH_FAILURE
    case PAPER_TYPES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // PAPER_TYPES_CREATE_REQUEST
    // PAPER_TYPES_UPDATE_REQUEST
    // PAPER_TYPES_DELETE_REQUEST
    case PAPER_TYPES_CREATE_REQUEST:
    case PAPER_TYPES_UPDATE_REQUEST:
    case PAPER_TYPES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // PAPER_TYPES_CREATE_SUCCESS
    // PAPER_TYPES_UPDATE_SUCCESS
    // PAPER_TYPES_DELETE_SUCCESS
    // PAPER_TYPES_CREATE_FAILURE
    // PAPER_TYPES_UPDATE_FAILURE
    // PAPER_TYPES_DELETE_FAILURE
    case PAPER_TYPES_CREATE_SUCCESS:
    case PAPER_TYPES_UPDATE_SUCCESS:
    case PAPER_TYPES_DELETE_SUCCESS:
    case PAPER_TYPES_CREATE_FAILURE:
    case PAPER_TYPES_UPDATE_FAILURE:
    case PAPER_TYPES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // PAPER_TYPES_TOGGLE_ADD_MODAL
    case PAPER_TYPES_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }
    // PAPER_TYPES_TOGGLE_EDIT_MODAL
    case PAPER_TYPES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // PAPER_TYPES_TOGGLE_DELETE_MODAL
    case PAPER_TYPES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // PAPER_TYPES_TOGGLE_REPLACE_MODAL
    case PAPER_TYPES_TOGGLE_REPLACE_MODAL:
      return {
        ...state,
        isReplaceModalOpen: action.state,
      }
    // PAPER_TYPES_FILTER_SET_KEYWORD
    case PAPER_TYPES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PAPER_TYPES_FILTER_SET_WEIGHT
    case PAPER_TYPES_FILTER_SET_WEIGHT:
      return {
        ...state,
        filter: {
          ...state.filter,
          weight: action.values,
        },
      }
    // PAPER_TYPES_CLEAR_FILTER
    case PAPER_TYPES_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          weight: initialState.filter.weight,
        },
      }
    // PAPER_TYPES_ADD_ITEM_SELECTION
    case PAPER_TYPES_ADD_ITEM_SELECTION:
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.id],
      }
    // PAPER_TYPES_REMOVE_ITEM_SELECTION
    case PAPER_TYPES_REMOVE_ITEM_SELECTION:
      return {
        ...state,
        selectedItems: state.selectedItems.filter((id) => id !== action.id),
      }
    // PAPER_TYPES_TOGGLE_ITEM_SELECTIONS
    case PAPER_TYPES_TOGGLE_ITEM_SELECTIONS:
      if (state.selectedItems.length === state.items.length) {
        return {
          ...state,
          selectedItems: initialState.selectedItems,
        }
      }

      return {
        ...state,
        selectedItems: state.items.map(({ id }) => id),
      }
    // PAPER_TYPES_CLEAR_ITEM_SELECTIONS
    case PAPER_TYPES_CLEAR_ITEM_SELECTIONS:
      return {
        ...state,
        selectedItems: initialState.selectedItems,
      }
    // PAPER_TYPES_SORT
    case PAPER_TYPES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PAPER_TYPES_SET_ERRORS
    case PAPER_TYPES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // PAPER_TYPES_SET_CURRENT_PAGE
    case PAPER_TYPES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // PAPER_TYPES_SET_PAGINATION_PER_PAGE
    case PAPER_TYPES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // PAPER_TYPES_SET_QUERY_SETTINGS
    case PAPER_TYPES_SET_QUERY_SETTINGS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        filter: {
          ...state.filter,
          weight: {
            min:
              parseInt(action.weight.min, 10) || initialState.filter.weight.min,
            max:
              parseInt(action.weight.max, 10) || initialState.filter.weight.max,
          },
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // PAPER_TYPES_CLEAR
    case PAPER_TYPES_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default paperTypesReducer
