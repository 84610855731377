import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const CONTRACTORS_FETCH_REQUEST = 'CONTRACTORS_FETCH_REQUEST'
export const CONTRACTORS_FETCH_SUCCESS = 'CONTRACTORS_FETCH_SUCCESS'
export const CONTRACTORS_FETCH_FAILURE = 'CONTRACTORS_FETCH_FAILURE'
// CREATE
export const CONTRACTORS_CREATE_REQUEST = 'CONTRACTORS_CREATE_REQUEST'
export const CONTRACTORS_CREATE_SUCCESS = 'CONTRACTORS_CREATE_SUCCESS'
export const CONTRACTORS_CREATE_FAILURE = 'CONTRACTORS_CREATE_FAILURE'
// UPDATE
export const CONTRACTORS_UPDATE_REQUEST = 'CONTRACTORS_UPDATE_REQUEST'
export const CONTRACTORS_UPDATE_SUCCESS = 'CONTRACTORS_UPDATE_SUCCESS'
export const CONTRACTORS_UPDATE_FAILURE = 'CONTRACTORS_UPDATE_FAILURE'
// DELETE
export const CONTRACTORS_DELETE_REQUEST = 'CONTRACTORS_DELETE_REQUEST'
export const CONTRACTORS_DELETE_SUCCESS = 'CONTRACTORS_DELETE_SUCCESS'
export const CONTRACTORS_DELETE_FAILURE = 'CONTRACTORS_DELETE_FAILURE'
// RESOURCES
export const CONTRACTORS_SET_AVAILABLE_MANAGERS =
  'CONTRACTORS_SET_AVAILABLE_MANAGERS'
export const CONTRACTORS_SET_AVAILABLE_LANGUAGES =
  'CONTRACTORS_SET_AVAILABLE_LANGUAGES'
// MODALS
export const CONTRACTORS_TOGGLE_ADD_MODAL = 'CONTRACTORS_TOGGLE_ADD_MODAL'
export const CONTRACTORS_TOGGLE_EDIT_MODAL = 'CONTRACTORS_TOGGLE_EDIT_MODAL'
export const CONTRACTORS_TOGGLE_DELETE_MODAL = 'CONTRACTORS_TOGGLE_DELETE_MODAL'
// FILTERS
export const CONTRACTORS_FILTER_SET_KEYWORD = 'CONTRACTORS_FILTER_SET_KEYWORD'
export const CONTRACTORS_SELECT_MANAGERS = 'CONTRACTORS_SELECT_MANAGERS'
export const CONTRACTORS_SELECT_LANGUAGE = 'CONTRACTORS_SELECT_LANGUAGE'
export const CONTRACTORS_SELECT_IS_ACTIVE = 'CONTRACTORS_SELECT_IS_ACTIVE'
export const CONTRACTORS_CLEAR_FILTER = 'CONTRACTORS_CLEAR_FILTER'
// Other
export const CONTRACTORS_SORT = 'CONTRACTORS_SORT'
export const CONTRACTORS_SET_ERRORS = 'CONTRACTORS_SET_ERRORS'
export const CONTRACTORS_SET_CURRENT_PAGE = 'CONTRACTORS_SET_CURRENT_PAGE'
export const CONTRACTORS_SET_PAGINATION_PER_PAGE =
  'CONTRACTORS_SET_PAGINATION_PER_PAGE'
export const CONTRACTORS_SET_QUERY_SETTINGS = 'CONTRACTORS_SET_QUERY_SETTINGS'
export const CONTRACTORS_CLEAR = 'CONTRACTORS_CLEAR'

/**
 * FETCH CONTRACTORS
 * * Get contractors list with pagination
 */
export const fetchContractors = () => async (dispatch, getState) => {
  // Get current state
  const {
    contractors: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.contractors.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          manager_ids: filter.managers,
          language_id: filter.language,
          is_active: filter.isActive,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: CONTRACTORS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: CONTRACTORS_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: CONTRACTORS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('contractors:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE CONTRACTOR
 * * Create contractor and display validation errors
 */
export const createContractor =
  ({ language_id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.contractors.root, {
          language_id: language_id || null,
          ...params,
        }),
      beforeRequest: () => dispatch({ type: CONTRACTORS_CREATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('contractors:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: CONTRACTORS_CREATE_SUCCESS })
        // Reload contractors list
        dispatch(fetchContractors())
        // Close modal and reset errors
        dispatch({ type: CONTRACTORS_TOGGLE_ADD_MODAL, state: false })
        dispatch({ type: CONTRACTORS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: CONTRACTORS_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: CONTRACTORS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('contractors:alerts.cannot_create'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: CONTRACTORS_TOGGLE_ADD_MODAL, state: false }),
    })

/**
 * UPDATE CONTRACTOR
 * * Update contractor and reload contractors list
 */
export const updateContractor =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.put(`${config.api.contractors.root}/${id}`, params),
      beforeRequest: () => dispatch({ type: CONTRACTORS_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('contractors:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: CONTRACTORS_UPDATE_SUCCESS })
        // Reload contractors list
        dispatch(fetchContractors())
        // Close modal and reset errors
        dispatch({ type: CONTRACTORS_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: CONTRACTORS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: CONTRACTORS_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: CONTRACTORS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('contractors:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: CONTRACTORS_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE CONTRACTOR
 * * Delete contractor and fetch contractors list
 */
export const deleteContractor = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.contractors.root}/${id}`),
    beforeRequest: () => dispatch({ type: CONTRACTORS_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('contractors:alerts.successfully_deleted'), 'success')
      // Disable loading
      dispatch({ type: CONTRACTORS_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: CONTRACTORS_TOGGLE_DELETE_MODAL, state: false })
      // Reload contractors list
      dispatch(fetchContractors())
    },
    onError: () => dispatch({ type: CONTRACTORS_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('contractors:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: CONTRACTORS_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * FETCH MANAGERS
 * * Fetch managers list
 */
export const fetchAvailableManagers = () => async (dispatch) =>
  defaultRequest({
    request: () => api.get(`${config.api.users.root}`),
    onSuccess: (response) =>
      dispatch({
        type: CONTRACTORS_SET_AVAILABLE_MANAGERS,
        items: response.data,
      }),
    onError: () =>
      displayAlert(i18n.t('managers:alerts.cannot_fetch'), 'error'),
  })

/**
 * FETCH LANGUAGES
 * * Fetch languages list
 */
export const fetchAvailableLanguages = () => async (dispatch) =>
  defaultRequest({
    request: () => api.get(`${config.api.languages.root}`),
    onSuccess: (response) =>
      dispatch({
        type: CONTRACTORS_SET_AVAILABLE_LANGUAGES,
        items: response.data,
      }),
    onError: () =>
      displayAlert(i18n.t('languages:alerts.cannot_fetch'), 'error'),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: CONTRACTORS_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchContractors())
}
