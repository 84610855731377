import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.span`
  display: flex;
  align-items: center;
`

const Value = styled.div``

const Separator = styled.div`
  opacity: 0.6;
  margin: 0 2px;
`

const CountdownRenderer = ({ hours, minutes, seconds }) => {
  const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds

  return (
    <Wrapper>
      <Value>{hours}</Value>
      <Separator>:</Separator>
      <Value>{paddedMinutes}</Value>
      <Separator>:</Separator>
      <Value>{paddedSeconds}</Value>
    </Wrapper>
  )
}

export default CountdownRenderer
