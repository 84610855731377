import config from 'config'
import {
  DELIVERY_NOTES_FETCH_REQUEST,
  DELIVERY_NOTES_FETCH_SUCCESS,
  DELIVERY_NOTES_FETCH_FAILURE,
  DELIVERY_NOTES_CREATE_REQUEST,
  DELIVERY_NOTES_CREATE_SUCCESS,
  DELIVERY_NOTES_CREATE_FAILURE,
  DELIVERY_NOTES_UPDATE_REQUEST,
  DELIVERY_NOTES_UPDATE_SUCCESS,
  DELIVERY_NOTES_UPDATE_FAILURE,
  DELIVERY_NOTES_FILTER_SET_KEYWORD,
  DELIVERY_NOTES_FILTER_SELECT_CONTRACTOR,
  DELIVERY_NOTES_FILTER_SELECT_SUBCONTRACTOR,
  DELIVERY_NOTES_FILTER_SELECT_AUTHOR,
  DELIVERY_NOTES_FILTER_SET_DAYS,
  DELIVERY_NOTES_FILTER_SET_ONLY_NOT_SHIPPED,
  DELIVERY_NOTES_FILTER_SET_SUBCONTRACTOR_MODE,
  DELIVERY_NOTES_FILTER_SET_ORDER,
  DELIVERY_NOTES_CLEAR_FILTER,
  DELIVERY_NOTES_SORT,
  DELIVERY_NOTES_SET_ERRORS,
  DELIVERY_NOTES_SET_CURRENT_PAGE,
  DELIVERY_NOTES_SET_PAGINATION_PER_PAGE,
  DELIVERY_NOTES_SET_QUERY_SETTINGS,
  DELIVERY_NOTES_CLEAR,
  DELIVERY_NOTES_CLEAR_REDIRECT,
} from '../actions/delivery_notes'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  filter: {
    keyword: '',
    contractor: null,
    subcontractor: null,
    author: null,
    days: 0,
    onlyNotShipped: false,
    subcontractorsMode: 0,
    order: null,
  },
  sorting: {
    column: 'delivery_notes.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
  redirectTo: null,
}

const deliveryNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    // DELIVERY_NOTES_FETCH_REQUEST
    case DELIVERY_NOTES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // DELIVERY_NOTES_FETCH_SUCCESS
    case DELIVERY_NOTES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // DELIVERY_NOTES_FETCH_FAILURE
    case DELIVERY_NOTES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // DELIVERY_NOTES_CREATE_REQUEST
    // DELIVERY_NOTES_UPDATE_REQUEST
    case DELIVERY_NOTES_CREATE_REQUEST:
    case DELIVERY_NOTES_UPDATE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // DELIVERY_NOTES_CREATE_SUCCESS
    // DELIVERY_NOTES_UPDATE_SUCCESS
    case DELIVERY_NOTES_CREATE_SUCCESS:
    case DELIVERY_NOTES_UPDATE_SUCCESS:
      return {
        ...state,
        isFormLoading: false,
        redirectTo: action.id,
      }

    // DELIVERY_NOTES_CREATE_FAILURE
    // DELIVERY_NOTES_UPDATE_FAILURE
    case DELIVERY_NOTES_CREATE_FAILURE:
    case DELIVERY_NOTES_UPDATE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // DELIVERY_NOTES_FILTER_SET_KEYWORD
    case DELIVERY_NOTES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // DELIVERY_NOTES_FILTER_SELECT_CONTRACTOR
    case DELIVERY_NOTES_FILTER_SELECT_CONTRACTOR:
      return {
        ...state,
        filter: {
          ...state.filter,
          contractor: action.contractor,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // DELIVERY_NOTES_FILTER_SELECT_SUBCONTRACTOR
    case DELIVERY_NOTES_FILTER_SELECT_SUBCONTRACTOR:
      return {
        ...state,
        filter: {
          ...state.filter,
          subcontractor: action.subcontractor,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // DELIVERY_NOTES_FILTER_SELECT_AUTHOR
    case DELIVERY_NOTES_FILTER_SELECT_AUTHOR:
      return {
        ...state,
        filter: {
          ...state.filter,
          author: action.author,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // DELIVERY_NOTES_FILTER_SET_DAYS
    case DELIVERY_NOTES_FILTER_SET_DAYS:
      return {
        ...state,
        filter: {
          ...state.filter,
          days: action.days,
        },
      }
    // DELIVERY_NOTES_FILTER_SET_ONLY_NOT_SHIPPED
    case DELIVERY_NOTES_FILTER_SET_ONLY_NOT_SHIPPED:
      return {
        ...state,
        filter: {
          ...state.filter,
          onlyNotShipped: action.state,
        },
      }
    // DELIVERY_NOTES_FILTER_SET_SUBCONTRACTOR_MODE
    case DELIVERY_NOTES_FILTER_SET_SUBCONTRACTOR_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          subcontractorMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // DELIVERY_NOTES_FILTER_SET_ORDER
    case DELIVERY_NOTES_FILTER_SET_ORDER:
      return {
        ...state,
        filter: {
          ...state.filter,
          order: action.order,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // DELIVERY_NOTES_CLEAR_FILTER
    case DELIVERY_NOTES_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          ...initialState.filter,
          order: state.order,
        },
      }
    // DELIVERY_NOTES_SORT
    case DELIVERY_NOTES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // DELIVERY_NOTES_SET_ERRORS
    case DELIVERY_NOTES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // DELIVERY_NOTES_SET_CURRENT_PAGE
    case DELIVERY_NOTES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // DELIVERY_NOTES_SET_PAGINATION_PER_PAGE
    case DELIVERY_NOTES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // DELIVERY_NOTES_SET_QUERY_SETTINGS
    case DELIVERY_NOTES_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          contractor: parseInt(action.contractor, 10) || null,
          subcontractor: parseInt(action.subcontractor, 10) || null,
          author: parseInt(action.author, 10) || null,
          days: parseInt(action.days, 10) || initialState.filter.days,
          onlyNotShipped: action.onlyNotShipped === 'true',
          subcontractorMode: parseInt(action.subcontractorMode, 10) || 0,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // DELIVERY_NOTES_CLEAR
    case DELIVERY_NOTES_CLEAR:
      return {
        ...state,
        items: [],
      }
    // DELIVERY_NOTES_CLEAR_REDIRECT
    case DELIVERY_NOTES_CLEAR_REDIRECT:
      return {
        ...state,
        redirectTo: null,
      }
    default:
      return state
  }
}

export default deliveryNotesReducer
