import _ from 'lodash'
import config from 'config'
import store from 'store'

export const getSetting = (name) => {
  const {
    session: { currentUser },
  } = store.getState()
  if (!currentUser) return null

  const setting = _.get(currentUser.settings, `custom.${name}`)
  return setting !== undefined ? setting : _.get(config.settings, name)
}

export default getSetting
