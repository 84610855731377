import config from 'config'
import {
  MACHINES_FETCH_REQUEST,
  MACHINES_FETCH_SUCCESS,
  MACHINES_FETCH_FAILURE,
  MACHINES_CREATE_REQUEST,
  MACHINES_CREATE_SUCCESS,
  MACHINES_CREATE_FAILURE,
  MACHINES_UPDATE_REQUEST,
  MACHINES_UPDATE_SUCCESS,
  MACHINES_UPDATE_FAILURE,
  MACHINES_DELETE_REQUEST,
  MACHINES_DELETE_SUCCESS,
  MACHINES_DELETE_FAILURE,
  MACHINES_TOGGLE_ADD_MODAL,
  MACHINES_TOGGLE_EDIT_MODAL,
  MACHINES_TOGGLE_DELETE_MODAL,
  MACHINES_FILTER_SET_KEYWORD,
  MACHINES_FILTER_SELECT_TEMPLATE,
  MACHINES_CLEAR_FILTER,
  MACHINES_SORT,
  MACHINES_SET_ERRORS,
  MACHINES_SET_CURRENT_PAGE,
  MACHINES_SET_PAGINATION_PER_PAGE,
  MACHINES_SET_QUERY_SETTINGS,
  MACHINES_CLEAR,
} from '../actions/machines'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  filter: {
    keyword: '',
    template: null,
  },
  sorting: {
    column: 'machines.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
}

const machinesReducer = (state = initialState, action) => {
  switch (action.type) {
    // MACHINES_FETCH_REQUEST
    case MACHINES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // MACHINES_FETCH_SUCCESS
    case MACHINES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // MACHINES_FETCH_FAILURE
    case MACHINES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // MACHINES_CREATE_REQUEST
    // MACHINES_UPDATE_REQUEST
    // MACHINES_DELETE_REQUEST
    case MACHINES_CREATE_REQUEST:
    case MACHINES_UPDATE_REQUEST:
    case MACHINES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // MACHINES_CREATE_SUCCESS
    // MACHINES_UPDATE_SUCCESS
    // MACHINES_DELETE_SUCCESS
    // MACHINES_CREATE_FAILURE
    // MACHINES_UPDATE_FAILURE
    // MACHINES_DELETE_FAILURE
    case MACHINES_CREATE_SUCCESS:
    case MACHINES_UPDATE_SUCCESS:
    case MACHINES_DELETE_SUCCESS:
    case MACHINES_CREATE_FAILURE:
    case MACHINES_UPDATE_FAILURE:
    case MACHINES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // MACHINES_TOGGLE_ADD_MODAL
    case MACHINES_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }
    // MACHINES_TOGGLE_EDIT_MODAL
    case MACHINES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // MACHINES_TOGGLE_DELETE_MODAL
    case MACHINES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // MACHINES_FILTER_SET_KEYWORD
    case MACHINES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // MACHINES_FILTER_SELECT_TEMPLATE
    case MACHINES_FILTER_SELECT_TEMPLATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          template: action.template,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // MACHINES_CLEAR_FILTER
    case MACHINES_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          template: null,
        },
      }
    // MACHINES_SORT
    case MACHINES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // MACHINES_SET_ERRORS
    case MACHINES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // MACHINES_SET_CURRENT_PAGE
    case MACHINES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // MACHINES_SET_PAGINATION_PER_PAGE
    case MACHINES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // MACHINES_SET_QUERY_SETTINGS
    case MACHINES_SET_QUERY_SETTINGS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        filter: {
          ...state.filter,
          template: action.template,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // MACHINES_CLEAR
    case MACHINES_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default machinesReducer
