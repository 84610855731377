import React, { useRef } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import classNames from 'classnames'

/* ---------------------------------- Hooks --------------------------------- */
import { useTranslation } from 'react-i18next'

/* ------------------------------- Components ------------------------------- */
import { UncontrolledTooltip } from 'reactstrap'
import StatusIcon from './StatusIcon'

/* -------------------------------------------------------------------------- */
/*                           Order Status Component                           */
/* -------------------------------------------------------------------------- */
const OrderStatus = ({ status, compact = false, small = false, ...rest }) => {
  const tooltipRef = useRef()
  const [t] = useTranslation()

  /* --------------------------------- Render --------------------------------- */
  return (
    <>
      <span ref={tooltipRef}>
        <button
          className={classNames(`order__status order__status--${status}`, {
            'order__status--compact': compact,
            'order__status--small': small,
          })}
          {...rest}
          // disabled={disabled}
        >
          {!compact && t(`orders:values.status.${status}`)}
          {compact && <StatusIcon status={status} />}
        </button>
      </span>
      {compact && (
        <UncontrolledTooltip
          placement="bottom"
          target={tooltipRef}
          boundariesElement="table"
        >
          <small>{t(`orders:values.status.${status}`)}</small>
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default OrderStatus
