import config from 'config'

import {
  EMPLOYEES_FETCH_REQUEST,
  EMPLOYEES_FETCH_SUCCESS,
  EMPLOYEES_FETCH_FAILURE,
  EMPLOYEES_UPDATE_REQUEST,
  EMPLOYEES_UPDATE_SUCCESS,
  EMPLOYEES_UPDATE_FAILURE,
  EMPLOYEES_TOGGLE_EDIT_MODAL,
  EMPLOYEES_FILTER_SET_KEYWORD,
  EMPLOYEES_SELECT_ROLE,
  EMPLOYEES_FILTER_SELECT_MACHINE,
  EMPLOYEES_CLEAR_FILTER,
  EMPLOYEES_SORT,
  EMPLOYEES_SET_ERRORS,
  EMPLOYEES_SET_CURRENT_PAGE,
  EMPLOYEES_SET_PAGINATION_PER_PAGE,
  EMPLOYEES_SET_QUERY_SETTINGS,
  EMPLOYEES_CLEAR,
  EMPLOYEES_PRODUCTIVITY_SET_MACHINES_LIST,
  EMPLOYEES_PRODUCTIVITY_SET_EMPLOYEE,
} from '../actions/employees'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isEditModalOpen: false,
  filter: {
    keyword: '',
    role: null,
    machine: null,
  },
  sorting: {
    column: 'employees.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
  productivity: {
    employee: null,
    selectedMachines: [],
  },
}

const employeesReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ------------------------- EMPLOYEES_FETCH_REQUEST ------------------------ */
    case EMPLOYEES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    /* ------------------------- EMPLOYEES_FETCH_SUCCESS ------------------------ */
    case EMPLOYEES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    /* ------------------------- EMPLOYEES_FETCH_FAILURE ------------------------ */
    case EMPLOYEES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    /* ------------------------ EMPLOYEES_UPDATE_REQUEST ------------------------ */
    case EMPLOYEES_UPDATE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    /* ------------------------ EMPLOYEES_UPDATE_SUCCESS ------------------------ */
    /* ------------------------ EMPLOYEES_UPDATE_FAILURE ------------------------ */
    case EMPLOYEES_UPDATE_SUCCESS:
    case EMPLOYEES_UPDATE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    /* ----------------------- EMPLOYEES_TOGGLE_EDIT_MODAL ---------------------- */
    case EMPLOYEES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    /* ---------------------- EMPLOYEES_FILTER_SET_KEYWORD ---------------------- */
    case EMPLOYEES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    /* -------------------------- EMPLOYEES_SELECT_ROLE ------------------------- */
    case EMPLOYEES_SELECT_ROLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          role: action.role,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    /* --------------------- EMPLOYEES_FILTER_SELECT_MACHINE -------------------- */
    case EMPLOYEES_FILTER_SELECT_MACHINE:
      return {
        ...state,
        filter: {
          ...state.filter,
          machine: action.machine,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    /* ------------------------- EMPLOYEES_CLEAR_FILTER ------------------------- */
    case EMPLOYEES_CLEAR_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      }
    /* ----------------------------- EMPLOYEES_SORT ----------------------------- */
    case EMPLOYEES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    /* -------------------------- EMPLOYEES_SET_ERRORS -------------------------- */
    case EMPLOYEES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    /* ----------------------- EMPLOYEES_SET_CURRENT_PAGE ----------------------- */
    case EMPLOYEES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    /* -------------------- EMPLOYEES_SET_PAGINATION_PER_PAGE ------------------- */
    case EMPLOYEES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    /* ---------------------- EMPLOYEES_SET_QUERY_SETTINGS ---------------------- */
    case EMPLOYEES_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          role: parseInt(action.role, 10) || null,
          machine: parseInt(action.machine, 10) || null,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    /* ----------------------------- EMPLOYEES_CLEAR ---------------------------- */
    case EMPLOYEES_CLEAR:
      return {
        ...state,
        items: [],
      }
    /* ---------------- EMPLOYEES_PRODUCTIVITY_SET_MACHINES_LIST ---------------- */
    case EMPLOYEES_PRODUCTIVITY_SET_MACHINES_LIST:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          selectedMachines: action.value,
        },
      }
    /* ------------------- EMPLOYEES_PRODUCTIVITY_SET_EMPLOYEE ------------------ */
    case EMPLOYEES_PRODUCTIVITY_SET_EMPLOYEE:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          employee: action.value,
        },
      }
    default:
      return state
  }
}

export default employeesReducer
