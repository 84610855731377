import config from 'config'

import {
  MATERIALS_FETCH_REQUEST,
  MATERIALS_FETCH_SUCCESS,
  MATERIALS_FETCH_FAILURE,
  MATERIALS_UPDATE_REQUEST,
  MATERIALS_UPDATE_SUCCESS,
  MATERIALS_UPDATE_FAILURE,
  MATERIALS_SPLIT_REQUEST,
  MATERIALS_SPLIT_SUCCESS,
  MATERIALS_SPLIT_FAILURE,
  MATERIALS_TOGGLE_EDIT_MODAL,
  MATERIALS_TOGGLE_SPLIT_MODAL,
  MATERIALS_FILTER_SET_DATE,
  MATERIALS_FILTER_SET_KEYWORD,
  MATERIALS_FILTER_SELECT_PAPER_TYPE,
  MATERIALS_FILTER_SELECT_PAPER_SIZE,
  MATERIALS_FILTER_SET_STATUS,
  MATERIALS_CLEAR_FILTER,
  MATERIALS_ADD_ITEM_SELECTION,
  MATERIALS_REMOVE_ITEM_SELECTION,
  MATERIALS_TOGGLE_ITEM_SELECTIONS,
  MATERIALS_CLEAR_ITEM_SELECTIONS,
  MATERIALS_SORT,
  MATERIALS_SET_ERRORS,
  MATERIALS_SET_QUERY_SETTINGS,
  MATERIALS_CLEAR,
} from '../actions/materials'

const initialState = {
  items: [],
  selectedItems: [],
  selectedQuantity: 0,
  selectedQuantityUnit: '',
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isEditModalOpen: false,
  isSplitModalOpen: false,
  filter: {
    date: '',
    keyword: '',
    paper_type: null,
    paper_size: null,
    status: config.options.materials.defaultStatus,
  },
  sorting: {
    column: 'materials.id',
    direction: 'desc',
  },
  editId: null,
  errors: [],
  settingsFetched: false,
  canMergeItems: false,
}

const materialsReducer = (state = initialState, action) => {
  switch (action.type) {
    // MATERIALS_FETCH_REQUEST
    case MATERIALS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // MATERIALS_FETCH_SUCCESS
    case MATERIALS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        loadingFailed: false,
      }
    // MATERIALS_FETCH_FAILURE
    case MATERIALS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // MATERIALS_UPDATE_REQUEST
    // MATERIALS_SPLIT_REQUEST
    case MATERIALS_UPDATE_REQUEST:
    case MATERIALS_SPLIT_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // MATERIALS_UPDATE_SUCCESS
    // MATERIALS_UPDATE_FAILURE
    // MATERIALS_SPLIT_SUCCESS
    // MATERIALS_SPLIT_FAILURE
    case MATERIALS_UPDATE_SUCCESS:
    case MATERIALS_UPDATE_FAILURE:
    case MATERIALS_SPLIT_SUCCESS:
    case MATERIALS_SPLIT_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // MATERIALS_TOGGLE_EDIT_MODAL
    case MATERIALS_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // MATERIALS_TOGGLE_SPLIT_MODAL
    case MATERIALS_TOGGLE_SPLIT_MODAL:
      return {
        ...state,
        isSplitModalOpen: action.state,
        editId: action.id,
      }
    // MATERIALS_FILTER_SET_DATE
    case MATERIALS_FILTER_SET_DATE:
      return {
        ...state,
        filter: {
          ...state.filter,
          date: action.date,
        },
      }
    // MATERIALS_FILTER_SET_KEYWORD
    case MATERIALS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
      }
    // MATERIALS_FILTER_SELECT_PAPER_TYPE:
    case MATERIALS_FILTER_SELECT_PAPER_TYPE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_type: action.paper_type,
        },
      }
    // MATERIALS_FILTER_SELECT_PAPER_SIZE:
    case MATERIALS_FILTER_SELECT_PAPER_SIZE:
      return {
        ...state,
        filter: {
          ...state.filter,
          paper_size: action.paper_size,
        },
      }
    // MATERIALS_FILTER_SET_STATUS:
    case MATERIALS_FILTER_SET_STATUS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.status,
        },
      }
    // MATERIALS_CLEAR_FILTER
    case MATERIALS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          date: initialState.filter.date,
          keyword: initialState.filter.keyword,
          paper_type: initialState.filter.paper_type,
          paper_size: initialState.filter.paper_size,
          status: initialState.filter.status,
        },
      }
    // MATERIALS_ADD_ITEM_SELECTION
    case MATERIALS_ADD_ITEM_SELECTION:
      const newAddSelectedItems = [...state.selectedItems, action.id]
      const newAddItems = state.items.filter(({ id }) =>
        newAddSelectedItems.includes(id)
      )
      return {
        ...state,
        selectedItems: newAddSelectedItems,
        selectedQuantity: state.selectedQuantity + action.quantity,
        selectedQuantityUnit: action.unit,
        canMergeItems:
          state.selectedItems.length > 0 &&
          newAddItems.every(
            ({ materialable }) =>
              materialable.id === newAddItems[0].materialable.id
          ),
      }
    // MATERIALS_REMOVE_ITEM_SELECTION
    case MATERIALS_REMOVE_ITEM_SELECTION:
      const newRemoveSelectedItems = state.selectedItems.filter(
        (id) => id !== action.id
      )
      const newRemoveItems = state.items.filter(({ id }) =>
        newRemoveSelectedItems.includes(id)
      )
      return {
        ...state,
        selectedItems: newRemoveSelectedItems,
        selectedQuantity: state.selectedQuantity - action.quantity,
        canMergeItems:
          state.selectedItems.length > 2 &&
          newRemoveItems.every(
            ({ materialable }) =>
              materialable.id === newRemoveItems[0].materialable.id
          ),
      }
    // MATERIALS_TOGGLE_ITEM_SELECTIONS
    case MATERIALS_TOGGLE_ITEM_SELECTIONS:
      if (state.selectedItems.length === state.items.length) {
        return {
          ...state,
          selectedItems: initialState.selectedItems,
          selectedQuantity: initialState.selectedQuantity,
          canMergeItems: false,
        }
      }

      return {
        ...state,
        selectedItems: state.items.map(({ id }) => id),
        selectedQuantity: state.items.reduce(
          (acc, { quantity_value }) => acc + parseFloat(quantity_value),
          0
        ),
        selectedQuantityUnit: state.items[0]?.quantity_unit || '',
        canMergeItems:
          state.items.length > 1 &&
          state.items.every(
            ({ materialable }) =>
              materialable.id === state.items[0].materialable.id
          ),
      }
    // MATERIALS_CLEAR_ITEM_SELECTIONS
    case MATERIALS_CLEAR_ITEM_SELECTIONS:
      return {
        ...state,
        selectedItems: initialState.selectedItems,
        selectedQuantity: initialState.selectedQuantity,
      }
    // MATERIALS_SORT
    case MATERIALS_SORT:
      return {
        ...state,
        sorting: action.sorting,
      }
    // MATERIALS_SET_ERRORS
    case MATERIALS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // MATERIALS_SET_QUERY_SETTINGS
    case MATERIALS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          // paper_type: parseInt(action.paper_type) || null,
          // base_paper_size: parseInt(action.base_paper_size) || null,
          // target_paper_size: parseInt(action.target_paper_size) || null,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // MATERIALS_CLEAR
    case MATERIALS_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default materialsReducer
