import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const SUPPLIERS_FETCH_REQUEST = 'SUPPLIERS_FETCH_REQUEST'
export const SUPPLIERS_FETCH_SUCCESS = 'SUPPLIERS_FETCH_SUCCESS'
export const SUPPLIERS_FETCH_FAILURE = 'SUPPLIERS_FETCH_FAILURE'
// CREATE
export const SUPPLIERS_CREATE_REQUEST = 'SUPPLIERS_CREATE_REQUEST'
export const SUPPLIERS_CREATE_SUCCESS = 'SUPPLIERS_CREATE_SUCCESS'
export const SUPPLIERS_CREATE_FAILURE = 'SUPPLIERS_CREATE_FAILURE'
// UPDATE
export const SUPPLIERS_UPDATE_REQUEST = 'SUPPLIERS_UPDATE_REQUEST'
export const SUPPLIERS_UPDATE_SUCCESS = 'SUPPLIERS_UPDATE_SUCCESS'
export const SUPPLIERS_UPDATE_FAILURE = 'SUPPLIERS_UPDATE_FAILURE'
// DELETE
export const SUPPLIERS_DELETE_REQUEST = 'SUPPLIERS_DELETE_REQUEST'
export const SUPPLIERS_DELETE_SUCCESS = 'SUPPLIERS_DELETE_SUCCESS'
export const SUPPLIERS_DELETE_FAILURE = 'SUPPLIERS_DELETE_FAILURE'
// MODALS
export const SUPPLIERS_TOGGLE_ADD_MODAL = 'SUPPLIERS_TOGGLE_ADD_MODAL'
export const SUPPLIERS_TOGGLE_EDIT_MODAL = 'SUPPLIERS_TOGGLE_EDIT_MODAL'
export const SUPPLIERS_TOGGLE_DELETE_MODAL = 'SUPPLIERS_TOGGLE_DELETE_MODAL'
// FILTERS
export const SUPPLIERS_FILTER_SET_KEYWORD = 'SUPPLIERS_FILTER_SET_KEYWORD'
export const SUPPLIERS_CLEAR_FILTER = 'SUPPLIERS_CLEAR_FILTER'
// Other
export const SUPPLIERS_SORT = 'SUPPLIERS_SORT'
export const SUPPLIERS_SET_ERRORS = 'SUPPLIERS_SET_ERRORS'
export const SUPPLIERS_SET_CURRENT_PAGE = 'SUPPLIERS_SET_CURRENT_PAGE'
export const SUPPLIERS_SET_PAGINATION_PER_PAGE =
  'SUPPLIERS_SET_PAGINATION_PER_PAGE'
export const SUPPLIERS_SET_QUERY_SETTINGS = 'SUPPLIERS_SET_QUERY_SETTINGS'
export const SUPPLIERS_CLEAR = 'SUPPLIERS_CLEAR'

/**
 * FETCH SUPPLIERS
 * * Get suppliers list with pagination
 */
export const fetchSuppliers = () => async (dispatch, getState) => {
  // Get current state
  const {
    suppliers: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(config.api.suppliers.root, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: SUPPLIERS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: SUPPLIERS_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: SUPPLIERS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('suppliers:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE SUPPLIER
 * * Create supplier and display validation errors
 */
export const createSupplier = (params) => async (dispatch) =>
  defaultRequest({
    request: () => api.post(config.api.suppliers.root, params),
    beforeRequest: () => dispatch({ type: SUPPLIERS_CREATE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('suppliers:alerts.successfully_created'), 'success')
      // Disable loading
      dispatch({ type: SUPPLIERS_CREATE_SUCCESS })
      // Reload suppliers list
      dispatch(fetchSuppliers())
      // Close modal and reset errors
      dispatch({ type: SUPPLIERS_TOGGLE_ADD_MODAL, state: false })
      dispatch({ type: SUPPLIERS_SET_ERRORS, errors: [] })
    },
    onError: () => dispatch({ type: SUPPLIERS_CREATE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnprocessableError: (data) =>
      dispatch({ type: SUPPLIERS_SET_ERRORS, errors: data }),
    onUnknownError: () =>
      displayAlert(i18n.t('suppliers:alerts.cannot_create'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: SUPPLIERS_TOGGLE_ADD_MODAL, state: false }),
  })

/**
 * UPDATE SUPPLIER
 * * Update supplier and reload suppliers list
 */
export const updateSupplier =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.put(`${config.api.suppliers.root}/${id}`, params),
      beforeRequest: () => dispatch({ type: SUPPLIERS_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('suppliers:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: SUPPLIERS_UPDATE_SUCCESS })
        // Reload suppliers list
        dispatch(fetchSuppliers())
        // Close modal and reset errors
        dispatch({ type: SUPPLIERS_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: SUPPLIERS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: SUPPLIERS_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: SUPPLIERS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('suppliers:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: SUPPLIERS_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE SUPPLIER
 * * Delete supplier and fetch suppliers list
 */
export const deleteSupplier = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.suppliers.root}/${id}`),
    beforeRequest: () => dispatch({ type: SUPPLIERS_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('suppliers:alerts.successfully_deleted'), 'success')
      // Disable loading
      dispatch({ type: SUPPLIERS_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: SUPPLIERS_TOGGLE_DELETE_MODAL, state: false })
      // Reload suppliers list
      dispatch(fetchSuppliers())
    },
    onError: () => dispatch({ type: SUPPLIERS_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('suppliers:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: SUPPLIERS_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: SUPPLIERS_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchSuppliers())
}
