import config from 'config'
import {
  ADDRESSES_FETCH_REQUEST,
  ADDRESSES_FETCH_SUCCESS,
  ADDRESSES_FETCH_FAILURE,
  ADDRESSES_CREATE_REQUEST,
  ADDRESSES_CREATE_SUCCESS,
  ADDRESSES_CREATE_FAILURE,
  ADDRESSES_UPDATE_REQUEST,
  ADDRESSES_UPDATE_SUCCESS,
  ADDRESSES_UPDATE_FAILURE,
  ADDRESSES_DELETE_REQUEST,
  ADDRESSES_DELETE_SUCCESS,
  ADDRESSES_DELETE_FAILURE,
  ADDRESSES_TOGGLE_EDIT_MODAL,
  ADDRESSES_TOGGLE_DELETE_MODAL,
  ADDRESSES_FILTER_SET_KEYWORD,
  ADDRESSES_SELECT_CITY,
  ADDRESSES_SELECT_COUNTRY,
  ADDRESSES_SET_WITH_CONTRACTORS,
  ADDRESSES_CLEAR_FILTER,
  ADDRESSES_SET_AVAILABLE_CITIES,
  ADDRESSES_SET_AVAILABLE_COUNTRIES,
  ADDRESSES_SORT,
  ADDRESSES_SET_ERRORS,
  ADDRESSES_SET_CURRENT_PAGE,
  ADDRESSES_SET_PAGINATION_PER_PAGE,
  ADDRESSES_SET_QUERY_SETTINGS,
  ADDRESSES_CLEAR,
  ADDRESSES_ADD_AVAILABLE_CITY,
} from '../actions/addresses'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  filter: {
    keyword: '',
    city: null,
    country: null,
    withContractors: 0,
  },
  sorting: {
    column: 'addresses.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  availableCities: [],
  availableCountries: [],
  errors: [],
  settingsFetched: false,
}

const addressesReducer = (state = initialState, action) => {
  switch (action.type) {
    // ADDRESSES_FETCH_REQUEST
    case ADDRESSES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // ADDRESSES_FETCH_SUCCESS
    case ADDRESSES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // ADDRESSES_FETCH_FAILURE
    case ADDRESSES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // ADDRESSES_CREATE_REQUEST
    // ADDRESSES_UPDATE_REQUEST
    // ADDRESSES_DELETE_REQUEST
    case ADDRESSES_CREATE_REQUEST:
    case ADDRESSES_UPDATE_REQUEST:
    case ADDRESSES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // ADDRESSES_CREATE_SUCCESS
    // ADDRESSES_UPDATE_SUCCESS
    // ADDRESSES_DELETE_SUCCESS
    // ADDRESSES_CREATE_FAILURE
    // ADDRESSES_UPDATE_FAILURE
    // ADDRESSES_DELETE_FAILURE
    case ADDRESSES_CREATE_SUCCESS:
    case ADDRESSES_UPDATE_SUCCESS:
    case ADDRESSES_DELETE_SUCCESS:
    case ADDRESSES_CREATE_FAILURE:
    case ADDRESSES_UPDATE_FAILURE:
    case ADDRESSES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // ADDRESSES_TOGGLE_EDIT_MODAL
    case ADDRESSES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // ADDRESSES_TOGGLE_DELETE_MODAL
    case ADDRESSES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // ADDRESSES_FILTER_SET_KEYWORD
    case ADDRESSES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // ADDRESSES_SELECT_CITY
    case ADDRESSES_SELECT_CITY:
      return {
        ...state,
        filter: {
          ...state.filter,
          city: action.city,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // ADDRESSES_SELECT_COUNTRY
    case ADDRESSES_SELECT_COUNTRY:
      return {
        ...state,
        filter: {
          ...state.filter,
          country: action.country,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // ADDRESSES_SET_WITH_CONTRACTORS
    case ADDRESSES_SET_WITH_CONTRACTORS:
      return {
        ...state,
        filter: {
          ...state.filter,
          withContractors: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // ADDRESSES_CLEAR_FILTER
    case ADDRESSES_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          city: null,
          country: null,
          withContractors: 0,
        },
      }
    // ADDRESSES_SET_AVAILABLE_CITIES
    case ADDRESSES_SET_AVAILABLE_CITIES:
      return {
        ...state,
        availableCities: action.items,
      }
    // ADDRESSES_SET_AVAILABLE_COUNTRIES
    case ADDRESSES_SET_AVAILABLE_COUNTRIES:
      return {
        ...state,
        availableCountries: action.items,
      }
    // ADDRESSES_SORT
    case ADDRESSES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // ADDRESSES_SET_ERRORS
    case ADDRESSES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // ADDRESSES_SET_CURRENT_PAGE
    case ADDRESSES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // ADDRESSES_SET_PAGINATION_PER_PAGE
    case ADDRESSES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // ADDRESSES_SET_QUERY_SETTINGS
    case ADDRESSES_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          city: parseInt(action.city, 10) || null,
          country: parseInt(action.country, 10) || null,
          withContractors: parseInt(action.contractors_mode, 10) || 0,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // ADDRESSES_CLEAR
    case ADDRESSES_CLEAR:
      return {
        ...state,
        items: [],
      }
    // ADDRESSES_ADD_AVAILABLE_CITY
    case ADDRESSES_ADD_AVAILABLE_CITY:
      return {
        ...state,
        availableCities: [...state.availableCities, action.item],
      }
    default:
      return state
  }
}

export default addressesReducer
