import config from 'config'
import {
  PROCESS_TYPES_FETCH_REQUEST,
  PROCESS_TYPES_FETCH_SUCCESS,
  PROCESS_TYPES_FETCH_FAILURE,
  PROCESS_TYPES_CREATE_REQUEST,
  PROCESS_TYPES_CREATE_SUCCESS,
  PROCESS_TYPES_CREATE_FAILURE,
  PROCESS_TYPES_UPDATE_REQUEST,
  PROCESS_TYPES_UPDATE_SUCCESS,
  PROCESS_TYPES_UPDATE_FAILURE,
  PROCESS_TYPES_DELETE_REQUEST,
  PROCESS_TYPES_DELETE_SUCCESS,
  PROCESS_TYPES_DELETE_FAILURE,
  PROCESS_TYPES_MOVE_REQUEST,
  PROCESS_TYPES_MOVE_SUCCESS,
  PROCESS_TYPES_MOVE_FAILURE,
  PROCESS_TYPES_TOGGLE_ADD_MODAL,
  PROCESS_TYPES_TOGGLE_EDIT_MODAL,
  PROCESS_TYPES_TOGGLE_DELETE_MODAL,
  PROCESS_TYPES_FILTER_SET_KEYWORD,
  PROCESS_TYPES_FILTER_SELECT_MACHINE,
  PROCESS_TYPES_CLEAR_FILTER,
  PROCESS_TYPES_SET_ERRORS,
  PROCESS_TYPES_SET_CURRENT_PAGE,
  PROCESS_TYPES_SET_PAGINATION_PER_PAGE,
  PROCESS_TYPES_SET_QUERY_SETTINGS,
  PROCESS_TYPES_CLEAR,
  PROCESS_TYPES_SET_AVAILABLE_MACHINES,
  PROCESS_TYPES_FILTER_SET_MACHINES_MODE,
  PROCESS_TYPES_FILTER_SET_DEFAULT_MACHINE_MODE,
} from '../actions/process_types'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  isMoveLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  filter: {
    keyword: '',
    machine: null,
    machinesMode: 0,
    defaultMachineMode: 0,
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
  availableMachines: [],
}

const processTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    // PROCESS_TYPES_FETCH_REQUEST
    case PROCESS_TYPES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // PROCESS_TYPES_FETCH_SUCCESS
    case PROCESS_TYPES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // PROCESS_TYPES_FETCH_FAILURE
    case PROCESS_TYPES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // PROCESS_TYPES_CREATE_REQUEST
    // PROCESS_TYPES_UPDATE_REQUEST
    // PROCESS_TYPES_DELETE_REQUEST
    case PROCESS_TYPES_CREATE_REQUEST:
    case PROCESS_TYPES_UPDATE_REQUEST:
    case PROCESS_TYPES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // PROCESS_TYPES_CREATE_SUCCESS
    // PROCESS_TYPES_UPDATE_SUCCESS
    // PROCESS_TYPES_DELETE_SUCCESS
    // PROCESS_TYPES_CREATE_FAILURE
    // PROCESS_TYPES_UPDATE_FAILURE
    // PROCESS_TYPES_DELETE_FAILURE
    case PROCESS_TYPES_CREATE_SUCCESS:
    case PROCESS_TYPES_UPDATE_SUCCESS:
    case PROCESS_TYPES_DELETE_SUCCESS:
    case PROCESS_TYPES_CREATE_FAILURE:
    case PROCESS_TYPES_UPDATE_FAILURE:
    case PROCESS_TYPES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // PROCESS_TYPES_MOVE_REQUEST
    case PROCESS_TYPES_MOVE_REQUEST:
      return {
        ...state,
        isMoveLoading: true,
      }
    // PROCESS_TYPES_MOVE_SUCCESS
    // PROCESS_TYPES_MOVE_FAILURE
    case PROCESS_TYPES_MOVE_SUCCESS:
    case PROCESS_TYPES_MOVE_FAILURE:
      return {
        ...state,
        isMoveLoading: false,
      }
    // PROCESS_TYPES_TOGGLE_ADD_MODAL
    case PROCESS_TYPES_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }
    // PROCESS_TYPES_TOGGLE_EDIT_MODAL
    case PROCESS_TYPES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // PROCESS_TYPES_TOGGLE_DELETE_MODAL
    case PROCESS_TYPES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // PROCESS_TYPES_FILTER_SET_KEYWORD
    case PROCESS_TYPES_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PROCESS_TYPES_FILTER_SELECT_MACHINE
    case PROCESS_TYPES_FILTER_SELECT_MACHINE:
      return {
        ...state,
        filter: {
          ...state.filter,
          machine: action.machine,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PROCESS_TYPES_FILTER_SET_MACHINES_MODE
    case PROCESS_TYPES_FILTER_SET_MACHINES_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          machinesMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PROCESS_TYPES_FILTER_SET_DEFAULT_MACHINE_MODE
    case PROCESS_TYPES_FILTER_SET_DEFAULT_MACHINE_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          defaultMachineMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // PROCESS_TYPES_CLEAR_FILTER
    case PROCESS_TYPES_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          machine: null,
          machinesMode: 0,
          defaultMachineMode: 0,
        },
      }
    // PROCESS_TYPES_SET_AVAILABLE_MACHINES
    case PROCESS_TYPES_SET_AVAILABLE_MACHINES:
      return {
        ...state,
        availableMachines: action.items,
      }
    // PROCESS_TYPES_SET_ERRORS
    case PROCESS_TYPES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // PROCESS_TYPES_SET_CURRENT_PAGE
    case PROCESS_TYPES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // PROCESS_TYPES_SET_PAGINATION_PER_PAGE
    case PROCESS_TYPES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // PROCESS_TYPES_SET_QUERY_SETTINGS
    case PROCESS_TYPES_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          machine: parseInt(action.machine, 10) || null,
          machinesMode: parseInt(action.machines_mode, 10) || 0,
          defaultMachineMode: parseInt(action.default_machine_mode, 10) || 0,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // PROCESS_TYPES_CLEAR
    case PROCESS_TYPES_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default processTypesReducer
