import global from './global'
import session from './session'
import orders from './orders'
import events from './events'
import contractors from './contractors'
import suppliers from './suppliers'
import addresses from './addresses'
import cities from './cities'
import registration_keys from './registration_keys'

import paper_sizes from './paper_sizes'
import color_types from './color_types'
import paper_types from './paper_types'
import paper_type_groups from './paper_type_groups'
import varnish_types from './varnish_types'
import cutting_patterns from './cutting_patterns'
import machines from './machines'
import process_types from './process_types'

import paper_demands from './paper_demands'
import materials from './materials'
import delivery_notes from './delivery_notes'

import employees from './employees'
import issues from './issues'
import users from './users'
import roles from './roles'
import versions from './versions'

import employee_time_entries from './employee_time_entries'

export default {
  global,
  session,
  orders,
  events,
  contractors,
  suppliers,
  addresses,
  cities,
  registration_keys,
  paper_sizes,
  color_types,
  paper_types,
  paper_type_groups,
  varnish_types,
  cutting_patterns,
  machines,
  process_types,
  paper_demands,
  materials,
  delivery_notes,
  employees,
  issues,
  users,
  roles,
  versions,
  employee_time_entries,
}
