import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import useWindowSize from 'hooks/useWindowSize'

const Submenu = ({ toggleIcon, toggleText, children }) => {
  const itemRef = useRef()
  const submenuRef = useRef()
  const { pathname } = useLocation()
  const [isActive, setIsActive] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOutOfScreen, setIsOutOfScreen] = useState(false)
  const { height: windowHeight } = useWindowSize()

  useEffect(() => {
    if (children) {
      const condition = children.some(
        ({ props }) =>
          props?.children &&
          matchPath({ path: props.children.props.to }, pathname) !== null
      )
      setIsActive(condition)
    }
  }, [children, pathname])

  useLayoutEffect(() => {
    const updatePosition = () => {
      const { top: itemTop } = itemRef.current.getBoundingClientRect()
      const { height: submenuHeight } =
        submenuRef.current.getBoundingClientRect()
      setIsOutOfScreen(itemTop + submenuHeight > windowHeight)
    }
    window.addEventListener('resize', updatePosition)
    updatePosition()
    return () => window.removeEventListener('resize', updatePosition)
  }, [windowHeight])

  return (
    <li
      className={classNames('gd-aside-menu__item', {
        'bottom-overflow': isOutOfScreen,
      })}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      ref={itemRef}
    >
      <div
        className={classNames('gd-aside-menu__expand', { active: isActive })}
      >
        <FontAwesomeIcon icon={toggleIcon} className="gd-aside-menu__icon" />
        <span className="gd-aside-menu__text">{toggleText}</span>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="gd-aside-menu__arrow"
        />
      </div>
      <ul
        className={classNames('gd-aside-submenu', { expanded: isExpanded })}
        onClick={() => setIsExpanded(false)}
        ref={submenuRef}
      >
        {children}
      </ul>
    </li>
  )
}

export default Submenu
