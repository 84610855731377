import React from 'react'
import moment from 'moment'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import 'initializers'

// Import styles
import 'react-toastify/dist/ReactToastify.css'
import './assets/scss/index.scss'

// Components
import App from './components/main/App'

moment.locale('pl')

const domNode = document.getElementById('application')
const root = createRoot(domNode)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
