import React, { useState, useEffect } from 'react'

// Hooks
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Actions
import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from 'redux/actions/global'

// Components

// Utils
import config from 'config'
import api from 'utils/api'
import { defaultRequest } from 'utils/requests'
import _ from 'lodash'
import EventsMachines from './EventsMachines'

const EventsMachinesContaienr = () => {
  const [groups, setGroups] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const groupMachines = (items) =>
    _.mapValues(_.groupBy(items, 'template'), (list) =>
      list.map((machine) => _.omit(machine, 'template'))
    )

  useEffect(() => {
    const getMachines = async () =>
      defaultRequest({
        request: () => api.get(config.api.machines.search),
        beforeRequest: () => setIsLoading(true),
        onSuccess: ({ data }) => setGroups(groupMachines(data)),
        afterRequest: () => setIsLoading(false),
        onForbiddenError: ({ error }) =>
          dispatch({ type: GLOBAL_SET_UNAUTHORIZED, message: error }),
        onUnknownError: () =>
          displayAlert(t('machines:alerts.cannot_fetch'), 'error'),
      })
    getMachines()
  }, [dispatch, t])

  return (
    <EventsMachines
      groups={groups}
      isLoading={isLoading}
      loadingText={t('info.loading')}
    />
  )
}

export default EventsMachinesContaienr
