import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const VERSIONS_FETCH_REQUEST = 'VERSIONS_FETCH_REQUEST'
export const VERSIONS_FETCH_SUCCESS = 'VERSIONS_FETCH_SUCCESS'
export const VERSIONS_FETCH_FAILURE = 'VERSIONS_FETCH_FAILURE'
// FILTERS
export const VERSIONS_FILTER_SET_KEYWORD = 'VERSIONS_FILTER_SET_KEYWORD'
export const VERSIONS_FILTER_SELECT_AUTHOR = 'VERSIONS_FILTER_SELECT_AUTHOR'
export const VERSIONS_CLEAR_FILTER = 'VERSIONS_CLEAR_FILTER'
// Other
export const VERSIONS_SORT = 'VERSIONS_SORT'
export const VERSIONS_SET_CURRENT_PAGE = 'VERSIONS_SET_CURRENT_PAGE'
export const VERSIONS_SET_PAGINATION_PER_PAGE =
  'VERSIONS_SET_PAGINATION_PER_PAGE'
export const VERSIONS_SET_QUERY_SETTINGS = 'VERSIONS_SET_QUERY_SETTINGS'
export const VERSIONS_CLEAR = 'VERSIONS_CLEAR'

/**
 * FETCH VERSIONS
 * * Get versions list with pagination
 */
export const fetchVersions = () => async (dispatch, getState) => {
  // Get current state
  const {
    versions: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.versions.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          author_id: filter.author,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: VERSIONS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: VERSIONS_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: VERSIONS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('versions:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: VERSIONS_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchVersions())
}
