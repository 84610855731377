import rootReducer from 'redux/reducers'
import { configureStore } from '@reduxjs/toolkit'
import api from 'redux/services/api'

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    ...rootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})

export default store
