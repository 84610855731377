import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'
import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'
import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

/* ---------------------------------- FETCH --------------------------------- */
export const PAPER_TYPE_GROUPS_FETCH_REQUEST = 'PAPER_TYPE_GROUPS_FETCH_REQUEST'
export const PAPER_TYPE_GROUPS_FETCH_SUCCESS = 'PAPER_TYPE_GROUPS_FETCH_SUCCESS'
export const PAPER_TYPE_GROUPS_FETCH_FAILURE = 'PAPER_TYPE_GROUPS_FETCH_FAILURE'
/* --------------------------------- CREATE --------------------------------- */
export const PAPER_TYPE_GROUPS_CREATE_REQUEST =
  'PAPER_TYPE_GROUPS_CREATE_REQUEST'
export const PAPER_TYPE_GROUPS_CREATE_SUCCESS =
  'PAPER_TYPE_GROUPS_CREATE_SUCCESS'
export const PAPER_TYPE_GROUPS_CREATE_FAILURE =
  'PAPER_TYPE_GROUPS_CREATE_FAILURE'
/* --------------------------------- UPDATE --------------------------------- */
export const PAPER_TYPE_GROUPS_UPDATE_REQUEST =
  'PAPER_TYPE_GROUPS_UPDATE_REQUEST'
export const PAPER_TYPE_GROUPS_UPDATE_SUCCESS =
  'PAPER_TYPE_GROUPS_UPDATE_SUCCESS'
export const PAPER_TYPE_GROUPS_UPDATE_FAILURE =
  'PAPER_TYPE_GROUPS_UPDATE_FAILURE'
/* --------------------------------- DELETE --------------------------------- */
export const PAPER_TYPE_GROUPS_DELETE_REQUEST =
  'PAPER_TYPE_GROUPS_DELETE_REQUEST'
export const PAPER_TYPE_GROUPS_DELETE_SUCCESS =
  'PAPER_TYPE_GROUPS_DELETE_SUCCESS'
export const PAPER_TYPE_GROUPS_DELETE_FAILURE =
  'PAPER_TYPE_GROUPS_DELETE_FAILURE'
/* --------------------------------- MODALS --------------------------------- */
export const PAPER_TYPE_GROUPS_TOGGLE_EDIT_MODAL =
  'PAPER_TYPE_GROUPS_TOGGLE_EDIT_MODAL'
export const PAPER_TYPE_GROUPS_TOGGLE_DELETE_MODAL =
  'PAPER_TYPE_GROUPS_TOGGLE_DELETE_MODAL'
/* --------------------------------- FILTERS -------------------------------- */
export const PAPER_TYPE_GROUPS_FILTER_SET_KEYWORD =
  'PAPER_TYPE_GROUPS_FILTER_SET_KEYWORD'
export const PAPER_TYPE_GROUPS_CLEAR_FILTER = 'PAPER_TYPE_GROUPS_CLEAR_FILTER'
/* ---------------------------------- Other --------------------------------- */
export const PAPER_TYPE_GROUPS_SORT = 'PAPER_TYPE_GROUPS_SORT'
export const PAPER_TYPE_GROUPS_SET_ERRORS = 'PAPER_TYPE_GROUPS_SET_ERRORS'
export const PAPER_TYPE_GROUPS_SET_CURRENT_PAGE =
  'PAPER_TYPE_GROUPS_SET_CURRENT_PAGE'
export const PAPER_TYPE_GROUPS_SET_PAGINATION_PER_PAGE =
  'PAPER_TYPE_GROUPS_SET_PAGINATION_PER_PAGE'
export const PAPER_TYPE_GROUPS_SET_QUERY_SETTINGS =
  'PAPER_TYPE_GROUPS_SET_QUERY_SETTINGS'
export const PAPER_TYPE_GROUPS_CLEAR = 'PAPER_TYPE_GROUPS_CLEAR'

/**
 * FETCH PAPER TYPES GRUOPS
 * * Get paper type group list with pagination
 */
export const fetchPaperTypeGroups = () => async (dispatch, getState) => {
  // Get current state
  const {
    paper_type_groups: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.paper_type_groups.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: PAPER_TYPE_GROUPS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: PAPER_TYPE_GROUPS_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: PAPER_TYPE_GROUPS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_type_groups:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE PAPER TYPE GROUP
 * * Create paper type group and display validation errors
 */
export const createPaperTypeGroup =
  ({ data, onClose, onCreate = () => {} }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.post(config.api.paper_type_groups.root, data),
      beforeRequest: () => dispatch({ type: PAPER_TYPE_GROUPS_CREATE_REQUEST }),
      onSuccess: ({ data: responseData }) => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_type_groups:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: PAPER_TYPE_GROUPS_CREATE_SUCCESS })
        // Reload paper type groups list
        dispatch(fetchPaperTypeGroups())
        // Call onCreate method
        onCreate(responseData)
        // Close modal and reset errors
        onClose()
        dispatch({ type: PAPER_TYPE_GROUPS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PAPER_TYPE_GROUPS_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (responseData) =>
        dispatch({ type: PAPER_TYPE_GROUPS_SET_ERRORS, errors: responseData }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_type_groups:alerts.cannot_create'), 'error'),
      onUnexpectedError: () => onClose(),
    })

/**
 * UPDATE CUTTING PATTERN
 * * Update cutting pattern and reload cutting patterns list
 */
export const updatePaperTypeGroup =
  ({ id, name }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.paper_type_groups.root}/${id}`, {
          name,
        }),
      beforeRequest: () => dispatch({ type: PAPER_TYPE_GROUPS_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_type_groups:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: PAPER_TYPE_GROUPS_UPDATE_SUCCESS })
        // Reload paper type groups list
        dispatch(fetchPaperTypeGroups())
        // Close modal and reset errors
        dispatch({ type: PAPER_TYPE_GROUPS_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: PAPER_TYPE_GROUPS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: PAPER_TYPE_GROUPS_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_TYPE_GROUPS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_type_groups:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_TYPE_GROUPS_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE PAPER TYPE GROUP
 * * Delete paper type group and fetch paper type groups list
 */
export const deletePaperTypesGroup = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.paper_type_groups.root}/${id}`),
    beforeRequest: () => dispatch({ type: PAPER_TYPE_GROUPS_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(
        i18n.t('paper_type_groups:alerts.successfully_deleted'),
        'success'
      )
      // Disable loading
      dispatch({ type: PAPER_TYPE_GROUPS_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: PAPER_TYPE_GROUPS_TOGGLE_DELETE_MODAL, state: false })
      // Reload paper type groups list
      dispatch(fetchPaperTypeGroups())
    },
    onError: () => dispatch({ type: PAPER_TYPE_GROUPS_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_type_groups:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: PAPER_TYPE_GROUPS_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: PAPER_TYPE_GROUPS_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchPaperTypeGroups())
}
