import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const PAPER_DEMANDS_FETCH_REQUEST = 'PAPER_DEMANDS_FETCH_REQUEST'
export const PAPER_DEMANDS_FETCH_SUCCESS = 'PAPER_DEMANDS_FETCH_SUCCESS'
export const PAPER_DEMANDS_FETCH_FAILURE = 'PAPER_DEMANDS_FETCH_FAILURE'
// UPDATE
export const PAPER_DEMANDS_UPDATE_REQUEST = 'PAPER_DEMANDS_UPDATE_REQUEST'
export const PAPER_DEMANDS_UPDATE_SUCCESS = 'PAPER_DEMANDS_UPDATE_SUCCESS'
export const PAPER_DEMANDS_UPDATE_FAILURE = 'PAPER_DEMANDS_UPDATE_FAILURE'
// FILL
export const PAPER_DEMANDS_FILL_REQUEST = 'PAPER_DEMANDS_FILL_REQUEST'
export const PAPER_DEMANDS_FILL_SUCCESS = 'PAPER_DEMANDS_FILL_SUCCESS'
export const PAPER_DEMANDS_FILL_FAILURE = 'PAPER_DEMANDS_FILL_FAILURE'
// MOVE
export const PAPER_DEMANDS_MOVE_REQUEST = 'PAPER_DEMANDS_MOVE_REQUEST'
export const PAPER_DEMANDS_MOVE_SUCCESS = 'PAPER_DEMANDS_MOVE_SUCCESS'
export const PAPER_DEMANDS_MOVE_FAILURE = 'PAPER_DEMANDS_MOVE_FAILURE'
// MODALS
export const PAPER_DEMANDS_TOGGLE_EDIT_MODAL = 'PAPER_DEMANDS_TOGGLE_EDIT_MODAL'
export const PAPER_DEMANDS_TOGGLE_FILL_MODAL = 'PAPER_DEMANDS_TOGGLE_FILL_MODAL'

// FILTERS
export const PAPER_DEMANDS_FILTER_SET_DATE = 'PAPER_DEMANDS_FILTER_SET_DATE'
export const PAPER_DEMANDS_FILTER_SET_KEYWORD =
  'PAPER_DEMANDS_FILTER_SET_KEYWORD'
export const PAPER_DEMANDS_FILTER_SELECT_PAPER_TYPE =
  'PAPER_DEMANDS_FILTER_SELECT_PAPER_TYPE'
export const PAPER_DEMANDS_FILTER_SELECT_BASE_PAPER_SIZE =
  'PAPER_DEMANDS_FILTER_SELECT_BASE_PAPER_SIZE'
export const PAPER_DEMANDS_FILTER_SELECT_TARGET_PAPER_SIZE =
  'PAPER_DEMANDS_FILTER_SELECT_TARGET_PAPER_SIZE'
export const PAPER_DEMANDS_CLEAR_FILTER = 'PAPER_DEMANDS_CLEAR_FILTER'
// Selection
export const PAPER_DEMANDS_ADD_ITEM_SELECTION =
  'PAPER_DEMANDS_ADD_ITEM_SELECTION'
export const PAPER_DEMANDS_REMOVE_ITEM_SELECTION =
  'PAPER_DEMANDS_REMOVE_ITEM_SELECTION'
export const PAPER_DEMANDS_TOGGLE_ITEM_SELECTIONS =
  'PAPER_DEMANDS_TOGGLE_ITEM_SELECTIONS'
export const PAPER_DEMANDS_CLEAR_ITEM_SELECTIONS =
  'PAPER_DEMANDS_CLEAR_ITEM_SELECTIONS'
// Other
export const PAPER_DEMANDS_SORT = 'PAPER_DEMANDS_SORT'
export const PAPER_DEMANDS_SET_ERRORS = 'PAPER_DEMANDS_SET_ERRORS'
export const PAPER_DEMANDS_SET_QUERY_SETTINGS =
  'PAPER_DEMANDS_SET_QUERY_SETTINGS'
export const PAPER_DEMANDS_CLEAR = 'PAPER_DEMANDS_CLEAR'
export const PAPER_DEMANDS_SET_SELECTION_TYPE =
  'PAPER_DEMANDS_SET_SELECTION_TYPE'
export const PAPER_DEMANDS_SET_SELECTIONS_HIGHLIGHT =
  'PAPER_DEMANDS_SET_SELECTIONS_HIGHLIGHT'

/**
 * FETCH PAPER DEMANDS
 * * Get paper demands list
 */
export const fetchPaperDemands = () => async (dispatch, getState) => {
  // Get current state
  const {
    paper_demands: { filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(config.api.paper_demands.root, {
        params: {
          date: filter.date,
          search: filter.keyword,
          paper_type_id: filter.paper_type,
          base_paper_size_id: filter.base_paper_size,
          target_paper_size_id: filter.target_paper_size,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: PAPER_DEMANDS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response
      const { data } = response
      // Dispatch success action
      dispatch({ type: PAPER_DEMANDS_FETCH_SUCCESS, items: data })
    },
    onError: () => dispatch({ type: PAPER_DEMANDS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_demands:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * UPDATE PAPER DEMAND
 * * Update paper demand and reload paper demands list
 */
export const updatePaperDemand =
  ({
    id,
    paper_type_id,
    base_paper_size_id,
    base_quantity,
    target_additional_quantity,
    annotation,
  }) =>
  async (dispatch, getState) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.paper_demands.root}/${id}`, {
          paper_type_id,
          base_paper_size_id,
          base_quantity,
          target_additional_quantity,
          annotation,
        }),
      beforeRequest: () => dispatch({ type: PAPER_DEMANDS_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('paper_demands:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: PAPER_DEMANDS_UPDATE_SUCCESS })
        // Reload paper demands list
        dispatch(fetchPaperDemands())
        // Close modal and reset errors
        dispatch({ type: PAPER_DEMANDS_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: PAPER_DEMANDS_SET_ERRORS, errors: [] })

        if (!base_paper_size_id || !base_quantity) {
          // Get current state
          const {
            paper_demands: { items },
          } = getState()
          const updatedItem = items.find(({ id: itemId }) => itemId === id)
          dispatch({
            type: PAPER_DEMANDS_REMOVE_ITEM_SELECTION,
            id,
            quantity: updatedItem.target_quantity,
          })
        }
      },
      onError: () => dispatch({ type: PAPER_DEMANDS_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_DEMANDS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_demands:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_DEMANDS_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * MOVE PAPER DEMANDS TO MATERIALS
 * * Move paper demands to materials and update paper demands list
 */
export const moveSelectedItemsToMaterials = () => async (dispatch, getState) =>
  defaultRequest({
    request: () =>
      api.post(config.api.paper_demands.materials, {
        paper_demand_ids: getState().paper_demands.selectedItems,
      }),
    beforeRequest: () => dispatch({ type: PAPER_DEMANDS_MOVE_REQUEST }),
    onSuccess: ({ data }) => {
      // Display successfull message
      if (
        data?.skipped &&
        data.skipped < getState().paper_demands.selectedItems.length
      ) {
        displayAlert(
          i18n.t('paper_demands:alerts.successfully_moved'),
          'success'
        )
      }
      if (data?.skipped && data.skipped > 0) {
        // Display skipped items message
        displayAlert(
          i18n.t('paper_demands:alerts.skipped_items', {
            count: data.skipped,
          }),
          'warning'
        )
      }
      // Disable loading
      dispatch({ type: PAPER_DEMANDS_MOVE_SUCCESS })
      // Reload paper demands list
      dispatch(fetchPaperDemands())
      // Clear selections
      dispatch({ type: PAPER_DEMANDS_CLEAR_ITEM_SELECTIONS })
    },
    onError: () => dispatch({ type: PAPER_DEMANDS_MOVE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_demands:alerts.cannot_move'), 'error'),
  })

/**
 * FILL PAPER DEMANDS
 * * Fill paper demands and update paper demands list
 */
export const fillPaperDemands =
  ({ base_paper_size_id, paper_demand_ids }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(config.api.paper_demands.fill, {
          base_paper_size_id,
          paper_demand_ids,
        }),
      beforeRequest: () => dispatch({ type: PAPER_DEMANDS_FILL_REQUEST }),
      onSuccess: ({ data }) => {
        // Display successfull message
        if (data?.skipped && data.skipped < paper_demand_ids.length) {
          displayAlert(
            i18n.t('paper_demands:alerts.successfully_filled'),
            'success'
          )
        }
        if (data?.skipped && data.skipped > 0) {
          // Display skipped items message
          displayAlert(
            i18n.t('paper_demands:alerts.skipped_items', {
              count: data.skipped,
            }),
            'warning'
          )
        }

        // Disable loading
        dispatch({ type: PAPER_DEMANDS_FILL_SUCCESS })
        // Reload paper demands list
        dispatch(fetchPaperDemands())
        // Close modal and reset errors
        dispatch({ type: PAPER_DEMANDS_TOGGLE_FILL_MODAL, state: false })
        dispatch({ type: PAPER_DEMANDS_SET_ERRORS, errors: [] })
        // Clear selections
        dispatch({ type: PAPER_DEMANDS_CLEAR_ITEM_SELECTIONS })
      },
      onError: () => dispatch({ type: PAPER_DEMANDS_FILL_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: PAPER_DEMANDS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('paper_demands:alerts.cannot_fill'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: PAPER_DEMANDS_TOGGLE_FILL_MODAL, state: false }),
    })

/**
 * HIDE PAPER DEMAND
 * * Hide paper demand and reload paper demands list
 */
export const hidePaperDemand = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.put(`${config.api.paper_demands.root}/${id}/hide`),
    beforeRequest: () => dispatch({ type: PAPER_DEMANDS_UPDATE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(
        i18n.t('paper_demands:alerts.successfully_hidden'),
        'success'
      )
      // Disable loading
      dispatch({ type: PAPER_DEMANDS_UPDATE_SUCCESS })
      // Reload paper demands list
      dispatch(fetchPaperDemands())
    },
    onError: () => dispatch({ type: PAPER_DEMANDS_UPDATE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnprocessableError: (data) =>
      dispatch({ type: PAPER_DEMANDS_SET_ERRORS, errors: data }),
    onUnknownError: () =>
      displayAlert(i18n.t('paper_demands:alerts.cannot_hide'), 'error'),
  })
