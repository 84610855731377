import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'
import md5 from 'crypto-js/md5'

/* -------------------------------------------------------------------------- */
/*                                 User Avatar                                */
/* -------------------------------------------------------------------------- */
const UserAvatar = ({ email, className = 'tw-h-8', showOnline = false }) => {
  return (
    <div
      className={cn(
        'tw-aspect-square tw-bg-white tw-bg-cover tw-bg-center tw-rounded-full tw-border-2 tw-border-white tw-relative',
        className
      )}
      style={{
        backgroundImage: `url(https://www.gravatar.com/avatar/${md5(
          email.toLowerCase().trim()
        )}?d=mp)`,
      }}
    >
      {showOnline && (
        <>
          <div className="tw-absolute -tw-bottom-0.5 -tw-right-0.5 tw-w-2 tw-aspect-square tw-rounded-full tw-bg-green-500 tw-animate-ping"></div>
          <div className="tw-absolute -tw-bottom-0.5 -tw-right-0.5 tw-w-2 tw-aspect-square tw-rounded-full tw-bg-green-500"></div>
        </>
      )}
    </div>
  )
}

export default UserAvatar
