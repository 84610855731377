import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

/* ---------------------------------- FETCH --------------------------------- */
export const EMPLOYEE_TIME_ENTRIES_FETCH_REQUEST =
  'EMPLOYEE_TIME_ENTRIES_FETCH_REQUEST'
export const EMPLOYEE_TIME_ENTRIES_FETCH_SUCCESS =
  'EMPLOYEE_TIME_ENTRIES_FETCH_SUCCESS'
export const EMPLOYEE_TIME_ENTRIES_FETCH_FAILURE =
  'EMPLOYEE_TIME_ENTRIES_FETCH_FAILURE'

/* --------------------------------- UPDATE --------------------------------- */
export const EMPLOYEE_TIME_ENTRIES_UPDATE_REQUEST =
  'EMPLOYEE_TIME_ENTRIES_UPDATE_REQUEST'
export const EMPLOYEE_TIME_ENTRIES_UPDATE_SUCCESS =
  'EMPLOYEE_TIME_ENTRIES_UPDATE_SUCCESS'
export const EMPLOYEE_TIME_ENTRIES_UPDATE_FAILURE =
  'EMPLOYEE_TIME_ENTRIES_UPDATE_FAILURE'
export const EMPLOYEE_TIME_ENTRIES_UPDATE_ITEM =
  'EMPLOYEE_TIME_ENTRIES_UPDATE_ITEM'

/* --------------------------------- DELETE --------------------------------- */
export const EMPLOYEE_TIME_ENTRIES_DELETE_REQUEST =
  'EMPLOYEE_TIME_ENTRIES_DELETE_REQUEST'
export const EMPLOYEE_TIME_ENTRIES_DELETE_SUCCESS =
  'EMPLOYEE_TIME_ENTRIES_DELETE_SUCCESS'
export const EMPLOYEE_TIME_ENTRIES_DELETE_FAILURE =
  'EMPLOYEE_TIME_ENTRIES_DELETE_FAILURE'
export const EMPLOYEE_TIME_ENTRIES_DELETE_ITEM =
  'EMPLOYEE_TIME_ENTRIES_DELETE_ITEM'

/* --------------------------------- MODALS --------------------------------- */
export const EMPLOYEE_TIME_ENTRIES_TOGGLE_EDIT_MODAL =
  'EMPLOYEE_TIME_ENTRIES_TOGGLE_EDIT_MODAL'
export const EMPLOYEE_TIME_ENTRIES_TOGGLE_DELETE_MODAL =
  'EMPLOYEE_TIME_ENTRIES_TOGGLE_DELETE_MODAL'

/* --------------------------------- OTHERS --------------------------------- */
export const EMPLOYEE_TIME_ENTRIES_SET_ERRORS =
  'EMPLOYEE_TIME_ENTRIES_SET_ERRORS'
export const EMPLOYEE_TIME_ENTRIES_SET_TIME = 'EMPLOYEE_TIME_ENTRIES_SET_TIME'

/* -------------------------------------------------------------------------- */
/*                             FETCH TIME ENTRIES                             */
/* -------------------------------------------------------------------------- */
export const fetchEmployeeTimeEntries = () => async (dispatch, getState) => {
  // Get current state
  const {
    employee_time_entries: { timeStart, timeEnd },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.employee_time_entries.root}`, {
        params: {
          start_date: timeStart,
          end_date: timeEnd,
        },
      }),
    beforeRequest: () =>
      dispatch({ type: EMPLOYEE_TIME_ENTRIES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data } = response
      // Dispatch success action
      dispatch({
        type: EMPLOYEE_TIME_ENTRIES_FETCH_SUCCESS,
        items: data,
      })
    },
    onError: () => dispatch({ type: EMPLOYEE_TIME_ENTRIES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(
        i18n.t('employee_time_entries:alerts.cannot_fetch_list'),
        'error'
      ),
  })
}

/* -------------------------------------------------------------------------- */
/*                              UPDATE TIME ENTRY                             */
/* -------------------------------------------------------------------------- */
export const updateTimeEntry =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.employee_time_entries.root}/${id}`, params),
      beforeRequest: () =>
        dispatch({ type: EMPLOYEE_TIME_ENTRIES_UPDATE_REQUEST }),
      onSuccess: ({ data }) => {
        // Display successfull message
        displayAlert(
          i18n.t('employee_time_entries:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: EMPLOYEE_TIME_ENTRIES_UPDATE_SUCCESS })
        // Update item on time entries list
        dispatch({ type: EMPLOYEE_TIME_ENTRIES_UPDATE_ITEM, item: data })
        // Close modal and reset errors
        dispatch({
          type: EMPLOYEE_TIME_ENTRIES_TOGGLE_EDIT_MODAL,
          state: false,
        })
        dispatch({ type: EMPLOYEE_TIME_ENTRIES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: EMPLOYEE_TIME_ENTRIES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: EMPLOYEE_TIME_ENTRIES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(
          i18n.t('employee_time_entries:alerts.cannot_update'),
          'error'
        ),
      onUnexpectedError: () =>
        dispatch({
          type: EMPLOYEE_TIME_ENTRIES_TOGGLE_EDIT_MODAL,
          state: false,
        }),
    })

/* -------------------------------------------------------------------------- */
/*                              DELETE TIME ENTRY                             */
/* -------------------------------------------------------------------------- */
export const deleteTimeEntry = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.employee_time_entries.root}/${id}`),
    beforeRequest: () =>
      dispatch({ type: EMPLOYEE_TIME_ENTRIES_DELETE_REQUEST }),
    onSuccess: ({ data }) => {
      // Display successfull message
      displayAlert(
        i18n.t('employee_time_entries:alerts.successfully_deleted'),
        'success'
      )
      // Disable loading
      dispatch({ type: EMPLOYEE_TIME_ENTRIES_DELETE_SUCCESS })
      // Close modal
      dispatch({
        type: EMPLOYEE_TIME_ENTRIES_TOGGLE_DELETE_MODAL,
        state: false,
      })
      // Remove item on time entries list
      dispatch({ type: EMPLOYEE_TIME_ENTRIES_DELETE_ITEM, item: data })
    },
    onError: () => dispatch({ type: EMPLOYEE_TIME_ENTRIES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(
        i18n.t('employee_time_entries:alerts.cannot_delete'),
        'error'
      ),
    onUnexpectedError: () =>
      dispatch({
        type: EMPLOYEE_TIME_ENTRIES_TOGGLE_DELETE_MODAL,
        state: false,
      }),
  })
