import React from 'react'

/* ---------------------------------- Icons --------------------------------- */
import {
  faBox,
  faCheck,
  faClock,
  faPlay,
  faTruck,
  faXmark,
  faXmarkToSlot,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* -------------------------------------------------------------------------- */
/*                            Status Icon Component                           */
/* -------------------------------------------------------------------------- */
const StatusIcon = ({ status }) => {
  switch (status) {
    case 'in_progress':
      return <FontAwesomeIcon icon={faPlay} />
    case 'completed':
      return <FontAwesomeIcon icon={faCheck} />
    case 'stored':
      return <FontAwesomeIcon icon={faBox} />
    case 'sent':
      return <FontAwesomeIcon icon={faTruck} />
    case 'rejected':
      return <FontAwesomeIcon icon={faXmarkToSlot} />
    case 'cancelled':
      return <FontAwesomeIcon icon={faXmark} />
    default:
      return <FontAwesomeIcon icon={faClock} />
  }
}

export default StatusIcon
