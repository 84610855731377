import config from 'config'

import {
  REGISTRATION_KEYS_FETCH_REQUEST,
  REGISTRATION_KEYS_FETCH_SUCCESS,
  REGISTRATION_KEYS_FETCH_FAILURE,
  REGISTRATION_KEYS_CREATE_REQUEST,
  REGISTRATION_KEYS_CREATE_SUCCESS,
  REGISTRATION_KEYS_CREATE_FAILURE,
  REGISTRATION_KEYS_DELETE_REQUEST,
  REGISTRATION_KEYS_DELETE_SUCCESS,
  REGISTRATION_KEYS_DELETE_FAILURE,
  REGISTRATION_KEYS_SET_AVAILABLE_ROLES,
  REGISTRATION_KEYS_FILTER_SET_KEYWORD,
  REGISTRATION_KEYS_SELECT_ROLE,
  REGISTRATION_KEYS_CLEAR_FILTER,
  REGISTRATION_KEYS_SORT,
  REGISTRATION_KEYS_SET_ERRORS,
  REGISTRATION_KEYS_SET_CURRENT_PAGE,
  REGISTRATION_KEYS_SET_PAGINATION_PER_PAGE,
  REGISTRATION_KEYS_SET_QUERY_SETTINGS,
  REGISTRATION_KEYS_CLEAR,
  REGISTRATION_KEYS_SET_USED_MODE,
  REGISTRATION_KEYS_SET_EXPIRED_MODE,
  REGISTRATION_KEYS_TOGGLE_DELETE_MODAL,
  REGISTRATION_KEYS_TOGGLE_ADD_MODAL,
  REGISTRATION_KEYS_TOGGLE_SUMMARY_MODAL,
} from '../actions/registration_keys'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isDeleteModalOpen: false,
  isSummaryModalOpen: false,
  filter: {
    keyword: '',
    role: null,
    usedMode: 0,
    expiredMode: 0,
  },
  sorting: {
    column: 'registration_keys.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  availableRoles: [],
  errors: [],
  settingsFetched: false,
  summaryData: {},
}

const registrationKeysReducer = (state = initialState, action) => {
  switch (action.type) {
    // REGISTRATION_KEYS_FETCH_REQUEST
    case REGISTRATION_KEYS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // REGISTRATION_KEYS_FETCH_SUCCESS
    case REGISTRATION_KEYS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // REGISTRATION_KEYS_FETCH_FAILURE
    case REGISTRATION_KEYS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // REGISTRATION_KEYS_CREATE_REQUEST
    // REGISTRATION_KEYS_DELETE_REQUEST
    case REGISTRATION_KEYS_CREATE_REQUEST:
    case REGISTRATION_KEYS_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // REGISTRATION_KEYS_CREATE_SUCCESS
    // REGISTRATION_KEYS_DELETE_SUCCESS
    // REGISTRATION_KEYS_CREATE_FAILURE
    // REGISTRATION_KEYS_DELETE_FAILURE
    case REGISTRATION_KEYS_CREATE_SUCCESS:
    case REGISTRATION_KEYS_CREATE_FAILURE:
    case REGISTRATION_KEYS_DELETE_SUCCESS:
    case REGISTRATION_KEYS_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // REGISTRATION_KEYS_TOGGLE_ADD_MODAL
    case REGISTRATION_KEYS_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }
    // REGISTRATION_KEYS_TOGGLE_DELETE_MODAL
    case REGISTRATION_KEYS_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // REGISTRATION_KEYS_TOGGLE_SUMMARY_MODAL
    case REGISTRATION_KEYS_TOGGLE_SUMMARY_MODAL:
      return {
        ...state,
        isSummaryModalOpen: action.state,
        summaryData: action.data,
      }
    // REGISTRATION_KEYS_FILTER_SET_KEYWORD
    case REGISTRATION_KEYS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // REGISTRATION_KEYS_SELECT_ROLE
    case REGISTRATION_KEYS_SELECT_ROLE:
      return {
        ...state,
        filter: {
          ...state.filter,
          role: action.role,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // REGISTRATION_KEYS_SET_USED_MODE
    case REGISTRATION_KEYS_SET_USED_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          usedMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // REGISTRATION_KEYS_SET_EXPIRED_MODE
    case REGISTRATION_KEYS_SET_EXPIRED_MODE:
      return {
        ...state,
        filter: {
          ...state.filter,
          expiredMode: action.value,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // REGISTRATION_KEYS_CLEAR_FILTER
    case REGISTRATION_KEYS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
          role: null,
          usedMode: 0,
          expiredMode: 0,
        },
      }
    // REGISTRATION_KEYS_SET_AVAILABLE_ROLES
    case REGISTRATION_KEYS_SET_AVAILABLE_ROLES:
      return {
        ...state,
        availableRoles: action.items,
      }
    // REGISTRATION_KEYS_SORT
    case REGISTRATION_KEYS_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // REGISTRATION_KEYS_SET_ERRORS
    case REGISTRATION_KEYS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // REGISTRATION_KEYS_SET_CURRENT_PAGE
    case REGISTRATION_KEYS_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // REGISTRATION_KEYS_SET_PAGINATION_PER_PAGE
    case REGISTRATION_KEYS_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // REGISTRATION_KEYS_SET_QUERY_SETTINGS
    case REGISTRATION_KEYS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          role: parseInt(action.role, 10) || null,
          usedMode: parseInt(action.used_mode, 10) || 0,
          expiredMode: parseInt(action.expired_mode, 10) || 0,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // REGISTRATION_KEYS_CLEAR
    case REGISTRATION_KEYS_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default registrationKeysReducer
