import React from 'react'
import { useDispatch } from 'react-redux'
import { GLOBAL_CLEAR_NOT_FOUND } from 'redux/actions/global'
import NotFound from './NotFound'

const NotFoundContainer = () => {
  const dispatch = useDispatch()

  const handleExit = () => {
    dispatch({ type: GLOBAL_CLEAR_NOT_FOUND })
  }

  return <NotFound onExit={handleExit} />
}

export default NotFoundContainer
