import React, { Fragment } from 'react'

/* ---------------------------------- Icons --------------------------------- */
import {
  faCog,
  faImage,
  faLock,
  faPowerOff,
} from '@fortawesome/pro-solid-svg-icons'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'

/* ------------------------------- Components ------------------------------- */
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { endSession, signOut } from 'redux/actions/session'
import UserAvatar from 'components/atomic/atoms/user-avatar'
import UserMenuItem from './user-menu-item'
import SessionExpiration from './session-expiration'

/* -------------------------------------------------------------------------- */
/*                                  User Menu                                 */
/* -------------------------------------------------------------------------- */
const UserMenu = (user) => {
  const { first_name, last_name, roles } = user
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const handleSignOut = () => {
    dispatch(endSession())
    dispatch(signOut())
  }

  return (
    <Menu as="div" className="tw-relative tw-inline-block tw-text-left tw-z-20">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={cn(
                'tw-inline-flex tw-items-center tw-gap-x-2 tw-w-full tw-justify-center tw-rounded-lg tw-px-3 tw-py-1',
                open
                  ? 'tw-bg-primary-300/60 tw-text-primary-800'
                  : 'tw-text-slate-700 hover:text-slate-900 hover:tw-bg-slate-300'
              )}
            >
              <UserAvatar {...user} className="tw-h-8" />
              <div className="tw-font-semibold">{first_name}</div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-absolute tw-right-0 tw-w-56 tw-origin-top tw-divide-y tw-divide-gray-100 tw-rounded-lg tw-overflow-hidden tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-slate-700/5 focus:tw-outline-none">
              <div className="tw-bg-slate-50 tw-p-4 tw-flex tw-items-center tw-gap-x-3">
                <UserAvatar {...user} className="tw-h-10" />
                <div>
                  <div className="tw-font-bold tw-text-slate-700">
                    {first_name} {last_name}
                  </div>
                  <div className="tw-text-xs tw-text-slate-500">
                    {roles.map(({ name }) => name).join(', ')}
                  </div>
                </div>
              </div>
              <div className="tw-px-1 tw-py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <UserMenuItem
                      to="/account/permissions"
                      active={active}
                      icon={faLock}
                      tag="link"
                    >
                      {t('account:permissions.title')}
                    </UserMenuItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <UserMenuItem
                      to="/account/settings"
                      active={active}
                      icon={faCog}
                      tag="link"
                    >
                      {t('settings:title')}
                    </UserMenuItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <UserMenuItem
                      href="https://pl.gravatar.com/"
                      target="_blank"
                      active={active}
                      icon={faImage}
                      tag="a"
                    >
                      {t('account:menu.avatar')}
                    </UserMenuItem>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <UserMenuItem
                      active={active}
                      icon={faPowerOff}
                      onClick={handleSignOut}
                      color="orange"
                    >
                      {t('actions.sign_out')}
                    </UserMenuItem>
                  )}
                </Menu.Item>
              </div>
              <div className="tw-py-2 tw-px-5 tw-bg-slate-50">
                <div className="tw-text-xs tw-text-slate-500">
                  {t('account:menu.expiration_time')}
                </div>
                <div className="tw-font-bold tw-text-slate-700">
                  <SessionExpiration expirationTime={user.session_expires_at} />
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default UserMenu
