import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import polishTranslations from 'locales/pl'
import englishTranslations from 'locales/en'

const resources = {
  pl: polishTranslations,
  en: englishTranslations,
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'pl',
  defaultNS: 'global',
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
})

export default i18n
