import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const REGISTRATION_KEYS_FETCH_REQUEST = 'REGISTRATION_KEYS_FETCH_REQUEST'
export const REGISTRATION_KEYS_FETCH_SUCCESS = 'REGISTRATION_KEYS_FETCH_SUCCESS'
export const REGISTRATION_KEYS_FETCH_FAILURE = 'REGISTRATION_KEYS_FETCH_FAILURE'
// CREATE
export const REGISTRATION_KEYS_CREATE_REQUEST =
  'REGISTRATION_KEYS_CREATE_REQUEST'
export const REGISTRATION_KEYS_CREATE_SUCCESS =
  'REGISTRATION_KEYS_CREATE_SUCCESS'
export const REGISTRATION_KEYS_CREATE_FAILURE =
  'REGISTRATION_KEYS_CREATE_FAILURE'
// DELETE
export const REGISTRATION_KEYS_DELETE_REQUEST =
  'REGISTRATION_KEYS_DELETE_REQUEST'
export const REGISTRATION_KEYS_DELETE_SUCCESS =
  'REGISTRATION_KEYS_DELETE_SUCCESS'
export const REGISTRATION_KEYS_DELETE_FAILURE =
  'REGISTRATION_KEYS_DELETE_FAILURE'
// RESOURCES
export const REGISTRATION_KEYS_SET_AVAILABLE_ROLES =
  'REGISTRATION_KEYS_SET_AVAILABLE_ROLES'
// MODALS
export const REGISTRATION_KEYS_TOGGLE_ADD_MODAL =
  'REGISTRATION_KEYS_TOGGLE_ADD_MODAL'
export const REGISTRATION_KEYS_TOGGLE_DELETE_MODAL =
  'REGISTRATION_KEYS_TOGGLE_DELETE_MODAL'
export const REGISTRATION_KEYS_TOGGLE_SUMMARY_MODAL =
  'REGISTRATION_KEYS_TOGGLE_SUMMARY_MODAL'
// FILTERS
export const REGISTRATION_KEYS_FILTER_SET_KEYWORD =
  'REGISTRATION_KEYS_FILTER_SET_KEYWORD'
export const REGISTRATION_KEYS_CLEAR_FILTER = 'REGISTRATION_KEYS_CLEAR_FILTER'
export const REGISTRATION_KEYS_SELECT_ROLE = 'REGISTRATION_KEYS_SELECT_ROLE'
export const REGISTRATION_KEYS_SET_USED_MODE = 'REGISTRATION_KEYS_SET_USED_MODE'
export const REGISTRATION_KEYS_SET_EXPIRED_MODE =
  'REGISTRATION_KEYS_SET_EXPIRED_MODE'
// Other
export const REGISTRATION_KEYS_SORT = 'REGISTRATION_KEYS_SORT'
export const REGISTRATION_KEYS_SET_ERRORS = 'REGISTRATION_KEYS_SET_ERRORS'
export const REGISTRATION_KEYS_SET_CURRENT_PAGE =
  'REGISTRATION_KEYS_SET_CURRENT_PAGE'
export const REGISTRATION_KEYS_SET_PAGINATION_PER_PAGE =
  'REGISTRATION_KEYS_SET_PAGINATION_PER_PAGE'
export const REGISTRATION_KEYS_SET_QUERY_SETTINGS =
  'REGISTRATION_KEYS_SET_QUERY_SETTINGS'
export const REGISTRATION_KEYS_CLEAR = 'REGISTRATION_KEYS_CLEAR'

/**
 * FETCH REGISTRATION KEYS
 * * Get registration keys list with pagination
 */
export const fetchRegistrationKeys = () => async (dispatch, getState) => {
  // Get current state
  const {
    registration_keys: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.registration_keys}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          role_id: filter.role,
          used_mode: filter.usedMode,
          expired_mode: filter.expiredMode,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: REGISTRATION_KEYS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: REGISTRATION_KEYS_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: REGISTRATION_KEYS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('registration_keys:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE REGISTRATION KEY
 * * Create registration key and display validation errors
 */
export const createRegistrationKey =
  ({ role_id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.registration_keys, {
          role_id: role_id || null,
          ...params,
        }),
      beforeRequest: () => dispatch({ type: REGISTRATION_KEYS_CREATE_REQUEST }),
      onSuccess: (response) => {
        // Display successfull message
        displayAlert(
          i18n.t('registration_keys:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: REGISTRATION_KEYS_CREATE_SUCCESS })
        // Reload registration keys list
        dispatch(fetchRegistrationKeys())
        // Close modal and reset errors
        dispatch({ type: REGISTRATION_KEYS_TOGGLE_ADD_MODAL, state: false })
        dispatch({
          type: REGISTRATION_KEYS_TOGGLE_SUMMARY_MODAL,
          state: true,
          data: response.data,
        })
        dispatch({ type: REGISTRATION_KEYS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: REGISTRATION_KEYS_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: REGISTRATION_KEYS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('registration_keys:alerts.cannot_create'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: REGISTRATION_KEYS_TOGGLE_ADD_MODAL, state: false }),
    })

/**
 * DELETE REGISTRATION KEY
 * * Delete registration key and fetch list
 */
export const deleteRegistrationKey = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.registration_keys}/${id}`),
    beforeRequest: () => dispatch({ type: REGISTRATION_KEYS_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(
        i18n.t('registration_keys:alerts.successfully_deleted'),
        'success'
      )
      // Disable loading
      dispatch({ type: REGISTRATION_KEYS_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: REGISTRATION_KEYS_TOGGLE_DELETE_MODAL, state: false })
      // Reload registration keys list
      dispatch(fetchRegistrationKeys())
    },
    onError: () => dispatch({ type: REGISTRATION_KEYS_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('registration_keys:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: REGISTRATION_KEYS_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * FETCH ROLES
 * * Fetch roles list
 */
export const fetchAvailableRoles = () => async (dispatch) =>
  defaultRequest({
    request: () => api.get(`${config.api.roles.root}`),
    onSuccess: (response) =>
      dispatch({
        type: REGISTRATION_KEYS_SET_AVAILABLE_ROLES,
        items: response.data,
      }),
    onError: () => displayAlert(i18n.t('roles:alerts.cannot_fetch'), 'error'),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: REGISTRATION_KEYS_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchRegistrationKeys())
}
