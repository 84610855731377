import React from 'react'

/* ---------------------------------- Icons --------------------------------- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* ------------------------------- Components ------------------------------- */
import { NavLink } from 'react-router-dom'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'

/* -------------------------------------------------------------------------- */
/*                                     Nav                                    */
/* -------------------------------------------------------------------------- */
const Nav = ({ children }) => {
  return <div className="tw-flex tw-items-center tw-gap-x-2">{children}</div>
}

/* -------------------------------------------------------------------------- */
/*                                    Item                                    */
/* -------------------------------------------------------------------------- */
const Item = ({ children, icon = null, ...rest }) => {
  return (
    <NavLink
      {...rest}
      className={(isActive) =>
        cn(
          'tw-font-medium tw-px-3 tw-py-1 tw-flex tw-items-center tw-gap-x-2 tw-rounded-lg',
          isActive
            ? 'tw-bg-primary-300/60 tw-text-primary-800'
            : 'tw-text-slate-600 hover:tw-bg-slate-300 hover:tw-text-slate-900'
        )
      }
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      <span>{children}</span>
    </NavLink>
  )
}

export default Object.assign(Nav, { Item })
