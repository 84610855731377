import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { signIn } from 'redux/actions/session'

import NetworkErrorModal from 'components/modals/NetworkErrorModal'
import SessionExpiredModal from 'components/modals/SessionExpiredModal'
import ExpirationWarningModal from 'components/modals/ExpirationWarningModal'
// import OrientationLock from './OrientationLock'
import Routes from '../Routes'
import MaintenanceMode from './MaintenanceMode'

const Wrapper = () => {
  const networkError = useSelector((state) => state.global.networkError)
  const sessionExpired = useSelector((state) => state.global.sessionExpired)
  const expirationWarning = useSelector(
    (state) => state.global.expirationWarning
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(signIn())
  }, [dispatch])

  return (
    <>
      {/* <OrientationLock /> */}
      <NetworkErrorModal isOpen={networkError} />
      <SessionExpiredModal isOpen={sessionExpired} />
      <ExpirationWarningModal isOpen={expirationWarning} />
      <MaintenanceMode />
      <Routes />
    </>
  )
}

export default Wrapper
