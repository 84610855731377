import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import Loading from 'components/pages/Loading'
import Unauthorized from 'components/pages/Unauthorized'
import NotFound from 'components/pages/NotFound'

const AuthenticatedRoute = (props) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const session = useSelector((state) => state.session)
  const isUnauthorized = useSelector(
    (state) => state.global.unauthorized.isUnauthorized
  )
  const isNotFound = useSelector((state) => state.global.isNotFound)

  const Layout = props.layout

  useEffect(() => {
    setIsChecked(session.isChecked)
    setIsSignedIn(session.isSignedIn)
  }, [session])

  if (!isChecked) {
    return <Loading />
  }

  if (!isSignedIn) {
    return <Redirect to="/signin" />
  }

  if (isUnauthorized) {
    return <Unauthorized />
  }

  if (isNotFound) {
    return <NotFound />
  }

  return (
    <Layout>
      <Route {...props} />
    </Layout>
  )
}

export default AuthenticatedRoute
