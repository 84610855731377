import React, { useMemo } from 'react'

/* ---------------------------------- Icons --------------------------------- */
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faLockKeyhole,
  faRotate,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* -------------------------------------------------------------------------- */
/*                                 Alert Toast                                */
/* -------------------------------------------------------------------------- */
const AlertToast = ({ text, type }) => {
  const settings = useMemo(() => {
    switch (type) {
      case 'error':
        return {
          icon: faExclamationCircle,
          iconClassName: 'tw-text-red-500',
        }
      case 'warning':
        return {
          icon: faExclamationCircle,
          iconClassName: 'tw-text-amber-500',
        }
      case 'sync':
        return {
          icon: faRotate,
          iconClassName: 'tw-text-primary-500',
        }
      case 'permissions':
        return {
          icon: faLockKeyhole,
          iconClassName: 'tw-text-red-500',
        }
      case 'success':
        return {
          icon: faCheckCircle,
          iconClassName: 'tw-text-green-500',
        }
      default:
        return {
          icon: faInfoCircle,
          iconClassName: 'tw-text-slate-500',
        }
    }
  }, [type])

  return (
    <div className="tw-flex tw-items-stretch tw-gap-x-3">
      <div>
        <FontAwesomeIcon
          icon={settings.icon}
          className={settings.iconClassName}
        />
      </div>
      <div className="tw-text-slate-600 tw-text-sm">{text}</div>
    </div>
  )
}

export default AlertToast
