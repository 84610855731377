import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'
import _ from 'lodash'

import { defaultRequest } from 'utils/requests'

import { displayAlert, displayUserToast } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// LOAD
export const ORDERS_LOAD_REQUEST = 'ORDERS_LOAD_REQUEST'
export const ORDERS_LOAD_SUCCESS = 'ORDERS_LOAD_SUCCESS'
export const ORDERS_LOAD_FAILURE = 'ORDERS_LOAD_FAILURE'
export const ORDERS_LOAD_RESET = 'ORDERS_LOAD_RESET'
// FETCH
export const ORDERS_FETCH_REQUEST = 'ORDERS_FETCH_REQUEST'
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS'
export const ORDERS_FETCH_FAILURE = 'ORDERS_FETCH_FAILURE'
// CREATE
export const ORDERS_CREATE_REQUEST = 'ORDERS_CREATE_REQUEST'
export const ORDERS_CREATE_SUCCESS = 'ORDERS_CREATE_SUCCESS'
export const ORDERS_CREATE_FAILURE = 'ORDERS_CREATE_FAILURE'
// UPDATE
export const ORDERS_UPDATE_REQUEST = 'ORDERS_UPDATE_REQUEST'
export const ORDERS_UPDATE_SUCCESS = 'ORDERS_UPDATE_SUCCESS'
export const ORDERS_UPDATE_FAILURE = 'ORDERS_UPDATE_FAILURE'
// FILTERS
export const ORDERS_FILTER_SET_KEYWORD = 'ORDERS_FILTER_SET_KEYWORD'
export const ORDERS_FILTER_SET_STATUS = 'ORDERS_FILTER_SET_STATUS'
export const ORDERS_FILTER_SET_YEARS = 'ORDERS_FILTER_SET_YEARS'
export const ORDERS_FILTER_SELECT_CONTRACTOR = 'ORDERS_FILTER_SELECT_CONTRACTOR'
export const ORDERS_FILTER_SET_DAYS = 'ORDERS_FILTER_SET_DAYS'
export const ORDERS_FILTER_SET_DUE_DATE = 'ORDERS_FILTER_SET_DUE_DATE'
export const ORDERS_FILTER_SELECT_COLOR_TYPES =
  'ORDERS_FILTER_SELECT_COLOR_TYPES'
export const ORDERS_FILTER_SELECT_VARNISH_TYPE =
  'ORDERS_FILTER_SELECT_VARNISH_TYPE'
export const ORDERS_FILTER_SELECT_PAPER_SIZES =
  'ORDERS_FILTER_SELECT_PAPER_SIZES'
export const ORDERS_FILTER_SELECT_PAPER_TYPE_GROUPS =
  'ORDERS_FILTER_SELECT_PAPER_TYPE_GROUPS'
export const ORDERS_FILTER_SELECT_PAPER_WEIGHTS =
  'ORDERS_FILTER_SELECT_PAPER_WEIGHTS'
export const ORDERS_FILTER_SELECT_PAPER_TYPES =
  'ORDERS_FILTER_SELECT_PAPER_TYPES'
export const ORDERS_FILTER_SELECT_CUTTING_PATTERN =
  'ORDERS_FILTER_SELECT_CUTTING_PATTERN'
export const ORDERS_FILTER_SET_EVENT_DESCRIPTION =
  'ORDERS_FILTER_SET_EVENT_DESCRIPTION'
export const ORDERS_FILTER_SELECT_MANAGER = 'ORDERS_FILTER_SELECT_MANAGER'
export const ORDERS_CLEAR_FILTER = 'ORDERS_CLEAR_FILTER'
// OTHER
export const ORDERS_STOP_UPDATING = 'ORDERS_STOP_UPDATING'
export const ORDERS_SORT = 'ORDERS_SORT'
export const ORDERS_SET_ERRORS = 'ORDERS_SET_ERRORS'
export const ORDERS_SET_QUERY_SETTINGS = 'ORDERS_SET_QUERY_SETTINGS'
export const ORDERS_CLEAR = 'ORDERS_CLEAR'
export const ORDERS_CLEAR_REDIRECT = 'ORDERS_CLEAR_REDIRECT'
export const ORDERS_UPDATE_ITEM = 'ORDERS_UPDATE_ITEM'
export const ORDERS_UPDATE_ITEM_EVENT = 'ORDERS_UPDATE_ITEM_EVENT'
export const ORDERS_INCREASE_NEW_ITEMS = 'ORDERS_INCREASE_NEW_ITEMS'
export const ORDERS_SET_ADVANCED_FILTERS_UPDATED =
  'ORDERS_SET_ADVANCED_FILTERS_UPDATED'

/**
 * FETCH ORDERS - INFINITE LOADING
 * * Get orders list
 */
export const fetchOrdersInfinite =
  ({ startIndex, stopIndex, onSuccess = () => {}, onError = () => {} }) =>
  async (dispatch, getState) => {
    // Get current state
    const {
      orders: { filter, sorting },
    } = getState()

    defaultRequest({
      request: () =>
        api.get(config.api.orders.root, {
          params: {
            start_index: startIndex,
            stop_index: stopIndex,
            search: filter.keyword,
            status: filter.status,
            years: filter.years,
            contractor_id: filter.contractor,
            manager_id: filter.manager,
            days: filter.days,
            due_date: filter.due_date,
            color_type_ids: filter.color_types,
            varnish_type_id: filter.varnish_type,
            paper_size_ids: filter.paper_sizes,
            paper_type_group_id: filter.paper_type_group,
            paper_weights: filter.paper_weights,
            paper_type_ids: filter.paper_types,
            cutting_pattern_id: filter.cutting_pattern,
            event_description: filter.event_description,
            sort: sorting.column,
            direction: sorting.direction,
          },
        }),
      beforeRequest: () => dispatch({ type: ORDERS_LOAD_REQUEST }),
      onSuccess: ({ data }) => {
        onSuccess()
        // Dispatch success action
        dispatch({
          type: ORDERS_LOAD_SUCCESS,
          items: data.items,
          total: data.total,
        })
      },
      onError: () => {
        onError()
        dispatch({ type: ORDERS_LOAD_FAILURE })
      },
      onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
      onUnknownError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_fetch'), 'error'),
    })
  }

/**
 * EXPORT ORDERS - INFINITE LOADING
 * * Export orders list
 */
export const exportOrders =
  ({
    beforeRequest = () => {},
    afterRequest = () => {},
    onSuccess = () => {},
    onError = () => {},
  }) =>
  async (dispatch, getState) => {
    // Get current state
    const {
      orders: { filter, sorting },
    } = getState()

    defaultRequest({
      request: () =>
        api.get(`${config.api.orders.root}/export`, {
          responseType: 'blob',
          params: {
            search: filter.keyword,
            status: filter.status,
            years: filter.years,
            contractor_id: filter.contractor,
            manager_id: filter.manager,
            days: filter.days,
            due_date: filter.due_date,
            color_type_ids: filter.color_types,
            varnish_type_id: filter.varnish_type,
            paper_size_ids: filter.paper_sizes,
            paper_type_group_id: filter.paper_type_group,
            paper_weights: filter.paper_weights,
            paper_type_ids: filter.paper_types,
            cutting_pattern_id: filter.cutting_pattern,
            event_description: filter.event_description,
            sort: sorting.column,
            direction: sorting.direction,
          },
        }),
      beforeRequest,
      afterRequest,
      onSuccess,
      onError,
      onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
      onUnknownError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_fetch'), 'error'),
    })
  }

/**
 * FETCH ORDERS
 * * Get orders list
 */
export const fetchOrders = () => async (dispatch, getState) => {
  // Get current state
  const {
    orders: { filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(config.api.orders.sheet, {
        params: {
          search: filter.keyword,
          status: filter.status,
          years: filter.years,
          contractor_id: filter.contractor,
          manager_id: filter.manager,
          days: filter.days,
          due_date: filter.due_date,
          color_type_ids: filter.color_types,
          varnish_type_id: filter.varnish_type,
          paper_size_id: filter.paper_size,
          paper_type_group_id: filter.paper_type_group,
          paper_weights: filter.paper_weights,
          paper_type_id: filter.paper_type,
          cutting_pattern_id: filter.cutting_pattern,
          event_description: filter.event_description,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: ORDERS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Dispatch success action
      dispatch({ type: ORDERS_FETCH_SUCCESS, items: response.data })
    },
    onError: () => dispatch({ type: ORDERS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('orders:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE ORDER
 * * Create order and display validation errors
 */
export const createOrder =
  ({
    contractor,
    order_items,
    description,
    price_cents,
    price_currency,
    price_annotation,
    prepress_annotation,
    order_on,
    due_date_on,
    due_date_annotation,
    quantity,
    quantity_annotation,
    address,
    is_personal_collection,
    is_address_not_specified,
    packing_annotation,
    printing_annotation,
    printing_events,
    basic_events,
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.orders.root, {
          contractor_id: contractor || null,
          order_items,
          description,
          price_cents: price_cents ? price_cents * 100 : 0,
          price_currency: price_currency || null,
          price_annotation,
          prepress_annotation,
          order_on: order_on || null,
          due_date_on: due_date_on || null,
          due_date_annotation,
          quantity: quantity || 0,
          quantity_annotation,
          address_id: address?.value || null,
          is_personal_collection,
          is_address_not_specified,
          packing_annotation,
          printing_annotation,
          printing_events,
          basic_events,
        }),
      beforeRequest: () => dispatch({ type: ORDERS_CREATE_REQUEST }),
      onSuccess: ({ data: { id } }) => {
        // Display successfull message
        displayAlert(i18n.t('orders:alerts.successfully_created'), 'success')
        // Disable loading
        dispatch({ type: ORDERS_CREATE_SUCCESS, id })
        // Close modal and reset errors
        dispatch({ type: ORDERS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: ORDERS_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: ORDERS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_create'), 'error'),
    })

/**
 * UPDATE ORDER
 * * Update order and display validation errors
 */
export const updateOrder =
  ({
    id,
    contractor,
    order_items,
    description,
    price_cents,
    price_currency,
    price_annotation,
    prepress_annotation,
    order_on,
    due_date_on,
    due_date_annotation,
    quantity,
    quantity_annotation,
    address,
    is_personal_collection,
    is_address_not_specified,
    packing_annotation,
    printing_annotation,
    printing_events,
    basic_events,
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.orders.root}/${id}`, {
          contractor_id: contractor || null,
          order_items,
          description,
          price_cents: price_cents ? price_cents * 100 : 0,
          price_currency,
          price_annotation,
          prepress_annotation,
          order_on,
          due_date_on,
          due_date_annotation,
          quantity,
          quantity_annotation,
          address_id: address?.value || null,
          is_personal_collection,
          is_address_not_specified,
          packing_annotation,
          printing_annotation,
          printing_events,
          basic_events,
        }),
      beforeRequest: () => dispatch({ type: ORDERS_UPDATE_REQUEST }),
      onSuccess: ({ data: { id: responseId } }) => {
        // Display successfull message
        displayAlert(i18n.t('orders:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: ORDERS_UPDATE_SUCCESS, id: responseId })
        // Close modal and reset errors
        dispatch({ type: ORDERS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: ORDERS_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: ORDERS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_update'), 'error'),
    })

/**
 * UPDATE ORDER STATUS
 * * Update order status
 */
export const updateOrderStatus =
  ({
    id,
    event,
    beforeRequest = () => {},
    afterRequest = () => {},
    onError = () => {},
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.orders.root}/${id}/status`, { event }),
      beforeRequest,
      onSuccess: ({ data }) => {
        // Display successfull message
        displayAlert(
          i18n.t('orders:alerts.successfully_updated_status'),
          'success'
        )
        // Update order item
        dispatch({ type: ORDERS_UPDATE_ITEM, data })
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_update_status'), 'error'),
      onUnknownError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_update_status'), 'error'),
      afterRequest,
      onError,
    })

/**
 * UPDATE ORDER ITEM
 * * Update order item
 */
export const updateOrderItem = (data) => (dispatch, getState) => {
  const {
    orders: { items },
    session: { currentUser },
  } = getState()
  const { order, sender } = data

  if (
    sender.id !== currentUser.id &&
    items.map((item) => item.id).includes(order.id)
  ) {
    dispatch({ type: ORDERS_UPDATE_ITEM, data: order })
    displayUserToast(i18n.t('orders:alerts.asynchronously_updated'), sender, {
      order_number: order.order_number,
    })
  }
}

/**
 * UPDATE AFTER UPDATE ORDER ITEM STATUS
 * * Update after update order item status
 */
export const updateAfterUpdateStatus = (data) => (dispatch, getState) => {
  const {
    orders: { items },
    session: { currentUser },
  } = getState()
  const { order, sender, prev_status } = data

  if (
    sender.id !== currentUser.id &&
    items.map((item) => item.id).includes(order.id)
  ) {
    dispatch({ type: ORDERS_UPDATE_ITEM, data: { ...order, updating: true } })

    const options = {
      order_number: order.order_number,
      order_status: {
        prev: prev_status,
        current: order.status,
      },
    }
    displayUserToast(
      i18n.t('orders:alerts.asynchronously_updated_status'),
      sender,
      options
    )
  }
}

/**
 * UPDATE AFTER UPDATE ORDER ITEM EVENT STATUS
 * * Update after update order item event status
 */
export const updateAfterUpdateEventStatus = (data) => (dispatch, getState) => {
  const {
    orders: { items },
    session: { currentUser },
  } = getState()
  const { event, sender, prev_status } = data

  if (
    sender.id !== currentUser.id &&
    items.map((item) => item.order_number).includes(event.order_number)
  ) {
    dispatch({ type: ORDERS_UPDATE_ITEM_EVENT, data: event, updating: true })

    const options = {
      order_number: event.order_number,
      event_status: {
        prev: prev_status,
        current: event.status,
      },
    }
    displayUserToast(
      i18n.t('orders:alerts.asynchronously_updated_event_status', {
        event_name: event.event_type.name,
      }),
      sender,
      options
    )
  }
}

/**
 * UPDATE AFTER UPDATE ORDER ITEM EVENT MACHINE
 * * Update after update order item event machine
 */
export const updateAfterUpdateEventMachine = (data) => (dispatch, getState) => {
  const {
    orders: { items },
    session: { currentUser },
  } = getState()
  const { event, sender, source_machine_name } = data

  if (
    sender.id !== currentUser.id &&
    items.map((item) => item.order_number).includes(event.order_number)
  ) {
    dispatch({ type: ORDERS_UPDATE_ITEM_EVENT, data: event, updating: true })

    const target_machine_name = event.machine?.name
    const toastVariables = {
      order_number: event.order_number,
      source_machine_name,
      target_machine_name,
    }
    displayUserToast(
      i18n.t(`orders:alerts.asynchronously_updated_event_machine`, {
        event_name: event.event_type.name,
      }),
      sender,
      { order_number: event.order_number, event_machine: toastVariables }
    )
  }
}

/**
 * UPDATE ORDER DUE DATE
 * * Update order due date
 */
export const updateDueDate =
  ({
    id,
    value,
    beforeRequest = () => {},
    afterRequest = () => {},
    onError = () => {},
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.orders.root}/${id}/due_date`, {
          due_date: value,
        }),
      beforeRequest,
      onSuccess: ({ data }) => {
        // Display successfull message
        displayAlert(
          i18n.t('orders:alerts.successfully_updated_due_date'),
          'success'
        )
        // Update order item
        dispatch({ type: ORDERS_UPDATE_ITEM, data })
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_update_due_date'), 'error'),
      onUnknownError: () =>
        displayAlert(i18n.t('orders:alerts.cannot_update_due_date'), 'error'),
      afterRequest,
      onError,
    })

/**
 * UPDATE AFTER UPDATE ORDER ITEM DUE DATE
 * * Update after update order item due date
 */
export const updateAfterUpdateDueDate = (data) => (dispatch, getState) => {
  const {
    orders: { items },
    session: { currentUser },
  } = getState()
  const { order, sender, prev_due_date } = data

  if (
    sender.id !== currentUser.id &&
    items.map((item) => item.order_number).includes(order.order_number)
  ) {
    dispatch({ type: ORDERS_UPDATE_ITEM, data: order, updating: true })

    const options = {
      order_number: order.order_number,
      due_date: {
        prev: prev_due_date,
        current: order.due_date_on,
      },
    }
    displayUserToast(
      i18n.t('orders:alerts.asynchronously_updated_due_date'),
      sender,
      options
    )
  }
}

/**
 * SET ADVANCED FILTERS
 * * Set advanced filters in orders list
 */
export const setAdvancedFilters =
  ({
    days,
    due_date,
    color_type_ids,
    varnish_type_id,
    paper_size_ids,
    paper_group_id,
    paper_weights,
    paper_type_ids,
    cutting_pattern_id,
    event_description,
  }) =>
  (dispatch) => {
    dispatch({ type: ORDERS_FILTER_SET_DAYS, days })
    dispatch({ type: ORDERS_FILTER_SET_DUE_DATE, due_date })
    dispatch({ type: ORDERS_FILTER_SELECT_COLOR_TYPES, ids: color_type_ids })
    dispatch({ type: ORDERS_FILTER_SELECT_VARNISH_TYPE, id: varnish_type_id })
    dispatch({ type: ORDERS_FILTER_SELECT_PAPER_SIZES, ids: paper_size_ids })
    dispatch({
      type: ORDERS_FILTER_SELECT_PAPER_TYPE_GROUPS,
      id: paper_group_id,
    })
    dispatch({
      type: ORDERS_FILTER_SELECT_PAPER_WEIGHTS,
      values: paper_weights,
    })
    dispatch({ type: ORDERS_FILTER_SELECT_PAPER_TYPES, ids: paper_type_ids })
    dispatch({
      type: ORDERS_FILTER_SELECT_CUTTING_PATTERN,
      id: cutting_pattern_id,
    })
    dispatch({
      type: ORDERS_FILTER_SET_EVENT_DESCRIPTION,
      value: event_description,
    })
    dispatch({
      type: ORDERS_SET_ADVANCED_FILTERS_UPDATED,
      value: _.uniqueId('af'),
    })
  }
