import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'
import config from 'config'

/* ---------------------------------- Icons --------------------------------- */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobeEurope,
  faMapMarkerAlt,
  faTh,
  faReceipt,
  faKey,
  faUsers,
  faScroll,
  faExpand,
  faPalette,
  faSprayCan,
  faCut,
  faServer,
  faHandPaper,
  faLayerGroup,
  faCube,
  faUserTie,
  faTruckLoading,
  faCodeBranch,
  faPlus,
  faUserLock,
  faTable,
  faShieldAlt,
  faBook,
  faUserHelmetSafety,
  faFlag,
  faChartUser,
  faClock,
  faInboxOut,
  faWarehouse,
  faSparkles,
} from '@fortawesome/pro-solid-svg-icons'

/* ---------------------------------- Hooks --------------------------------- */
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

/* --------------------------------- Images --------------------------------- */
import Logo from 'assets/images/app-logo-dark.svg'

/* ------------------------------- Components ------------------------------- */
import { Link, NavLink } from 'react-router-dom'
import Submenu from './Submenu'

/* -------------------------------------------------------------------------- */
/*                               Aside Component                              */
/* -------------------------------------------------------------------------- */
const Aside = () => {
  const [
    canReadOrder,
    canCreateOrder,
    canReadContractors,
    canReadAddress,
    canReadPaperDemands,
    canReadMaterials,
    canReadDeliveryNotes,
    canCreateDeliveryNote,
    canReadPaperSizes,
    canReadPaperTypeGroups,
    canReadPaperTypes,
    canReadColorTypes,
    canReadVarnishTypes,
    canReadCuttingPatterns,
    canReadMachines,
    canReadProcessTypes,
    canReadEmployees,
    canReadEmployeeTimeEntries,
    canReadIssues,
    canReadUsers,
    canReadRoles,
    canReadRegistrationKeys,
    canReadVersions,
    canReadStorages,
    canReadPaperStocks,
  ] = useAuth([
    'orders.read',
    'orders.create',
    'contractors.read',
    'addresses.read',
    'paper_demands.read',
    'materials.read',
    'delivery_notes.read',
    'delivery_notes.create',
    'paper_sizes.read',
    'paper_type_groups.read',
    'paper_types.read',
    'color_types.read',
    'varnish_types.read',
    'cutting_patterns.read',
    'machines.read',
    'event_types.read',
    'employees.read',
    'employee_time_entries.read',
    'issues.read',
    'users.read',
    'roles.read',
    'registration_keys.read',
    'versions.read',
    'storages.read',
    'paper_stocks.read',
  ])
  const [t] = useTranslation()

  const productionEnv = !['dev', 'staging'].includes(config.env)

  /* --------------------------------- Render --------------------------------- */
  return (
    <>
      <div className="gd-aside-spacer"></div>
      <aside className="gd-aside">
        <div className="tw-relative tw-flex tw-justify-center tw-pt-2">
          <div className="tw-absolute tw-left-0 tw-right-0 tw-flex tw-justify-center -tw-bottom-3">
            <div
              className={cn(
                'tw-px-1 tw-rounded-lg tw-bg-slate-950 tw-text-xxs tw-pointer-events-none tw-flex tw-gap-x-1',
                config.env === 'dev' &&
                  'tw-ring-1 tw-ring-cyan-500 tw-text-cyan-500',
                config.env === 'staging' &&
                  'tw-ring-1 tw-ring-yellow-500 tw-text-yellow-500',
                productionEnv && 'tw-ring-1 tw-ring-slate-500 tw-text-slate-400'
              )}
            >
              {!productionEnv && <span>{config.env}</span>}
              <span>{config.version}</span>
            </div>
          </div>
          <Link to="/">
            <img src={Logo} alt={t('title')} style={{ height: '45px' }} />
          </Link>
        </div>
        <div className="gd-aside-menu">
          <ul className="gd-aside-menu__nav">
            <li className="gd-aside-menu__item">
              <NavLink exact to="/" className="gd-aside-menu__link">
                <FontAwesomeIcon
                  icon={faGlobeEurope}
                  className="gd-aside-menu__icon"
                />
                <span className="gd-aside-menu__text">
                  {t('menu.dashboard')}
                </span>
              </NavLink>
            </li>
            {canReadOrder && (
              <Submenu toggleIcon={faReceipt} toggleText={t('orders:title')}>
                <li className="gd-aside-menu__item">
                  <NavLink
                    to="/orders"
                    className="gd-aside-menu__link--submenu"
                  >
                    <FontAwesomeIcon
                      icon={faTable}
                      className="gd-aside-menu__icon"
                    />
                    <span className="gd-aside-menu__text">
                      {t('orders:sheet.title')}
                    </span>
                  </NavLink>
                </li>
                {canCreateOrder && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/orders/new"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('orders:add.title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                <span></span>
              </Submenu>
            )}
            {canReadPaperStocks && (
              <li className="gd-aside-menu__item">
                <NavLink
                  exact
                  to="/warehouse/stocks/paper"
                  className="gd-aside-menu__link"
                >
                  <FontAwesomeIcon
                    icon={faWarehouse}
                    className="gd-aside-menu__icon"
                  />
                  <span className="gd-aside-menu__text">
                    {t('menu.storages')}
                  </span>
                </NavLink>
              </li>
            )}
            {canReadPaperDemands && (
              <Submenu toggleIcon={faLayerGroup} toggleText={t('menu.demands')}>
                {canReadContractors && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/paper_demands"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faScroll}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('paper_demands:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                <span></span>
              </Submenu>
            )}
            {canReadMaterials && (
              <Submenu toggleIcon={faCube} toggleText={t('menu.materials')}>
                {canReadMaterials && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/materials/paper"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faScroll}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('materials:paper:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                <span></span>
              </Submenu>
            )}
            {(canReadDeliveryNotes || canCreateDeliveryNote) && (
              <Submenu
                toggleIcon={faTruckLoading}
                toggleText={t('menu.logistics')}
              >
                {canReadDeliveryNotes && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/delivery_notes"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faInboxOut}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('delivery_notes:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canCreateDeliveryNote && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/delivery_notes/new"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('delivery_notes:add.title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                <span></span>
              </Submenu>
            )}
            {(canReadContractors || canReadAddress) && (
              <Submenu toggleIcon={faUserTie} toggleText={t('menu.crm')}>
                {canReadContractors && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/contractors"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('contractors:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadAddress && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/addresses"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('addresses:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
              </Submenu>
            )}
            {canReadIssues && (
              <li className="gd-aside-menu__item">
                <NavLink exact to="/issues" className="gd-aside-menu__link">
                  <FontAwesomeIcon
                    icon={faFlag}
                    className="gd-aside-menu__icon"
                  />
                  <span className="gd-aside-menu__text">
                    {t('issues:title')}
                  </span>
                </NavLink>
              </li>
            )}
            {(canReadPaperSizes ||
              canReadPaperTypeGroups ||
              canReadPaperTypes ||
              canReadColorTypes ||
              canReadVarnishTypes ||
              canReadCuttingPatterns ||
              canReadMachines ||
              canReadProcessTypes) && (
              <Submenu toggleIcon={faTh} toggleText={t('menu.components')}>
                {canReadPaperSizes && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/paper_sizes"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faExpand}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('paper_sizes:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadPaperTypeGroups && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/paper_type_groups"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faLayerGroup}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('paper_type_groups:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadPaperTypes && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/paper_types"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faScroll}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('paper_types:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadColorTypes && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/color_types"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faPalette}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('color_types:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadVarnishTypes && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/varnish_types"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faSprayCan}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('varnish_types:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadCuttingPatterns && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/cutting_patterns"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faCut}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('cutting_patterns:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadMachines && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/machines"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faServer}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('machines:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadProcessTypes && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/process_types"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faHandPaper}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('process_types:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
              </Submenu>
            )}
            {(canReadEmployees || canReadEmployeeTimeEntries) && (
              <Submenu
                toggleIcon={faChartUser}
                toggleText={t('menu.management')}
              >
                {canReadEmployees && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/employees"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faUserHelmetSafety}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('employees:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadEmployeeTimeEntries && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/employee_time_entries"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faClock}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('employee_time_entries:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadStorages && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/storages"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faWarehouse}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('storages:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                <span></span>
              </Submenu>
            )}
            {(canReadUsers || canReadRoles || canReadRegistrationKeys) && (
              <Submenu
                toggleIcon={faShieldAlt}
                toggleText={t('menu.administration')}
              >
                {canReadUsers && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/users"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faUsers}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('users:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadRoles && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/roles"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faUserLock}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('roles:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadRegistrationKeys && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/registration_keys"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faKey}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('registration_keys:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
                {canReadVersions && (
                  <li className="gd-aside-menu__item">
                    <NavLink
                      to="/versions"
                      className="gd-aside-menu__link--submenu"
                    >
                      <FontAwesomeIcon
                        icon={faCodeBranch}
                        className="gd-aside-menu__icon"
                      />
                      <span className="gd-aside-menu__text">
                        {t('versions:title')}
                      </span>
                    </NavLink>
                  </li>
                )}
              </Submenu>
            )}
            <Submenu toggleIcon={faBook} toggleText={t('menu.docs')}>
              <li className="gd-aside-menu__item">
                <NavLink
                  to="/changelog"
                  className="gd-aside-menu__link--submenu"
                >
                  <FontAwesomeIcon
                    icon={faSparkles}
                    className="gd-aside-menu__icon"
                  />
                  <span className="gd-aside-menu__text">
                    {t('changelog:title')}
                  </span>
                </NavLink>
              </li>
              {canReadPaperDemands && (
                <li className="gd-aside-menu__item">
                  <NavLink
                    to="/docs/paper_demands"
                    className="gd-aside-menu__link--submenu"
                  >
                    <FontAwesomeIcon
                      icon={faScroll}
                      className="gd-aside-menu__icon"
                    />
                    <span className="gd-aside-menu__text">
                      {t('paper_demands:title')}
                    </span>
                  </NavLink>
                </li>
              )}
              <li></li>
            </Submenu>
          </ul>
        </div>
      </aside>
    </>
  )
}

export default Aside
