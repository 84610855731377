import config from 'config'
import {
  PAPER_TYPE_GROUPS_FETCH_REQUEST,
  PAPER_TYPE_GROUPS_FETCH_SUCCESS,
  PAPER_TYPE_GROUPS_FETCH_FAILURE,
  PAPER_TYPE_GROUPS_CREATE_REQUEST,
  PAPER_TYPE_GROUPS_CREATE_SUCCESS,
  PAPER_TYPE_GROUPS_CREATE_FAILURE,
  PAPER_TYPE_GROUPS_UPDATE_REQUEST,
  PAPER_TYPE_GROUPS_UPDATE_SUCCESS,
  PAPER_TYPE_GROUPS_UPDATE_FAILURE,
  PAPER_TYPE_GROUPS_DELETE_REQUEST,
  PAPER_TYPE_GROUPS_DELETE_SUCCESS,
  PAPER_TYPE_GROUPS_DELETE_FAILURE,
  PAPER_TYPE_GROUPS_TOGGLE_EDIT_MODAL,
  PAPER_TYPE_GROUPS_TOGGLE_DELETE_MODAL,
  PAPER_TYPE_GROUPS_FILTER_SET_KEYWORD,
  PAPER_TYPE_GROUPS_CLEAR_FILTER,
  PAPER_TYPE_GROUPS_SORT,
  PAPER_TYPE_GROUPS_SET_ERRORS,
  PAPER_TYPE_GROUPS_SET_CURRENT_PAGE,
  PAPER_TYPE_GROUPS_SET_PAGINATION_PER_PAGE,
  PAPER_TYPE_GROUPS_SET_QUERY_SETTINGS,
  PAPER_TYPE_GROUPS_CLEAR,
} from '../actions/paper_type_groups'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  filter: {
    keyword: '',
  },
  sorting: {
    column: 'paper_type_groups.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
}

const paperTypeGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    /* -------------------- PAPER_TYPE_GROUPS_FETCH_REQUEST -------------------- */
    case PAPER_TYPE_GROUPS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    /* -------------------- PAPER_TYPE_GROUPS_FETCH_SUCCESS -------------------- */
    case PAPER_TYPE_GROUPS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    /* -------------------- PAPER_TYPE_GROUPS_FETCH_FAILURE -------------------- */
    case PAPER_TYPE_GROUPS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    /* -------------------- PAPER_TYPE_GROUPS_CREATE_REQUEST ------------------- */
    /* -------------------- PAPER_TYPE_GROUPS_UPDATE_REQUEST ------------------- */
    /* -------------------- PAPER_TYPE_GROUPS_DELETE_REQUEST ------------------- */
    case PAPER_TYPE_GROUPS_CREATE_REQUEST:
    case PAPER_TYPE_GROUPS_UPDATE_REQUEST:
    case PAPER_TYPE_GROUPS_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    /* -------------------- PAPER_TYPE_GROUPS_CREATE_SUCCESS ------------------- */
    /* -------------------- PAPER_TYPE_GROUPS_UPDATE_SUCCESS ------------------- */
    /* -------------------- PAPER_TYPE_GROUPS_DELETE_SUCCESS ------------------- */
    /* -------------------- PAPER_TYPE_GROUPS_CREATE_FAILURE ------------------- */
    /* -------------------- PAPER_TYPE_GROUPS_UPDATE_FAILURE ------------------- */
    /* -------------------- PAPER_TYPE_GROUPS_DELETE_FAILURE ------------------- */
    case PAPER_TYPE_GROUPS_CREATE_SUCCESS:
    case PAPER_TYPE_GROUPS_UPDATE_SUCCESS:
    case PAPER_TYPE_GROUPS_DELETE_SUCCESS:
    case PAPER_TYPE_GROUPS_CREATE_FAILURE:
    case PAPER_TYPE_GROUPS_UPDATE_FAILURE:
    case PAPER_TYPE_GROUPS_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    /* ------------------ PAPER_TYPE_GROUPS_TOGGLE_EDIT_MODAL ------------------ */
    case PAPER_TYPE_GROUPS_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    /* ----------------- PAPER_TYPE_GROUPS_TOGGLE_DELETE_MODAL ----------------- */
    case PAPER_TYPE_GROUPS_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    /* ------------------ PAPER_TYPE_GROUPS_FILTER_SET_KEYWORD ----------------- */
    case PAPER_TYPE_GROUPS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    /* --------------------- PAPER_TYPE_GROUPS_CLEAR_FILTER -------------------- */
    case PAPER_TYPE_GROUPS_CLEAR_FILTER:
      return {
        ...state,
        filter: {
          keyword: '',
        },
      }
    /* ------------------------- PAPER_TYPE_GROUPS_SORT ------------------------ */
    case PAPER_TYPE_GROUPS_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    /* ---------------------- PAPER_TYPE_GROUPS_SET_ERRORS --------------------- */
    case PAPER_TYPE_GROUPS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    /* ------------------- PAPER_TYPE_GROUPS_SET_CURRENT_PAGE ------------------ */
    case PAPER_TYPE_GROUPS_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    /* --------------- PAPER_TYPE_GROUPS_SET_PAGINATION_PER_PAGE --------------- */
    case PAPER_TYPE_GROUPS_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    /* ------------------ PAPER_TYPE_GROUPS_SET_QUERY_SETTINGS ----------------- */
    case PAPER_TYPE_GROUPS_SET_QUERY_SETTINGS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        filter: {
          ...state.filter,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    /* ------------------------ PAPER_TYPE_GROUPS_CLEAR ------------------------ */
    case PAPER_TYPE_GROUPS_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default paperTypeGroupsReducer
