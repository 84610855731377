import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { GLOBAL_CLEAR_EXPIRATION_WARNING } from 'redux/actions/global'
import { signOut } from 'redux/actions/session'

import TimeAgoWrapper from './TimeAgoWrapper'

const ExpirationWarningModal = ({ isOpen }) => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.session.currentUser)
  const [t] = useTranslation()

  if (!currentUser) {
    return null
  }

  const toggle = () => {
    dispatch({ type: GLOBAL_CLEAR_EXPIRATION_WARNING })
  }

  const handleSignOut = () => {
    toggle()
    dispatch(signOut())
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        {t('modals:expiration_warning.title')}
      </ModalHeader>
      <ModalBody>
        <Trans i18nKey="modals:expiration_warning.content">
          <TimeAgoWrapper expirationTime={currentUser.session_expires_at} />
        </Trans>
        <div className="alert mt-3">
          <FontAwesomeIcon icon={faInfo} className="me-2" />{' '}
          {t('modals:expiration_warning.tip')}
        </div>
        <span className="text-muted">
          {t('modals:expiration_warning.description')}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleSignOut}>
          {t('actions.sign_out')}
        </Button>
        <Button color="warning" onClick={toggle}>
          {t('actions.skip')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ExpirationWarningModal
