import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import config from 'config'

const baseQuery = fetchBaseQuery({
  baseUrl: config.api.fullUrl,
  prepareHeaders: (headers) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem('token')
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 })

const baseQueryWithPagination = async (args, api, extraOptions = {}) => {
  const result = await baseQueryWithRetry(args, api, extraOptions)

  if (result.data) {
    const { headers } = result.meta.response

    if (headers.get('X-Total')) {
      result.data = {
        items: result.data,
        pagination: {
          total: parseInt(headers.get('X-Total'), 10),
          page: parseInt(headers.get('X-Page'), 10),
          per_page: parseInt(headers.get('X-Per-Page'), 10),
        },
      }
    }
  }

  return result
}

const api = createApi({
  baseQuery: baseQueryWithPagination,
  endpoints: () => ({}),
})

export default api
