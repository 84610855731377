import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

/* ---------------------------------- FETCH --------------------------------- */
export const EMPLOYEES_FETCH_REQUEST = 'EMPLOYEES_FETCH_REQUEST'
export const EMPLOYEES_FETCH_SUCCESS = 'EMPLOYEES_FETCH_SUCCESS'
export const EMPLOYEES_FETCH_FAILURE = 'EMPLOYEES_FETCH_FAILURE'

/* --------------------------------- UPDATE --------------------------------- */
export const EMPLOYEES_UPDATE_REQUEST = 'EMPLOYEES_UPDATE_REQUEST'
export const EMPLOYEES_UPDATE_SUCCESS = 'EMPLOYEES_UPDATE_SUCCESS'
export const EMPLOYEES_UPDATE_FAILURE = 'EMPLOYEES_UPDATE_FAILURE'

/* --------------------------------- MODALS --------------------------------- */
export const EMPLOYEES_TOGGLE_EDIT_MODAL = 'EMPLOYEES_TOGGLE_EDIT_MODAL'

/* --------------------------------- FILTERS -------------------------------- */
export const EMPLOYEES_FILTER_SET_KEYWORD = 'EMPLOYEES_FILTER_SET_KEYWORD'
export const EMPLOYEES_CLEAR_FILTER = 'EMPLOYEES_CLEAR_FILTER'
export const EMPLOYEES_SELECT_ROLE = 'EMPLOYEES_SELECT_ROLE'
export const EMPLOYEES_FILTER_SELECT_MACHINE = 'EMPLOYEES_FILTER_SELECT_MACHINE'
export const EMPLOYEES_PRODUCTIVITY_SET_MACHINES_LIST =
  'EMPLOYEES_PRODUCTIVITY_SET_MACHINES_LIST'
export const EMPLOYEES_PRODUCTIVITY_SET_EMPLOYEE =
  'EMPLOYEES_PRODUCTIVITY_SET_EMPLOYEE'

/* ---------------------------------- Other --------------------------------- */
export const EMPLOYEES_SORT = 'EMPLOYEES_SORT'
export const EMPLOYEES_SET_ERRORS = 'EMPLOYEES_SET_ERRORS'
export const EMPLOYEES_SET_CURRENT_PAGE = 'EMPLOYEES_SET_CURRENT_PAGE'
export const EMPLOYEES_SET_PAGINATION_PER_PAGE =
  'EMPLOYEES_SET_PAGINATION_PER_PAGE'
export const EMPLOYEES_SET_QUERY_SETTINGS = 'EMPLOYEES_SET_QUERY_SETTINGS'
export const EMPLOYEES_CLEAR = 'EMPLOYEES_CLEAR'

/* -------------------------------------------------------------------------- */
/*                               FETCH EMPLOYEES                              */
/* -------------------------------------------------------------------------- */
/* ------------------- Get employees list with pagination ------------------- */
export const fetchEmployees = () => async (dispatch, getState) => {
  // Get current state
  const {
    employees: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.employees.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          role_id: filter.role,
          machine_id: filter.machine,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: EMPLOYEES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: EMPLOYEES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: EMPLOYEES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('employees:alerts.cannot_fetch_list'), 'error'),
  })
}

/* -------------------------------------------------------------------------- */
/*                               UPDATE EMPLOYEE                              */
/* -------------------------------------------------------------------------- */
/* ---------------- Update employee and reload employees list --------------- */
export const updateEmployee =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.put(`${config.api.employees.root}/${id}`, params),
      beforeRequest: () => dispatch({ type: EMPLOYEES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('employees:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: EMPLOYEES_UPDATE_SUCCESS })
        // Reload employees list
        dispatch(fetchEmployees())
        // Close modal and reset errors
        dispatch({ type: EMPLOYEES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: EMPLOYEES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: EMPLOYEES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: EMPLOYEES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('employees:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: EMPLOYEES_TOGGLE_EDIT_MODAL, state: false }),
    })

/* -------------------------------------------------------------------------- */
/*                            SET PAGINATION LIMIT                            */
/* -------------------------------------------------------------------------- */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: EMPLOYEES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchEmployees())
}
