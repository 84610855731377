import React, { useRef } from 'react'

// Utils
import polishStrings from 'react-timeago/lib/language-strings/pl'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

// Components
import { UncontrolledTooltip } from 'reactstrap'
import ReactTimeAgo from 'react-timeago'
import Date from 'components/elements/basic/Date'

const formatter = buildFormatter(polishStrings)

const TimeAgo = ({ date = null, tooltip = false }) => {
  const tooltipRef = useRef()

  return (
    <>
      <span ref={tooltipRef}>
        <ReactTimeAgo date={date} formatter={formatter} title="" />
      </span>
      {tooltip && (
        <UncontrolledTooltip
          placement="bottom"
          target={tooltipRef}
          boundariesElement="table"
        >
          <Date date={date} format="D MMM YYYY HH:mm:ss" />
        </UncontrolledTooltip>
      )}
    </>
  )
}

export default TimeAgo
