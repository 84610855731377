import { useSelector } from 'react-redux'

const useAuth = (keys = []) => {
  const currentUser = useSelector((state) => state.session.currentUser)
  const permissions = currentUser ? currentUser.permissions : false

  const hasPermission = (permission) =>
    permissions && permissions.some((e) => e.key === permission)

  return [...keys.map((key) => hasPermission(key))]
}

export default useAuth
