import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const CUTTING_PATTERNS_FETCH_REQUEST = 'CUTTING_PATTERNS_FETCH_REQUEST'
export const CUTTING_PATTERNS_FETCH_SUCCESS = 'CUTTING_PATTERNS_FETCH_SUCCESS'
export const CUTTING_PATTERNS_FETCH_FAILURE = 'CUTTING_PATTERNS_FETCH_FAILURE'
// CREATE
export const CUTTING_PATTERNS_CREATE_REQUEST = 'CUTTING_PATTERNS_CREATE_REQUEST'
export const CUTTING_PATTERNS_CREATE_SUCCESS = 'CUTTING_PATTERNS_CREATE_SUCCESS'
export const CUTTING_PATTERNS_CREATE_FAILURE = 'CUTTING_PATTERNS_CREATE_FAILURE'
// UPDATE
export const CUTTING_PATTERNS_UPDATE_REQUEST = 'CUTTING_PATTERNS_UPDATE_REQUEST'
export const CUTTING_PATTERNS_UPDATE_SUCCESS = 'CUTTING_PATTERNS_UPDATE_SUCCESS'
export const CUTTING_PATTERNS_UPDATE_FAILURE = 'CUTTING_PATTERNS_UPDATE_FAILURE'
// DELETE
export const CUTTING_PATTERNS_DELETE_REQUEST = 'CUTTING_PATTERNS_DELETE_REQUEST'
export const CUTTING_PATTERNS_DELETE_SUCCESS = 'CUTTING_PATTERNS_DELETE_SUCCESS'
export const CUTTING_PATTERNS_DELETE_FAILURE = 'CUTTING_PATTERNS_DELETE_FAILURE'
// MODALS
export const CUTTING_PATTERNS_TOGGLE_EDIT_MODAL =
  'CUTTING_PATTERNS_TOGGLE_EDIT_MODAL'
export const CUTTING_PATTERNS_TOGGLE_DELETE_MODAL =
  'CUTTING_PATTERNS_TOGGLE_DELETE_MODAL'
// FILTERS
export const CUTTING_PATTERNS_FILTER_SET_KEYWORD =
  'CUTTING_PATTERNS_FILTER_SET_KEYWORD'
export const CUTTING_PATTERNS_FILTER_SET_PERTINAX_MODE =
  'CUTTING_PATTERNS_FILTER_SET_PERTINAX_MODE'
export const CUTTING_PATTERNS_FILTER_SET_CLEANING_MODE =
  'CUTTING_PATTERNS_FILTER_SET_CLEANING_MODE'
export const CUTTING_PATTERNS_FILTER_SET_PUTTING_MODE =
  'CUTTING_PATTERNS_FILTER_SET_PUTTING_MODE'
export const CUTTING_PATTERNS_CLEAR_FILTER = 'CUTTING_PATTERNS_CLEAR_FILTER'
// Other
export const CUTTING_PATTERNS_SORT = 'CUTTING_PATTERNS_SORT'
export const CUTTING_PATTERNS_SET_ERRORS = 'CUTTING_PATTERNS_SET_ERRORS'
export const CUTTING_PATTERNS_SET_CURRENT_PAGE =
  'CUTTING_PATTERNS_SET_CURRENT_PAGE'
export const CUTTING_PATTERNS_SET_PAGINATION_PER_PAGE =
  'CUTTING_PATTERNS_SET_PAGINATION_PER_PAGE'
export const CUTTING_PATTERNS_SET_QUERY_SETTINGS =
  'CUTTING_PATTERNS_SET_QUERY_SETTINGS'
export const CUTTING_PATTERNS_CLEAR = 'CUTTING_PATTERNS_CLEAR'

/**
 * FETCH CUTTING PATTERNS
 * * Get cutting patterns list with pagination
 */
export const fetchCuttingPatterns = () => async (dispatch, getState) => {
  // Get current state
  const {
    cutting_patterns: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.cutting_patterns.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          pertinax_mode: filter.pertinaxMode,
          cleaning_mode: filter.cleaningMode,
          putting_mode: filter.puttingMode,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: CUTTING_PATTERNS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: CUTTING_PATTERNS_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: CUTTING_PATTERNS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('cutting_patterns:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE CUTTING PATTERN
 * * Create cutting pattern and display validation errors
 */
export const createCuttingPattern =
  ({ data, onClose, onCreate = () => {} }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.post(config.api.cutting_patterns.root, data),
      beforeRequest: () => dispatch({ type: CUTTING_PATTERNS_CREATE_REQUEST }),
      onSuccess: ({ data: responseData }) => {
        // Display successfull message
        displayAlert(
          i18n.t('cutting_patterns:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: CUTTING_PATTERNS_CREATE_SUCCESS })
        // Reload cutting patterns list
        dispatch(fetchCuttingPatterns())
        // Call onCreate method
        onCreate(responseData)
        // Close modal and reset errors
        onClose()
        dispatch({ type: CUTTING_PATTERNS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: CUTTING_PATTERNS_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (responseData) =>
        dispatch({ type: CUTTING_PATTERNS_SET_ERRORS, errors: responseData }),
      onUnknownError: () =>
        displayAlert(i18n.t('cutting_patterns:alerts.cannot_create'), 'error'),
      onUnexpectedError: () => onClose(),
    })

/**
 * UPDATE CUTTING PATTERN
 * * Update cutting pattern and reload cutting patterns list
 */
export const updateCuttingPattern =
  ({
    id,
    internal_id,
    external_id,
    is_pertinax,
    is_cleaning_section,
    is_putting_section,
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.cutting_patterns.root}/${id}`, {
          internal_id,
          external_id,
          is_pertinax,
          is_cleaning_section,
          is_putting_section,
        }),
      beforeRequest: () => dispatch({ type: CUTTING_PATTERNS_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('cutting_patterns:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: CUTTING_PATTERNS_UPDATE_SUCCESS })
        // Reload cutting patterns list
        dispatch(fetchCuttingPatterns())
        // Close modal and reset errors
        dispatch({ type: CUTTING_PATTERNS_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: CUTTING_PATTERNS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: CUTTING_PATTERNS_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: CUTTING_PATTERNS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('cutting_patterns:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: CUTTING_PATTERNS_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE CUTTING PATTERN
 * * Delete cutting pattern and fetch cutting patterns list
 */
export const deleteCuttingPattern = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.cutting_patterns.root}/${id}`),
    beforeRequest: () => dispatch({ type: CUTTING_PATTERNS_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(
        i18n.t('cutting_patterns:alerts.successfully_deleted'),
        'success'
      )
      // Disable loading
      dispatch({ type: CUTTING_PATTERNS_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: CUTTING_PATTERNS_TOGGLE_DELETE_MODAL, state: false })
      // Reload cutting patterns list
      dispatch(fetchCuttingPatterns())
    },
    onError: () => dispatch({ type: CUTTING_PATTERNS_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('cutting_patterns:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: CUTTING_PATTERNS_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: CUTTING_PATTERNS_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchCuttingPatterns())
}
