import { useEffect, useRef } from 'react'
import config from 'config'
import ActionCable from 'actioncable'

const Channel = ({ channel, onReceive, onDisconnect, children }) => {
  const consumer = useRef(null)
  const subscription = useRef(null)

  useEffect(() => {
    const token = localStorage.getItem('token')
    consumer.current = ActionCable.createConsumer(
      `${config.ws.fullUrl}?token=${token}`
    )

    return () => {
      subscription.current.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (
      consumer.current &&
      consumer.current.subscriptions?.subscriptions?.length === 0
    ) {
      subscription.current = consumer.current.subscriptions.create(channel, {
        received: onReceive,
        disconnected: onDisconnect,
      })
    }
  }, [channel, onDisconnect, onReceive])

  return children
}

export default Channel
