import {
  SESSION_SIGN_IN_REQUEST,
  SESSION_SIGN_IN_SUCCESS,
  SESSION_SIGN_IN_FAILURE,
  SESSION_SIGN_OUT,
  SESSION_UPDATE_SETTINGS,
  SESSION_SET_ONLINE_USERS,
  SESSION_ONLINE_USERS_ADD,
  SESSION_ONLINE_USERS_REMOVE,
} from 'redux/actions/session'

const initialState = {
  currentUser: null,
  isLoading: false,
  isSignedIn: false,
  isChecked: false,
  usersOnline: [],
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case SESSION_SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.currentUser,
        isLoading: false,
        isSignedIn: true,
        isChecked: true,
      }
    case SESSION_SIGN_IN_FAILURE:
    case SESSION_SIGN_OUT:
      return {
        ...state,
        currentUser: null,
        isLoading: false,
        isSignedIn: false,
        isChecked: true,
      }
    case SESSION_UPDATE_SETTINGS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          settings: action.settings,
        },
      }
    case SESSION_SET_ONLINE_USERS:
      return {
        ...state,
        usersOnline: action.usersOnline,
      }
    case SESSION_ONLINE_USERS_ADD:
      return {
        ...state,
        usersOnline: [
          ...state.usersOnline.filter((user) => user.id !== action.user.id),
          action.user,
        ],
      }
    case SESSION_ONLINE_USERS_REMOVE:
      return {
        ...state,
        usersOnline: state.usersOnline.filter(
          (user) => user.id !== action.user.id
        ),
      }
    default:
      return state
  }
}

export default sessionReducer
