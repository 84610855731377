import React, { useEffect, useState } from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch, useSelector } from 'react-redux'

/* --------------------------------- Actions -------------------------------- */
import {
  SESSION_ONLINE_USERS_ADD,
  SESSION_ONLINE_USERS_REMOVE,
  fetchOnlineUsers,
} from 'redux/actions/session'

/* ------------------------------- Components ------------------------------- */
import Channel from 'components/providers/Channel'
import UsersOnline from './UsersOnline'

/* -------------------------------------------------------------------------- */
/*                      Users Online Container Component                      */
/* -------------------------------------------------------------------------- */
const UsersOnlineContainer = () => {
  const dispatch = useDispatch()
  const users = useSelector((state) => state.session.usersOnline)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchOnlineUsers())
  }, [dispatch])

  const handleToggle = () => {
    setIsOpen((state) => !state)
  }

  const handleMessageReceive = (data) => {
    switch (data.type) {
      case 'logged_in':
        return dispatch({ type: SESSION_ONLINE_USERS_ADD, user: data.user })
      case 'logged_out':
        return dispatch({ type: SESSION_ONLINE_USERS_REMOVE, user: data.user })
      default:
        return undefined
    }
  }

  return (
    <Channel channel="UsersChannel" onReceive={handleMessageReceive}>
      <UsersOnline {...{ users, isOpen, onToggle: handleToggle }} />
    </Channel>
  )
}

export default UsersOnlineContainer
