import { faServer, faTimes } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MachineBadge = ({ children }) => (
  <Button color="light" disabled size="xs">
    {children && <FontAwesomeIcon icon={faServer} className="me-2" />}
    {!children && <FontAwesomeIcon icon={faTimes} className="text-danger" />}
    {children}
  </Button>
)

export default MachineBadge
