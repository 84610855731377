import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

/* ---------------------------------- FETCH --------------------------------- */
export const ISSUES_FETCH_REQUEST = 'ISSUES_FETCH_REQUEST'
export const ISSUES_FETCH_SUCCESS = 'ISSUES_FETCH_SUCCESS'
export const ISSUES_FETCH_FAILURE = 'ISSUES_FETCH_FAILURE'

/* --------------------------------- UPDATE --------------------------------- */
export const ISSUES_UPDATE_REQUEST = 'ISSUES_UPDATE_REQUEST'
export const ISSUES_UPDATE_SUCCESS = 'ISSUES_UPDATE_SUCCESS'
export const ISSUES_UPDATE_FAILURE = 'ISSUES_UPDATE_FAILURE'
export const ISSUES_UPDATE_ITEM = 'ISSUES_UPDATE_ITEM'

/* --------------------------------- CREATE --------------------------------- */
export const ISSUES_CREATE_REQUEST = 'ISSUES_CREATE_REQUEST'
export const ISSUES_CREATE_SUCCESS = 'ISSUES_CREATE_SUCCESS'
export const ISSUES_CREATE_FAILURE = 'ISSUES_CREATE_FAILURE'

/* --------------------------------- MODALS --------------------------------- */
export const ISSUES_TOGGLE_EDIT_MODAL = 'ISSUES_TOGGLE_EDIT_MODAL'
export const ISSUES_TOGGLE_ADD_MODAL = 'ISSUES_TOGGLE_ADD_MODAL'

/* --------------------------------- FILTERS -------------------------------- */
export const ISSUES_CLEAR_FILTER = 'ISSUES_CLEAR_FILTER'
export const ISSUES_FILTER_SET_KEYWORD = 'ISSUES_FILTER_SET_KEYWORD'
export const ISSUES_FILTER_SELECT_AUTHOR = 'ISSUES_FILTER_SELECT_AUTHOR'
export const ISSUES_FILTER_SELECT_TYPE = 'ISSUES_FILTER_SELECT_TYPE'
export const ISSUES_FILTER_SELECT_STATUS = 'ISSUES_FILTER_SELECT_STATUS'

/* ---------------------------------- Other --------------------------------- */
export const ISSUES_SORT = 'ISSUES_SORT'
export const ISSUES_SET_ERRORS = 'ISSUES_SET_ERRORS'
export const ISSUES_SET_CURRENT_PAGE = 'ISSUES_SET_CURRENT_PAGE'
export const ISSUES_SET_PAGINATION_PER_PAGE = 'ISSUES_SET_PAGINATION_PER_PAGE'
export const ISSUES_SET_QUERY_SETTINGS = 'ISSUES_SET_QUERY_SETTINGS'
export const ISSUES_CLEAR = 'ISSUES_CLEAR'

/* -------------------------------------------------------------------------- */
/*                                FETCH ISSUES                                */
/* -------------------------------------------------------------------------- */
/* --------------------- Get issues list with pagination -------------------- */
export const fetchIssues = () => async (dispatch, getState) => {
  // Get current state
  const {
    issues: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.issues.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          status: filter.status,
          type: filter.issue_type,
          author_id: filter.author,
          search: filter.keyword,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: ISSUES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: ISSUES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: ISSUES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('issues:alerts.cannot_fetch_list'), 'error'),
  })
}

/* -------------------------------------------------------------------------- */
/*                                CREATE ISSUE                                */
/* -------------------------------------------------------------------------- */
/* --------------- Create issue and display validation errors --------------- */

export const createIssue = (params) => async (dispatch) =>
  defaultRequest({
    request: () => api.post(config.api.issues.root, params),
    beforeRequest: () => dispatch({ type: ISSUES_CREATE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('issues:alerts.successfully_created'), 'success')
      // Disable loading
      dispatch({ type: ISSUES_CREATE_SUCCESS })
      // Reload issues list
      dispatch(fetchIssues())
      // Close modal and reset errors
      dispatch({ type: ISSUES_TOGGLE_ADD_MODAL, state: false })
      dispatch({ type: ISSUES_SET_ERRORS, errors: [] })
    },
    onError: () => dispatch({ type: ISSUES_CREATE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnprocessableError: (data) =>
      dispatch({ type: ISSUES_SET_ERRORS, errors: data }),
    onUnknownError: () =>
      displayAlert(i18n.t('issues:alerts.cannot_create'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: ISSUES_TOGGLE_ADD_MODAL, state: false }),
  })

/* -------------------------------------------------------------------------- */
/*                                UPDATE ISSUE                                */
/* -------------------------------------------------------------------------- */
/* ------------------- Update issue and reload issues list ------------------ */
export const updateIssue =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.put(`${config.api.issues.root}/${id}`, params),
      beforeRequest: () => dispatch({ type: ISSUES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('issues:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: ISSUES_UPDATE_SUCCESS })
        // Reload issues list
        dispatch(fetchIssues())
        // Close modal and reset errors
        dispatch({ type: ISSUES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: ISSUES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: ISSUES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: ISSUES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('issues:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: ISSUES_TOGGLE_EDIT_MODAL, state: false }),
    })

/* -------------------------------------------------------------------------- */
/*                             UPDATE ISSUE STATUS                            */
/* -------------------------------------------------------------------------- */
export const updateIssueStatus =
  ({
    id,
    event,
    beforeRequest = () => {},
    afterRequest = () => {},
    onError = () => {},
  }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.issues.root}/${id}/status`, { event }),
      beforeRequest,
      onSuccess: ({ data }) => {
        // Display successfull message
        displayAlert(
          i18n.t('issues:alerts.successfully_updated_status'),
          'success'
        )
        // Update issue item
        dispatch({ type: ISSUES_UPDATE_ITEM, data })
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: () =>
        displayAlert(i18n.t('issues:alerts.cannot_update_status'), 'error'),
      onUnknownError: () =>
        displayAlert(i18n.t('issues:alerts.cannot_update_status'), 'error'),
      afterRequest,
      onError,
    })

/* -------------------------------------------------------------------------- */
/*                            SET PAGINATION LIMIT                            */
/* -------------------------------------------------------------------------- */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: ISSUES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchIssues())
}
