import api from 'utils/api'
import config from 'config'

import i18n from 'initializers/i18n'
import { defaultRequest } from 'utils/requests'
import { displayAlert } from 'utils/helpers/toasts'

export const SESSION_SIGN_IN_REQUEST = 'SESSION_SIGN_IN_REQUEST'
export const SESSION_SIGN_IN_SUCCESS = 'SESSION_SIGN_IN_SUCCESS'
export const SESSION_SIGN_IN_FAILURE = 'SESSION_SIGN_IN_FAILURE'
export const SESSION_SIGN_OUT = 'SESSION_EXPIRE'
export const SESSION_UPDATE_SETTINGS = 'SESSION_UPDATE_SETTINGS'
export const SESSION_SET_ONLINE_USERS = 'SESSION_SET_ONLINE_USERS'
export const SESSION_ONLINE_USERS_ADD = 'SESSION_ONLINE_USERS_ADD'
export const SESSION_ONLINE_USERS_REMOVE = 'SESSION_ONLINE_USERS_REMOVE'

export const signIn = () => async (dispatch) => {
  try {
    dispatch({ type: SESSION_SIGN_IN_REQUEST })
    if (localStorage.getItem('token') === null) throw new Error()
    const result = await api.request(
      `${config.api.fullUrl}${config.api.account.root}`
    )
    dispatch({ type: SESSION_SIGN_IN_SUCCESS, currentUser: result.data })
  } catch (error) {
    localStorage.removeItem('token')
    dispatch({ type: SESSION_SIGN_IN_FAILURE })
  }
}

export const signOut = () => (dispatch) => {
  localStorage.removeItem('token')
  dispatch({ type: SESSION_SIGN_OUT })
}

export const updateSettings =
  ({ settings, beforeRequest = () => {}, afterRequest = () => {} }) =>
  async (dispatch) =>
    defaultRequest({
      beforeRequest,
      afterRequest,
      request: () => api.put(config.api.account.settings, { settings }),
      onSuccess: ({ data: { settings: responseSettings } }) => {
        // Display successfull message
        displayAlert(
          i18n.t('account:alerts.successfully_updated_setting'),
          'success'
        )
        dispatch({ type: SESSION_UPDATE_SETTINGS, settings: responseSettings })
      },
      onUnprocessableError: () =>
        displayAlert(i18n.t('account:alerts.cannot_update_setting'), 'error'),
      onUnknownError: () =>
        displayAlert(i18n.t('account:alerts.cannot_update_setting'), 'error'),
    })

export const updateSetting = (name, value) => async (dispatch) =>
  dispatch(updateSettings({ settings: [{ name, value }] }))

export const createOrRenewSession = () => async () => {
  try {
    await api.post(`${config.api.fullUrl}${config.api.sessions.root}`)
  } catch (error) {
    displayAlert(
      i18n.t('account:alerts.cannot_create_or_renew_session'),
      'error'
    )
  }
}

export const endSession = () => async () => {
  try {
    await api.delete(`${config.api.fullUrl}${config.api.sessions.root}`)
  } catch (error) {
    displayAlert(i18n.t('account:alerts.cannot_end_session'), 'error')
  }
}

export const fetchOnlineUsers = () => async (dispatch) =>
  defaultRequest({
    request: () => api.get(`${config.api.fullUrl}${config.api.users.online}`),
    onSuccess: ({ data }) => {
      dispatch({ type: SESSION_SET_ONLINE_USERS, usersOnline: data })
    },
    onError: () =>
      displayAlert(i18n.t('account:alerts.cannot_fetch_online_users'), 'error'),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
  })
