import React from 'react'
import TimeAgo from 'components/elements/basic/TimeAgo'

const TimeAgoWrapper = ({ expirationTime }) => (
  <strong>
    <TimeAgo date={expirationTime} />
  </strong>
)

export default TimeAgoWrapper
