import React, { Fragment } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

// Components
import { DropdownMenu, DropdownItem } from 'reactstrap'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
// import MachineGroup from './MachineGroup/MachineGroup'
import { Menu, Transition } from '@headlessui/react'
import { faChevronDown, faSlotMachine } from '@fortawesome/pro-solid-svg-icons'
import MachineGroup from './MachineGroup'

const EventsMachines = ({ groups, isLoading, loadingText }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <DropdownMenu>
        <DropdownItem disabled>
          <FontAwesomeIcon icon={faCircleNotch} spin className="me-1" />{' '}
          {loadingText}
        </DropdownItem>
      </DropdownMenu>
    )
  }

  return (
    <Menu as="div">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={cn(
                'tw-flex tw-items-center tw-w-full tw-justify-center tw-rounded-lg tw-px-3 tw-py-1 tw-gap-x-2 tw-font-medium tw-cursor-pointer tw-select-none',
                open
                  ? 'tw-bg-primary-300/60 tw-text-primary-800'
                  : 'tw-text-slate-700 hover:text-slate-900 hover:tw-bg-slate-300'
              )}
              as="div"
            >
              <FontAwesomeIcon icon={faSlotMachine} />
              <span>{t('machines:title')}</span>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={cn(
                  '-tw-ml-1 -tw-opacity-60 tw-text-xxs',
                  open && 'tw-rotate-180'
                )}
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-absolute tw-z-[9999] tw-right-0 tw-left-0 tw-w-1/2 tw-origin-top tw-divide-x tw-divide-slate-200 tw-rounded-lg tw-overflow-hidden tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-slate-700/5 focus:tw-outline-none tw-grid tw-grid-cols-12">
              {Object.keys(groups).map((template) => (
                <MachineGroup
                  key={template}
                  template={template}
                  items={groups[template]}
                />
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )

  // return (
  //   <DropdownMenu className="megamenu bg-light">
  //     <Row>
  //       {Object.keys(groups).map((template) => (
  //         <MachineGroup
  //           key={template}
  //           template={template}
  //           items={groups[template]}
  //         />
  //       ))}
  //     </Row>
  //   </DropdownMenu>
  // )
}

export default EventsMachines
