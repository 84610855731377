import React from 'react'
import OrderStatus from 'components/elements/basic/OrderStatus'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const OrderStatusToast = ({ prev, current }) => (
  <div className="mt-1 d-flex align-items-center">
    <OrderStatus status={prev} disabled small />
    <FontAwesomeIcon icon={faArrowRight} className="mx-2 small" />
    <OrderStatus status={current} disabled small />
  </div>
)

export default OrderStatusToast
