import React, { Fragment } from 'react'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'
import { roleDecorator, userDecorator } from 'utils/decorators'

/* ------------------------------- Components ------------------------------- */
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faXmark } from '@fortawesome/pro-solid-svg-icons'
import UserAvatar from 'components/atomic/atoms/user-avatar'

/* -------------------------------------------------------------------------- */
/*                           Online Users Component                           */
/* -------------------------------------------------------------------------- */
const UsersOnline = ({ users }) => {
  const { t } = useTranslation('account')
  /* --------------------------------- Render --------------------------------- */
  return (
    <Menu as="div" className="tw-relative tw-inline-block tw-text-left tw-z-20">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={cn(
                'tw-inline-flex tw-aspect-square tw-h-8 tw-items-center tw-gap-x-2 tw-w-full tw-justify-center tw-rounded-lg tw-px-3 tw-py-1 tw-font-medium tw-relative',
                open
                  ? 'tw-bg-primary-300/60 tw-text-primary-800'
                  : 'tw-text-slate-700 hover:tw-text-slate-900 hover:tw-bg-slate-300'
              )}
            >
              <FontAwesomeIcon icon={faUsers} className="tw-text-lg" />
              <div className="tw-absolute tw-bottom-0 tw-right-0 tw-px-1 tw-font-black tw-text-xxs tw-text-white tw-rounded-lg tw-bg-green-500">
                {users.length}
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-fixed tw-right-0 tw-w-56 tw-top-0 tw-origin-top tw-h-screen tw-overflow-hidden tw-bg-slate-800 tw-shadow-lg tw-ring-1 tw-ring-slate-700/5 focus:tw-outline-none tw-overflow-y-auto tw-flex tw-flex-col tw-gap-y-3 tw-pb-2">
              <Menu.Item>
                {({ close }) => (
                  <div className="tw-font-medium tw-bg-slate-900 tw-text-slate-400 tw-p-2 tw-py-1 tw-text-sm tw-flex tw-items-center tw-justify-between">
                    <div>{t('menu.users_online')}</div>
                    <div
                      onClick={close}
                      className="tw-w-6 tw-aspect-square tw-flex tw-items-center tw-justify-center tw-text-slate-500 hover:tw-text-slate-200 tw-cursor-pointer hover:tw-bg-slate-700 tw-rounded-lg"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </div>
                )}
              </Menu.Item>
              {users.map((user) => (
                <div
                  key={user.id}
                  className="tw-flex tw-items-center tw-gap-x-2 tw-px-2"
                >
                  <div className="">
                    <UserAvatar email={user.email} showOnline />
                  </div>
                  <div className="tw-flex tw-flex-col tw-justify-center">
                    <div className="tw-text-slate-300 tw-text-sm tw-font-bold">
                      {userDecorator(user)}
                    </div>
                    <div className="tw-text-slate-400/80 tw-text-xs">
                      {user.roles.map((role) => roleDecorator(role)).join(', ')}
                    </div>
                  </div>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default UsersOnline
