import config from 'config'

import {
  CONTRACTORS_FETCH_REQUEST,
  CONTRACTORS_FETCH_SUCCESS,
  CONTRACTORS_FETCH_FAILURE,
  CONTRACTORS_CREATE_REQUEST,
  CONTRACTORS_CREATE_SUCCESS,
  CONTRACTORS_CREATE_FAILURE,
  CONTRACTORS_UPDATE_REQUEST,
  CONTRACTORS_UPDATE_SUCCESS,
  CONTRACTORS_UPDATE_FAILURE,
  CONTRACTORS_DELETE_REQUEST,
  CONTRACTORS_DELETE_SUCCESS,
  CONTRACTORS_DELETE_FAILURE,
  CONTRACTORS_SET_AVAILABLE_MANAGERS,
  CONTRACTORS_SET_AVAILABLE_LANGUAGES,
  CONTRACTORS_TOGGLE_ADD_MODAL,
  CONTRACTORS_TOGGLE_EDIT_MODAL,
  CONTRACTORS_TOGGLE_DELETE_MODAL,
  CONTRACTORS_FILTER_SET_KEYWORD,
  CONTRACTORS_SELECT_MANAGERS,
  CONTRACTORS_SELECT_LANGUAGE,
  CONTRACTORS_SELECT_IS_ACTIVE,
  CONTRACTORS_CLEAR_FILTER,
  CONTRACTORS_SORT,
  CONTRACTORS_SET_ERRORS,
  CONTRACTORS_SET_CURRENT_PAGE,
  CONTRACTORS_SET_PAGINATION_PER_PAGE,
  CONTRACTORS_SET_QUERY_SETTINGS,
  CONTRACTORS_CLEAR,
} from '../actions/contractors'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  filter: {
    keyword: '',
    managers: [],
    language: null,
    isActive: config.options.contractors.filters.is_active.defaultValue,
  },
  sorting: {
    column: 'contractors.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  availableManagers: [],
  availableLanguages: [],
  errors: [],
  settingsFetched: false,
}

const contractorsReducer = (state = initialState, action) => {
  switch (action.type) {
    // CONTRACTORS_FETCH_REQUEST
    case CONTRACTORS_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // CONTRACTORS_FETCH_SUCCESS
    case CONTRACTORS_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // CONTRACTORS_FETCH_FAILURE
    case CONTRACTORS_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // CONTRACTORS_CREATE_REQUEST
    // CONTRACTORS_UPDATE_REQUEST
    // CONTRACTORS_DELETE_REQUEST
    case CONTRACTORS_CREATE_REQUEST:
    case CONTRACTORS_UPDATE_REQUEST:
    case CONTRACTORS_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // CONTRACTORS_CREATE_SUCCESS
    // CONTRACTORS_UPDATE_SUCCESS
    // CONTRACTORS_DELETE_SUCCESS
    // CONTRACTORS_CREATE_FAILURE
    // CONTRACTORS_UPDATE_FAILURE
    // CONTRACTORS_DELETE_FAILURE
    case CONTRACTORS_CREATE_SUCCESS:
    case CONTRACTORS_UPDATE_SUCCESS:
    case CONTRACTORS_DELETE_SUCCESS:
    case CONTRACTORS_CREATE_FAILURE:
    case CONTRACTORS_UPDATE_FAILURE:
    case CONTRACTORS_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // CONTRACTORS_TOGGLE_ADD_MODAL
    case CONTRACTORS_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }
    // CONTRACTORS_TOGGLE_EDIT_MODAL
    case CONTRACTORS_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // CONTRACTORS_TOGGLE_DELETE_MODAL
    case CONTRACTORS_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // CONTRACTORS_FILTER_SET_KEYWORD
    case CONTRACTORS_FILTER_SET_KEYWORD:
      return {
        ...state,
        filter: {
          ...state.filter,
          keyword: action.keyword,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CONTRACTORS_SELECT_MANAGERS
    case CONTRACTORS_SELECT_MANAGERS:
      return {
        ...state,
        filter: {
          ...state.filter,
          managers: action.managers,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CONTRACTORS_SELECT_LANGUAGE
    case CONTRACTORS_SELECT_LANGUAGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          language: action.language,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CONTRACTORS_SELECT_IS_ACTIVE
    case CONTRACTORS_SELECT_IS_ACTIVE:
      return {
        ...state,
        filter: {
          ...state.filter,
          isActive: action.state,
        },
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CONTRACTORS_CLEAR_FILTER
    case CONTRACTORS_CLEAR_FILTER:
      return {
        ...state,
        filter: initialState.filter,
      }
    // CONTRACTORS_SET_AVAILABLE_MANAGERS
    case CONTRACTORS_SET_AVAILABLE_MANAGERS:
      return {
        ...state,
        availableManagers: action.items,
      }
    // CONTRACTORS_SET_AVAILABLE_LANGUAGES
    case CONTRACTORS_SET_AVAILABLE_LANGUAGES:
      return {
        ...state,
        availableLanguages: action.items,
      }
    // CONTRACTORS_SORT
    case CONTRACTORS_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // CONTRACTORS_SET_ERRORS
    case CONTRACTORS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // CONTRACTORS_SET_CURRENT_PAGE
    case CONTRACTORS_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // CONTRACTORS_SET_PAGINATION_PER_PAGE
    case CONTRACTORS_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // CONTRACTORS_SET_QUERY_SETTINGS
    case CONTRACTORS_SET_QUERY_SETTINGS:
      return {
        ...state,
        filter: {
          ...state.filter,
          managers: (action.managers || []).map((e) => parseInt(e, 10)),
          language: parseInt(action.language, 10) || null,
          isActive:
            action.isActive ||
            config.options.contractors.filters.is_active.defaultValue,
        },
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // CONTRACTORS_CLEAR
    case CONTRACTORS_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default contractorsReducer
