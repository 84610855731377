import React from 'react'
import EventStatus from 'components/elements/basic/EventStatus'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const EventStatusToast = ({ prev, current }) => (
  <div className="mt-1 d-flex align-items-center">
    <EventStatus status={prev} disabled compact />
    <FontAwesomeIcon icon={faArrowRight} className="mx-2 small" />
    <EventStatus status={current} disabled compact />
  </div>
)

export default EventStatusToast
