import React from 'react'

import md5 from 'crypto-js/md5'

const UserAvatar = ({ email, className = '' }) => (
  <div
    className={`gd-user-badge__avatar ${className}`}
    style={{
      backgroundImage: `url(https://www.gravatar.com/avatar/${md5(
        email.toLowerCase().trim()
      )}?d=mp`,
    }}
  >
    &#160;
  </div>
)

export default UserAvatar
