import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { GLOBAL_CLEAR_SESSION_EXPIRED } from 'redux/actions/global'

const SessionExpiredModal = ({ isOpen }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const toggle = () => {
    dispatch({ type: GLOBAL_CLEAR_SESSION_EXPIRED })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        {t('modals:session_expired.title')}
      </ModalHeader>
      <ModalBody>
        {t('modals:session_expired.content')}
        <div className="alert alert-info mt-3">
          <FontAwesomeIcon icon={faInfo} className="me-2" />{' '}
          {t('modals:session_expired.tip')}
        </div>
        <span className="text-muted">
          {t('modals:session_expired.description')}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button color="info" onClick={toggle}>
          {t('actions.understand')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SessionExpiredModal
