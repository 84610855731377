import {
  GLOBAL_SET_UNAUTHORIZED,
  GLOBAL_CLEAR_UNAUTHORIZED,
  GLOBAL_SET_NETWORK_ERROR,
  GLOBAL_CLEAR_NETWORK_ERROR,
  GLOBAL_SET_NOT_FOUND,
  GLOBAL_CLEAR_NOT_FOUND,
  GLOBAL_SET_SESSION_EXPIRED,
  GLOBAL_CLEAR_SESSION_EXPIRED,
  GLOBAL_SET_EXPIRATION_WARNING,
  GLOBAL_CLEAR_EXPIRATION_WARNING,
  GLOBAL_SET_MAINTENANCE_MODE,
} from 'redux/actions/global'

const initialState = {
  unauthorized: {
    isUnauthorized: false,
    message: null,
  },
  isNotFound: false,
  isMaintenanceMode: false,
  networkError: false,
  sessionExpired: false,
  expirationWarning: false,
}

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_SET_UNAUTHORIZED:
      return {
        ...state,
        unauthorized: {
          ...state.unauthorized,
          isUnauthorized: true,
          message: action.message,
        },
      }
    case GLOBAL_CLEAR_UNAUTHORIZED:
      return {
        ...state,
        unauthorized: {
          ...state.unauthorized,
          isUnauthorized: false,
          message: null,
        },
      }
    case GLOBAL_SET_NOT_FOUND:
      return {
        ...state,
        isNotFound: true,
      }
    case GLOBAL_CLEAR_NOT_FOUND:
      return {
        ...state,
        isNotFound: false,
      }
    case GLOBAL_SET_NETWORK_ERROR:
      return {
        ...state,
        networkError: true,
      }
    case GLOBAL_CLEAR_NETWORK_ERROR:
      return {
        ...state,
        networkError: false,
      }
    case GLOBAL_SET_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      }
    case GLOBAL_CLEAR_SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: false,
      }
    case GLOBAL_SET_EXPIRATION_WARNING:
      return {
        ...state,
        expirationWarning: true,
      }
    case GLOBAL_CLEAR_EXPIRATION_WARNING:
      return {
        ...state,
        expirationWarning: false,
      }
    case GLOBAL_SET_MAINTENANCE_MODE:
      return {
        ...state,
        isMaintenanceMode: action.state,
      }
    default:
      return state
  }
}

export default globalReducer
