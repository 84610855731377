import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const DELIVERY_NOTES_FETCH_REQUEST = 'DELIVERY_NOTES_FETCH_REQUEST'
export const DELIVERY_NOTES_FETCH_SUCCESS = 'DELIVERY_NOTES_FETCH_SUCCESS'
export const DELIVERY_NOTES_FETCH_FAILURE = 'DELIVERY_NOTES_FETCH_FAILURE'
// CREATE
export const DELIVERY_NOTES_CREATE_REQUEST = 'DELIVERY_NOTES_CREATE_REQUEST'
export const DELIVERY_NOTES_CREATE_SUCCESS = 'DELIVERY_NOTES_CREATE_SUCCESS'
export const DELIVERY_NOTES_CREATE_FAILURE = 'DELIVERY_NOTES_CREATE_FAILURE'
// UPDATE
export const DELIVERY_NOTES_UPDATE_REQUEST = 'DELIVERY_NOTES_UPDATE_REQUEST'
export const DELIVERY_NOTES_UPDATE_SUCCESS = 'DELIVERY_NOTES_UPDATE_SUCCESS'
export const DELIVERY_NOTES_UPDATE_FAILURE = 'DELIVERY_NOTES_UPDATE_FAILURE'
// FILTERS
export const DELIVERY_NOTES_FILTER_SET_KEYWORD =
  'DELIVERY_NOTES_FILTER_SET_KEYWORD'
export const DELIVERY_NOTES_FILTER_SELECT_CONTRACTOR =
  'DELIVERY_NOTES_FILTER_SELECT_CONTRACTOR'
export const DELIVERY_NOTES_FILTER_SELECT_SUBCONTRACTOR =
  'DELIVERY_NOTES_FILTER_SELECT_SUBCONTRACTOR'
export const DELIVERY_NOTES_FILTER_SELECT_AUTHOR =
  'DELIVERY_NOTES_FILTER_SELECT_AUTHOR'
export const DELIVERY_NOTES_FILTER_SET_DAYS = 'DELIVERY_NOTES_FILTER_SET_DAYS'
export const DELIVERY_NOTES_FILTER_SET_ONLY_NOT_SHIPPED =
  'DELIVERY_NOTES_FILTER_SET_ONLY_NOT_SHIPPED'
export const DELIVERY_NOTES_FILTER_SET_SUBCONTRACTOR_MODE =
  'DELIVERY_NOTES_FILTER_SET_SUBCONTRACTOR_MODE'
export const DELIVERY_NOTES_FILTER_SET_ORDER = 'DELIVERY_NOTES_FILTER_SET_ORDER'
export const DELIVERY_NOTES_CLEAR_FILTER = 'DELIVERY_NOTES_CLEAR_FILTER'
// Other
export const DELIVERY_NOTES_SORT = 'DELIVERY_NOTES_SORT'
export const DELIVERY_NOTES_SET_ERRORS = 'DELIVERY_NOTES_SET_ERRORS'
export const DELIVERY_NOTES_SET_CURRENT_PAGE = 'DELIVERY_NOTES_SET_CURRENT_PAGE'
export const DELIVERY_NOTES_SET_PAGINATION_PER_PAGE =
  'DELIVERY_NOTES_SET_PAGINATION_PER_PAGE'
export const DELIVERY_NOTES_SET_QUERY_SETTINGS =
  'DELIVERY_NOTES_SET_QUERY_SETTINGS'
export const DELIVERY_NOTES_CLEAR = 'DELIVERY_NOTES_CLEAR'
export const DELIVERY_NOTES_CLEAR_REDIRECT = 'DELIVERY_NOTES_CLEAR_REDIRECT'

/**
 * FETCH DELIVERY NOTES
 * * Get delivery notes list with pagination
 */
export const fetchDeliveryNotes = () => async (dispatch, getState) => {
  // Get current state
  const {
    delivery_notes: { pagination, filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(`${config.api.delivery_notes.root}`, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          search: filter.keyword,
          contractor_id: filter.contractor,
          subcontractor_id: filter.subcontractor,
          author_id: filter.author,
          only_not_shipped: filter.onlyNotShipped,
          days: filter.days,
          subcontractor_mode: filter.subcontractorMode,
          order_id: filter.order,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: DELIVERY_NOTES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: DELIVERY_NOTES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: DELIVERY_NOTES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('delivery_notes:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE DELIVERY NOTE
 * * Create delivery note and display validation errors
 */
export const createDeliveryNote =
  ({ language_id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.post(config.api.delivery_notes.root, {
          language_id: language_id || null,
          ...params,
        }),
      beforeRequest: () => dispatch({ type: DELIVERY_NOTES_CREATE_REQUEST }),
      onSuccess: ({ data }) => {
        // Display successfull message
        displayAlert(
          i18n.t('delivery_notes:alerts.successfully_created'),
          'success'
        )
        // Disable loading
        dispatch({ type: DELIVERY_NOTES_CREATE_SUCCESS, id: data.id })
        // Reload varnish types list
        dispatch(fetchDeliveryNotes())
        // Reset errors
        dispatch({ type: DELIVERY_NOTES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: DELIVERY_NOTES_CREATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: DELIVERY_NOTES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('delivery_notes:alerts.cannot_create'), 'error'),
    })

/**
 * UPDATE DELIVERY NOTE
 * * Update delivery note and reload delivery notes list
 */
export const updateDeliveryNote =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.put(`${config.api.delivery_notes.root}/${id}`, params),
      beforeRequest: () => dispatch({ type: DELIVERY_NOTES_UPDATE_REQUEST }),
      onSuccess: ({ data }) => {
        // Display successfull message
        displayAlert(
          i18n.t('delivery_notes:alerts.successfully_updated'),
          'success'
        )
        // Disable loading
        dispatch({ type: DELIVERY_NOTES_UPDATE_SUCCESS, id: data.id })
        // Reload varnish types list
        dispatch(fetchDeliveryNotes())
        // Reset errors
        dispatch({ type: DELIVERY_NOTES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: DELIVERY_NOTES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: DELIVERY_NOTES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('delivery_notes:alerts.cannot_update'), 'error'),
    })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: DELIVERY_NOTES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchDeliveryNotes())
}
