import {
  CITIES_FETCH_REQUEST,
  CITIES_FETCH_SUCCESS,
  CITIES_FETCH_FAILURE,
  CITIES_CREATE_REQUEST,
  CITIES_CREATE_SUCCESS,
  CITIES_CREATE_FAILURE,
  CITIES_UPDATE_REQUEST,
  CITIES_UPDATE_SUCCESS,
  CITIES_UPDATE_FAILURE,
  CITIES_DELETE_REQUEST,
  CITIES_DELETE_SUCCESS,
  CITIES_DELETE_FAILURE,
  CITIES_SET_ERRORS,
} from '../actions/cities'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  // isAddModalOpen: false,
  // isEditModalOpen: false,
  // isDeleteModalOpen: false,
  // filter: {
  //   keyword: '',
  //   country: null,
  // },
  // sorting: {
  //   column: 'cities.id',
  //   direction: 'desc',
  // },
  // pagination: {
  //   page: 1,
  //   per_page: config.pagination.limits[0],
  //   total: 0,
  // },
  // editId: null,
  errors: [],
  // settingsFetched: false,
}

const citiesReducer = (state = initialState, action) => {
  switch (action.type) {
    // CITIES_FETCH_REQUEST
    case CITIES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // CITIES_FETCH_SUCCESS
    case CITIES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // CITIES_FETCH_FAILURE
    case CITIES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // CITIES_CREATE_REQUEST
    // CITIES_UPDATE_REQUEST
    // CITIES_DELETE_REQUEST
    case CITIES_CREATE_REQUEST:
    case CITIES_UPDATE_REQUEST:
    case CITIES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // CITIES_CREATE_SUCCESS
    // CITIES_UPDATE_SUCCESS
    // CITIES_DELETE_SUCCESS
    // CITIES_CREATE_FAILURE
    // CITIES_UPDATE_FAILURE
    // CITIES_DELETE_FAILURE
    case CITIES_CREATE_SUCCESS:
    case CITIES_UPDATE_SUCCESS:
    case CITIES_DELETE_SUCCESS:
    case CITIES_CREATE_FAILURE:
    case CITIES_UPDATE_FAILURE:
    case CITIES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }

    // CITIES_FILTER_SET_KEYWORD
    // case CITIES_FILTER_SET_KEYWORD:
    //   return {
    //     ...state,
    //     filter: {
    //       ...state.filter,
    //       keyword: action.keyword,
    //     },
    //     pagination: {
    //       ...state.pagination,
    //       page: 1,
    //     }
    //   }
    // CITIES_CLEAR_FILTER
    // case CITIES_CLEAR_FILTER:
    //   return {
    //     ...state,
    //     filter: {
    //       keyword: '',
    //       city: null,
    //       country: null,
    //       withContractors: 0,
    //     }
    //   }
    // CITIES_SORT
    // case CITIES_SORT:
    //   return {
    //     ...state,
    //     sorting: action.sorting,
    //     pagination: {
    //       ...state.pagination,
    //       page: 1,
    //     }
    //   }
    // CITIES_SET_ERRORS
    case CITIES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // CITIES_SET_CURRENT_PAGE
    // case CITIES_SET_CURRENT_PAGE:
    //   return {
    //     ...state,
    //     pagination: {
    //       ...state.pagination,
    //       page: action.page,
    //     }
    //   }
    // CITIES_SET_PAGINATION_PER_PAGE
    // case CITIES_SET_PAGINATION_PER_PAGE:
    //   return {
    //     ...state,
    //     pagination: {
    //       ...state.pagination,
    //       page: 1,
    //       per_page: action.limit
    //     }
    //   }
    // CITIES_SET_QUERY_SETTINGS
    // case CITIES_SET_QUERY_SETTINGS:
    //   return {
    //     ...state,
    //   }
    // CITIES_CLEAR
    // case CITIES_CLEAR:
    //   return {
    //     ...state,
    //     items: []
    //   }
    default:
      return state
  }
}

export default citiesReducer
