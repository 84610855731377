import React from 'react'
import MachineBadge from 'components/elements/basic/MachineBadge'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const EventMachineToast = ({ source_machine_name, target_machine_name }) => (
  <div className="mt-1 d-flex align-items-center">
    <MachineBadge>{source_machine_name}</MachineBadge>
    <FontAwesomeIcon icon={faArrowRight} className="mx-2 small" />
    <MachineBadge>{target_machine_name}</MachineBadge>
  </div>
)

export default EventMachineToast
