export const KEYS_CONTROL = 17
export const KEYS_COMMAND = 91
export const KEYS_ESCAPE = 27
export const KEYS_DELETE = 46
export const KEYS_A = 65
export const KEYS_ENTER = 13
export const KEYS_F = 70
export const KEYS_F3 = 114

export const KEY_NAMES = {
  [KEYS_CONTROL]: 'CTRL',
  [KEYS_COMMAND]: 'CMD',
  [KEYS_DELETE]: 'DEL',
  [KEYS_ESCAPE]: 'ESC',
  [KEYS_A]: 'A',
  [KEYS_ENTER]: 'ENTER',
  [KEYS_F]: 'F',
}

export const getKeyName = (key) => KEY_NAMES[key]
