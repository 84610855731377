import React, { useState } from 'react'

/* ---------------------------------- Hooks --------------------------------- */
import { useDispatch } from 'react-redux'

/* ---------------------------------- Utils --------------------------------- */
import {
  GLOBAL_SET_SESSION_EXPIRED,
  GLOBAL_SET_EXPIRATION_WARNING,
  GLOBAL_CLEAR_EXPIRATION_WARNING,
} from 'redux/actions/global'
import { signOut } from 'redux/actions/session'

/* ------------------------------- Components ------------------------------- */
import Countdown from 'react-countdown'
import CountdownRenderer from './CountdownRenderer'

/* -------------------------------------------------------------------------- */
/*                             Session Expiration                             */
/* -------------------------------------------------------------------------- */
const SessionExpiration = ({ expirationTime }) => {
  const dispatch = useDispatch()
  const [warning, setWarning] = useState(false)

  const handleComplete = () => {
    dispatch(signOut())
    dispatch({ type: GLOBAL_CLEAR_EXPIRATION_WARNING })
    dispatch({ type: GLOBAL_SET_SESSION_EXPIRED })
  }

  const handleTick = (time) => {
    if (!warning && time.total <= 15 * 60 * 1000) {
      setWarning(true)
      dispatch({ type: GLOBAL_SET_EXPIRATION_WARNING })
    }
  }

  /* --------------------------------- Render --------------------------------- */
  return (
    <Countdown
      date={expirationTime}
      renderer={CountdownRenderer}
      onComplete={handleComplete}
      onTick={handleTick}
    />
  )
}

export default SessionExpiration
