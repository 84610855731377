import config from 'config'

import {
  ROLES_FETCH_REQUEST,
  ROLES_FETCH_SUCCESS,
  ROLES_FETCH_FAILURE,
  ROLES_CREATE_REQUEST,
  ROLES_CREATE_SUCCESS,
  ROLES_CREATE_FAILURE,
  ROLES_UPDATE_REQUEST,
  ROLES_UPDATE_SUCCESS,
  ROLES_UPDATE_FAILURE,
  ROLES_DELETE_REQUEST,
  ROLES_DELETE_SUCCESS,
  ROLES_DELETE_FAILURE,
  ROLES_TOGGLE_ADD_MODAL,
  ROLES_TOGGLE_EDIT_MODAL,
  ROLES_TOGGLE_DELETE_MODAL,
  ROLES_SORT,
  ROLES_SET_ERRORS,
  ROLES_SET_CURRENT_PAGE,
  ROLES_SET_PAGINATION_PER_PAGE,
  ROLES_SET_QUERY_SETTINGS,
  ROLES_CLEAR,
} from '../actions/roles'

const initialState = {
  items: [],
  isLoading: true,
  isFormLoading: false,
  loadingFailed: false,
  isAddModalOpen: false,
  isEditModalOpen: false,
  isDeleteModalOpen: false,
  sorting: {
    column: 'roles.id',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    per_page: config.pagination.limits[0],
    total: 0,
  },
  editId: null,
  errors: [],
  settingsFetched: false,
}

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    // ROLES_FETCH_REQUEST
    case ROLES_FETCH_REQUEST:
      return { ...state, isLoading: true }
    // ROLES_FETCH_SUCCESS
    case ROLES_FETCH_SUCCESS:
      return {
        ...state,
        items: action.items,
        isLoading: false,
        pagination: action.pagination,
        loadingFailed: false,
      }
    // ROLES_FETCH_FAILURE
    case ROLES_FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      }
    // ROLES_CREATE_REQUEST
    // ROLES_UPDATE_REQUEST
    // ROLES_DELETE_REQUEST
    case ROLES_CREATE_REQUEST:
    case ROLES_UPDATE_REQUEST:
    case ROLES_DELETE_REQUEST:
      return {
        ...state,
        isFormLoading: true,
      }
    // ROLES_CREATE_SUCCESS
    // ROLES_UPDATE_SUCCESS
    // ROLES_DELETE_SUCCESS
    // ROLES_CREATE_FAILURE
    // ROLES_UPDATE_FAILURE
    // ROLES_DELETE_FAILURE
    case ROLES_CREATE_SUCCESS:
    case ROLES_UPDATE_SUCCESS:
    case ROLES_DELETE_SUCCESS:
    case ROLES_CREATE_FAILURE:
    case ROLES_UPDATE_FAILURE:
    case ROLES_DELETE_FAILURE:
      return {
        ...state,
        isFormLoading: false,
      }
    // ROLES_TOGGLE_ADD_MODAL
    case ROLES_TOGGLE_ADD_MODAL:
      return {
        ...state,
        isAddModalOpen: action.state,
      }
    // ROLES_TOGGLE_EDIT_MODAL
    case ROLES_TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.state,
        editId: action.id,
      }
    // ROLES_TOGGLE_DELETE_MODAL
    case ROLES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.state,
      }
    // ROLES_SORT
    case ROLES_SORT:
      return {
        ...state,
        sorting: action.sorting,
        pagination: {
          ...state.pagination,
          page: 1,
        },
      }
    // ROLES_SET_ERRORS
    case ROLES_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      }
    // ROLES_SET_CURRENT_PAGE
    case ROLES_SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
        },
      }
    // ROLES_SET_PAGINATION_PER_PAGE
    case ROLES_SET_PAGINATION_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
          per_page: action.limit,
        },
      }
    // ROLES_SET_QUERY_SETTINGS
    case ROLES_SET_QUERY_SETTINGS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          per_page: action.per_page || initialState.pagination.per_page,
        },
        sorting: action.sorting || initialState.sorting,
        settingsFetched: true,
      }
    // ROLES_CLEAR
    case ROLES_CLEAR:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default rolesReducer
