import React from 'react'

/* ---------------------------------- Utils --------------------------------- */
import cn from 'classnames'

/* -------------------------------------------------------------------------- */
/*                                   Navbar                                   */
/* -------------------------------------------------------------------------- */
const Navbar = ({ children, className }) => (
  <div
    className={cn(
      'px-2 gd-navbar tw-bg-slate-200 tw-flex tw-justify-between',
      className
    )}
  >
    {children}
  </div>
)

export default Navbar
