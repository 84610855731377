import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { parsePagination } from 'utils/pagination'
import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const ROLES_FETCH_REQUEST = 'ROLES_FETCH_REQUEST'
export const ROLES_FETCH_SUCCESS = 'ROLES_FETCH_SUCCESS'
export const ROLES_FETCH_FAILURE = 'ROLES_FETCH_FAILURE'
// CREATE
export const ROLES_CREATE_REQUEST = 'ROLES_CREATE_REQUEST'
export const ROLES_CREATE_SUCCESS = 'ROLES_CREATE_SUCCESS'
export const ROLES_CREATE_FAILURE = 'ROLES_CREATE_FAILURE'
// UPDATE
export const ROLES_UPDATE_REQUEST = 'ROLES_UPDATE_REQUEST'
export const ROLES_UPDATE_SUCCESS = 'ROLES_UPDATE_SUCCESS'
export const ROLES_UPDATE_FAILURE = 'ROLES_UPDATE_FAILURE'
// DELETE
export const ROLES_DELETE_REQUEST = 'ROLES_DELETE_REQUEST'
export const ROLES_DELETE_SUCCESS = 'ROLES_DELETE_SUCCESS'
export const ROLES_DELETE_FAILURE = 'ROLES_DELETE_FAILURE'
// MODALS
export const ROLES_TOGGLE_ADD_MODAL = 'ROLES_TOGGLE_ADD_MODAL'
export const ROLES_TOGGLE_EDIT_MODAL = 'ROLES_TOGGLE_EDIT_MODAL'
export const ROLES_TOGGLE_DELETE_MODAL = 'ROLES_TOGGLE_DELETE_MODAL'
// Other
export const ROLES_SORT = 'ROLES_SORT'
export const ROLES_SET_ERRORS = 'ROLES_SET_ERRORS'
export const ROLES_SET_CURRENT_PAGE = 'ROLES_SET_CURRENT_PAGE'
export const ROLES_SET_PAGINATION_PER_PAGE = 'ROLES_SET_PAGINATION_PER_PAGE'
export const ROLES_SET_QUERY_SETTINGS = 'ROLES_SET_QUERY_SETTINGS'
export const ROLES_CLEAR = 'ROLES_CLEAR'

/**
 * FETCH ROLES
 * * Get roles list with pagination
 */
export const fetchRoles = () => async (dispatch, getState) => {
  // Get current state
  const {
    roles: { pagination, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(config.api.roles.root, {
        params: {
          page: pagination.page,
          per_page: pagination.per_page,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: ROLES_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response, parse pagination headers
      const { data, headers } = response
      const serverPagination = parsePagination(headers)
      // Dispatch success action
      dispatch({
        type: ROLES_FETCH_SUCCESS,
        items: data,
        pagination: serverPagination,
      })
    },
    onError: () => dispatch({ type: ROLES_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('roles:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * CREATE ROLE
 * * Create role and display validation errors
 */
export const createRole = (params) => async (dispatch) =>
  defaultRequest({
    request: () => api.post(config.api.roles.root, params),
    beforeRequest: () => dispatch({ type: ROLES_CREATE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('roles:alerts.successfully_created'), 'success')
      // Disable loading
      dispatch({ type: ROLES_CREATE_SUCCESS })
      // Reload roles list
      dispatch(fetchRoles())
      // Close modal and reset errors
      dispatch({ type: ROLES_TOGGLE_ADD_MODAL, state: false })
      dispatch({ type: ROLES_SET_ERRORS, errors: [] })
    },
    onError: () => dispatch({ type: ROLES_CREATE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnprocessableError: (data) =>
      dispatch({ type: ROLES_SET_ERRORS, errors: data }),
    onUnknownError: () =>
      displayAlert(i18n.t('roles:alerts.cannot_create'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: ROLES_TOGGLE_ADD_MODAL, state: false }),
  })

/**
 * UPDATE ROLE
 * * Update role and reload roles list
 */
export const updateRole =
  ({ id, ...params }) =>
  async (dispatch) =>
    defaultRequest({
      request: () => api.put(`${config.api.roles.root}/${id}`, params),
      beforeRequest: () => dispatch({ type: ROLES_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('roles:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: ROLES_UPDATE_SUCCESS })
        // Reload roles list
        dispatch(fetchRoles())
        // Close modal and reset errors
        dispatch({ type: ROLES_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: ROLES_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: ROLES_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: ROLES_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('roles:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: ROLES_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * DELETE ROLE
 * * Delete role and fetch roles list
 */
export const deleteRole = (id) => async (dispatch) =>
  defaultRequest({
    request: () => api.delete(`${config.api.roles.root}/${id}`),
    beforeRequest: () => dispatch({ type: ROLES_DELETE_REQUEST }),
    onSuccess: () => {
      // Display successfull message
      displayAlert(i18n.t('roles:alerts.successfully_deleted'), 'success')
      // Disable loading
      dispatch({ type: ROLES_DELETE_SUCCESS })
      // Close modal
      dispatch({ type: ROLES_TOGGLE_DELETE_MODAL, state: false })
      // Reload roles list
      dispatch(fetchRoles())
    },
    onError: () => dispatch({ type: ROLES_DELETE_FAILURE }),
    onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
    onUnknownError: () =>
      displayAlert(i18n.t('roles:alerts.cannot_delete'), 'error'),
    onUnexpectedError: () =>
      dispatch({ type: ROLES_TOGGLE_DELETE_MODAL, state: false }),
  })

/**
 * SET PAGINATION LIMIT
 */
export const setPaginationLimit = (limit) => (dispatch) => {
  dispatch({ type: ROLES_SET_PAGINATION_PER_PAGE, limit })
  dispatch(fetchRoles())
}
