import React from 'react'

const GuestLayout = ({ children }) => {
  return (
    <div className="vh-100 d-flex p-2 justify-content-center align-items-center tw-bg-slate-100 flex-column">
      {children}
    </div>
  )
}

export default GuestLayout
