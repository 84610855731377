import global from './global.json'
import orders from './orders.json'
import events from './events.json'
import managers from './managers.json'
import languages from './languages.json'
import contact_people from './contact_people.json'
import addresses from './addresses.json'
import account from './account.json'
import settings from './settings.json'
import dashboard from './dashboard.json'
import modals from './modals.json'
import cities from './cities.json'
import countries from './countries.json'
import employees from './employees.json'
import users from './users.json'
import roles from './roles.json'
import registration_keys from './registration_keys.json'

import paper_sizes from './paper_sizes.json'
import color_types from './color_types.json'
import paper_types from './paper_types.json'
import varnish_types from './varnish_types.json'
import cutting_patterns from './cutting_patterns.json'
import machines from './machines.json'
import process_types from './process_types.json'
import paper_type_groups from './paper_type_groups.json'

import paper_demands from './paper_demands.json'
import materials from './materials.json'

import delivery_notes from './delivery_notes.json'

import versions from './versions.json'
import changelog from './changelog.json'

import issues from './issues.json'
import employee_time_entries from './employee_time_entries.json'
import storages from './storages.json'
import storage_locations from './storage_locations.json'
import paper_stocks from './paper_stocks.json'
import warehouse from './warehouse.json'
import contractors from './contractors.json'

export default {
  global,
  orders,
  events,
  managers,
  languages,
  contact_people,
  addresses,
  account,
  settings,
  dashboard,
  modals,
  cities,
  countries,
  employees,
  users,
  roles,
  registration_keys,
  paper_sizes,
  color_types,
  paper_types,
  paper_type_groups,
  varnish_types,
  cutting_patterns,
  machines,
  process_types,
  paper_demands,
  materials,
  delivery_notes,
  versions,
  changelog,
  issues,
  employee_time_entries,
  storages,
  storage_locations,
  paper_stocks,
  warehouse,
  contractors,
}
