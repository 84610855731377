import React from 'react'
import classNames from 'classnames'

import { filterByKeys } from 'utils/functions'
import { useTranslation } from 'react-i18next'
import { eventOrderPositionDecorator } from 'utils/decorators'

const forbiddenKeys = ['updating']

const EventStatus = ({
  status,
  order_position,
  machine,
  event_type,
  small = false,
  compact = false,
  disabled = true,
  forbidden = false,
  fullWidth = false,
  ...rest
}) => {
  const [t] = useTranslation()

  return (
    <button
      className={classNames(`event__status event__status--${status}`, {
        'event__status--small': small,
        'event__status--compact': compact,
        'event__status--no-machine': small && !machine,
        'event__status--full-width': fullWidth,
        'event__status--forbidden': forbidden,
      })}
      {...filterByKeys(rest, (key) => !forbiddenKeys.includes(key))}
      disabled={disabled}
    >
      {small
        ? eventOrderPositionDecorator(order_position, event_type.template)
        : t(`events:values.status.${status}`)}
    </button>
  )
}

export default EventStatus
