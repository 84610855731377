import api from 'utils/api'
import config from 'config'
import i18n from 'initializers/i18n'

import { defaultRequest } from 'utils/requests'

import { displayAlert } from 'utils/helpers/toasts'
import { GLOBAL_SET_UNAUTHORIZED } from './global'

// FETCH
export const MATERIALS_FETCH_REQUEST = 'MATERIALS_FETCH_REQUEST'
export const MATERIALS_FETCH_SUCCESS = 'MATERIALS_FETCH_SUCCESS'
export const MATERIALS_FETCH_FAILURE = 'MATERIALS_FETCH_FAILURE'
// UPDATE
export const MATERIALS_UPDATE_REQUEST = 'MATERIALS_UPDATE_REQUEST'
export const MATERIALS_UPDATE_SUCCESS = 'MATERIALS_UPDATE_SUCCESS'
export const MATERIALS_UPDATE_FAILURE = 'MATERIALS_UPDATE_FAILURE'
// MERGE
export const MATERIALS_MERGE_REQUEST = 'MATERIALS_MERGE_REQUEST'
export const MATERIALS_MERGE_SUCCESS = 'MATERIALS_MERGE_SUCCESS'
export const MATERIALS_MERGE_FAILURE = 'MATERIALS_MERGE_FAILURE'
// SPLIT
export const MATERIALS_SPLIT_REQUEST = 'MATERIALS_SPLIT_REQUEST'
export const MATERIALS_SPLIT_SUCCESS = 'MATERIALS_SPLIT_SUCCESS'
export const MATERIALS_SPLIT_FAILURE = 'MATERIALS_SPLIT_FAILURE'
// MODALS
export const MATERIALS_TOGGLE_EDIT_MODAL = 'MATERIALS_TOGGLE_EDIT_MODAL'
// FILTERS
// --- PAPER
export const MATERIALS_FILTER_SET_DATE = 'MATERIALS_FILTER_SET_DATE'
export const MATERIALS_FILTER_SET_KEYWORD = 'MATERIALS_FILTER_SET_KEYWORD'
export const MATERIALS_FILTER_SELECT_PAPER_TYPE =
  'MATERIALS_FILTER_SELECT_PAPER_TYPE'
export const MATERIALS_FILTER_SELECT_PAPER_SIZE =
  'MATERIALS_FILTER_SELECT_PAPER_SIZE'
export const MATERIALS_FILTER_SET_STATUS = 'MATERIALS_FILTER_SET_STATUS'
export const MATERIALS_CLEAR_FILTER = 'MATERIALS_CLEAR_FILTER'
// Selection
export const MATERIALS_ADD_ITEM_SELECTION = 'MATERIALS_ADD_ITEM_SELECTION'
export const MATERIALS_REMOVE_ITEM_SELECTION = 'MATERIALS_REMOVE_ITEM_SELECTION'
export const MATERIALS_TOGGLE_ITEM_SELECTIONS =
  'MATERIALS_TOGGLE_ITEM_SELECTIONS'
export const MATERIALS_CLEAR_ITEM_SELECTIONS = 'MATERIALS_CLEAR_ITEM_SELECTIONS'
export const MATERIALS_SET_SELECTIONS_HIGHLIGHT =
  'MATERIALS_SET_SELECTIONS_HIGHLIGHT'
// Other
export const MATERIALS_TOGGLE_SPLIT_MODAL = 'MATERIALS_TOGGLE_SPLIT_MODAL'
export const MATERIALS_SORT = 'MATERIALS_SORT'
export const MATERIALS_SET_ERRORS = 'MATERIALS_SET_ERRORS'
export const MATERIALS_SET_QUERY_SETTINGS = 'MATERIALS_SET_QUERY_SETTINGS'
export const MATERIALS_CLEAR = 'MATERIALS_CLEAR'

/**
 * FETCH MATERIALS
 * * Get materials list
 */
export const fetchMaterials = (type) => async (dispatch, getState) => {
  // Get current state
  const {
    materials: { filter, sorting },
  } = getState()

  defaultRequest({
    request: () =>
      api.get(config.api.materials.root, {
        params: {
          type,
          date: filter.date,
          search: filter.keyword,
          paper_type_id: filter.paper_type,
          paper_size_id: filter.paper_size,
          status: filter.status,
          sort: sorting.column,
          direction: sorting.direction,
        },
      }),
    beforeRequest: () => dispatch({ type: MATERIALS_FETCH_REQUEST }),
    onSuccess: (response) => {
      // Response
      const { data } = response
      // Dispatch success action
      dispatch({ type: MATERIALS_FETCH_SUCCESS, items: data })
    },
    onError: () => dispatch({ type: MATERIALS_FETCH_FAILURE }),
    onForbiddenError: () => dispatch({ type: GLOBAL_SET_UNAUTHORIZED }),
    onUnknownError: () =>
      displayAlert(i18n.t('materials:alerts.cannot_fetch'), 'error'),
  })
}

/**
 * UPDATE MATERIAL
 * * Update material and reload materials list
 */
export const updateMaterial =
  ({ id, type, ...rest }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.materials.root}/${id}`, { type, ...rest }),
      beforeRequest: () => dispatch({ type: MATERIALS_UPDATE_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(i18n.t('materials:alerts.successfully_updated'), 'success')
        // Disable loading
        dispatch({ type: MATERIALS_UPDATE_SUCCESS })
        // Reload materials list
        dispatch(fetchMaterials(type))
        // Close modal and reset errors
        dispatch({ type: MATERIALS_TOGGLE_EDIT_MODAL, state: false })
        dispatch({ type: MATERIALS_SET_ERRORS, errors: [] })
      },
      onError: () => dispatch({ type: MATERIALS_UPDATE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: MATERIALS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('materials:alerts.cannot_update'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: MATERIALS_TOGGLE_EDIT_MODAL, state: false }),
    })

/**
 * UPDATE MATERIAL STATUS
 * * Update material status
 */
export const updateMaterialStatus =
  ({ id, event, beforeRequest, afterRequest, onError, type }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.materials.root}/${id}/status`, { event }),
      beforeRequest,
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('materials:alerts.successfully_updated_status'),
          'success'
        )
        // Fetch materials list
        dispatch(fetchMaterials(type))
      },
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: () =>
        displayAlert(i18n.t('materials:alerts.cannot_update_status'), 'error'),
      onUnknownError: () =>
        displayAlert(i18n.t('materials:alerts.cannot_update_status'), 'error'),
      onError,
      afterRequest,
    })

/**
 * SPLIT MATERIAL
 * * Split material
 */
export const splitMaterial =
  ({ id, quantity, type }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.materials.root}/${id}/split`, { quantity }),
      beforeRequest: () => dispatch({ type: MATERIALS_SPLIT_REQUEST }),
      onSuccess: () => {
        // Display successfull message
        displayAlert(
          i18n.t('materials:alerts.successfully_splitted'),
          'success'
        )
        // Disable loading
        dispatch({ type: MATERIALS_SPLIT_SUCCESS })
        // Reload materials list
        dispatch(fetchMaterials(type))
        // Close modal and reset errors
        dispatch({ type: MATERIALS_TOGGLE_SPLIT_MODAL, state: false })
        dispatch({ type: MATERIALS_SET_ERRORS, errors: [] })
        dispatch({ type: MATERIALS_CLEAR_ITEM_SELECTIONS })
      },
      onError: () => dispatch({ type: MATERIALS_SPLIT_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: MATERIALS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('materials:alerts.cannot_split'), 'error'),
      onUnexpectedError: () =>
        dispatch({ type: MATERIALS_TOGGLE_SPLIT_MODAL, state: false }),
    })

/**
 * MERGE MATERIALS
 * * Merge materials
 */
export const mergeMaterials =
  ({ material_ids, type }) =>
  async (dispatch) =>
    defaultRequest({
      request: () =>
        api.put(`${config.api.materials.root}/merge`, { material_ids }),
      beforeRequest: () => dispatch({ type: MATERIALS_MERGE_REQUEST }),
      onSuccess: ({ data }) => {
        // Display successfull message
        displayAlert(i18n.t('materials:alerts.successfully_merged'), 'success')

        if (data?.skipped && data.skipped > 0) {
          // Display skipped items message
          displayAlert(
            i18n.t('materials:alerts.skipped_items', {
              count: data.skipped,
            }),
            'warning'
          )
        }

        // Disable loading
        dispatch({ type: MATERIALS_MERGE_SUCCESS })
        // Reload materials list
        dispatch(fetchMaterials(type))
        // Close modal and reset errors
        dispatch({ type: MATERIALS_SET_ERRORS, errors: [] })
        dispatch({ type: MATERIALS_CLEAR_ITEM_SELECTIONS })
      },
      onError: () => dispatch({ type: MATERIALS_MERGE_FAILURE }),
      onForbiddenError: ({ error }) => displayAlert(error, 'permissions'),
      onUnprocessableError: (data) =>
        dispatch({ type: MATERIALS_SET_ERRORS, errors: data }),
      onUnknownError: () =>
        displayAlert(i18n.t('materials:alerts.cannot_merge'), 'error'),
    })
